<template>
  <v-dialog eager v-model="ui.dialog" :max-width="800" persistent>
    <v-card v-if="ui.afterSharer">
      <v-card-title>
        {{ $t('documentsUploader.filesUploaded') }}
      </v-card-title>
      <v-card-text class="px-7">
        <p>
          {{ $t('documentsUploader.allFilesUploaded') }}<br />
          {{ $t('documentsUploader.shareLinkSuggestion') }}
        </p>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-list subheader two-line>
              <v-list-item class="pr-1" v-for="(file, index) in files" :key="'file-' + index">
                <v-list-item-avatar v-if="file.thumbnail">
                  <v-img :src="file.thumbnail"></v-img>
                </v-list-item-avatar>
                <v-list-item-icon v-else>
                  <v-icon style="transform: translate(10px, 8px)">
                    mdi-text-box-plus-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="pb-1" v-text="file.titulo"></v-list-item-title>
                  <v-list-item-subtitle v-text="file.file.name"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  @click.stop="
                    $nuxt.$emit('share:open', {
                      tipo: file.tipo,
                      modulo: 'documentos',
                      moduloRef: file.uuid,
                    })
                  ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="success" small text outlined v-bind="attrs" v-on="on">
                        <v-icon small style="font-size: 20px; margin-right: 5px"> mdi-link </v-icon>
                        {{ $t('documentsUploader.share') }}
                      </v-btn>
                    </template>
                    <span>{{ $t('documentsUploader.shareTooltip') }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small text @click="close()">
          {{ $t('documentsUploader.closeWindow') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-row class="ma-0" v-show="!ui.generated">
        <v-col>
          <div id="dropzone" ref="dropzone" class="dropzone"></div>
        </v-col>
      </v-row>

      <v-card-title v-show="ui.generated">
        {{ $t('documentsUploader.filesGenerated') }}
        <v-icon color="yellow darken-3" style="font-size: 20px; margin: 7px"> mdi-creation </v-icon>
      </v-card-title>

      <v-card-text class="px-7">
        <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">
          <p v-if="!ui.generated">
            {{ $t('documentsUploader.dragAndDropFiles') }}<br />
            {{ $t('documentsUploader.savedSecurely') }}
          </p>
          <p v-else>
            {{ $t('documentsUploader.autoGeneratedDocument') }}<br />
            {{ $t('documentsUploader.saveToDocumentManager') }}
          </p>
          <v-row>
            <v-col>
              <v-list-item @click="ui.dummy = false">
                <v-list-item-icon>
                  <v-icon color="gray"> mdi-book-open</v-icon>
                </v-list-item-icon>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content v-bind="attrs" v-on="!isClient ? on : false">
                      <v-list-item-title>
                        {{ $t('documentsUploader.module') }}
                        <v-icon
                          v-if="!isClient"
                          color="gray"
                          small
                          style="transform: translate(2px, -1px)">
                          mdi-redo
                        </v-icon>
                      </v-list-item-title>
                      <v-list-item-subtitle :title="options.moduloRef">{{
                        options.modulo
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in db.modulos"
                      :key="index"
                      @click="
                        options.modulo = item.value
                        options.submodulo = null
                      ">
                      <v-list-item-title>{{ item.translation[locale] }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
            </v-col>

            <v-col v-if="options.submodulo">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="gray"> mdi-bookmark</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('documentsUploader.subModule') }}</v-list-item-title>
                  <v-list-item-subtitle :title="options.submoduloRef">{{
                    options.submodulo
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col>
              <v-list-item v-if="options.cliente || options.contacto">
                <v-list-item-icon>
                  <v-icon color="gray"> mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('documentsUploader.attachedTo') }}</v-list-item-title>
                  <v-list-item-subtitle :title="options.cliente || options.contacto">
                    {{ options.cliente_name }}
                    {{ options.contacto_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <v-list-item v-if="options.cliente || options.contacto">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-checkbox
                      v-model="options.notify"
                      :label="$t('documentsUploader.notifyClient')"
                      :hint="$t('documentsUploader.notifyClientMessage')"
                      persistent-hint
                      color="success"></v-checkbox>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-row v-if="options.notify">
                <v-col cols="12" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-checkbox
                          v-model="options.notifyEmail"
                          :label="$t('documentsUploader.notifyEmail')"
                          color="success"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-checkbox
                          v-model="options.notifyPush"
                          :label="$t('documentsUploader.notifyPush')"
                          color="success"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="options.maxFiles == 1">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="gray"> mdi-circle-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('documentsUploader.maxDocuments') }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t('documentsUploader.maxFilesMessage', { count: options.maxFiles })
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12">
              <v-list subheader two-line>
                <v-subheader v-if="!ui.generated">{{
                  $t('documentsUploader.filesToUpload')
                }}</v-subheader>
                <v-list-item v-for="(file, index) in files" :key="'file-' + index">
                  <v-list-item-avatar v-if="file.thumbnail">
                    <v-img :src="file.thumbnail"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-icon v-else>
                    <v-icon style="transform: translate(10px, 11px)">
                      mdi-text-box-plus-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="file.file.name"></v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-text-field
                        dense
                        filled
                        :label="$t('documentsUploader.assignName')"
                        single-line
                        hide-details="auto"
                        class="py-0 my-0"
                        :placeholder="$t('documentsUploader.namePlaceholder')"
                        v-model="file.titulo"></v-text-field>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    class="mx-2"
                    v-if="!isClient"
                    @click="file.visible = !file.visible">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon :color="file.visible ? 'success' : 'grey lighten-1'">
                            {{ file.visible ? 'mdi-cloud-check' : 'mdi-lock' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-html="
                          file.visible
                            ? $t('documentsUploader.filePublic')
                            : $t('documentsUploader.filePrivate')
                        "></span>
                    </v-tooltip>
                  </v-list-item-action>
                  <v-list-item-action class="mx-2" @click="removeFile(index)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="grey lighten-1">mdi-close-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('documentsUploader.cancelFileUpload') }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!ui.loading">
        <v-btn small text color="error" @click="close()">
          {{ $t('documentsUploader.cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" small text outlined @click="submit">
          {{ $t('documentsUploader.attach') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"></v-progress-linear>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/documentsUploader.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 13th July 2021
 * Last Modified: Thu Nov 03 2022
 **/

import Dropzone from 'dropzone'
import 'dropzone/dist/dropzone.css'

import MODULOS from '~/models/modulos'

export default {
  name: 'DocumentUploader',

  data: () => ({
    dropzone: null,
    options: {},
    files: [],
    locale: 'es',
    valid: false,
    rules: [(v) => !!v || 'El campo es obligatorio'],

    db: {
      modulos: [],
    },

    ui: {
      dummy: false,
      dialog: false,
      loading: false,
      generated: false,
      afterSharer: false,
    },
  }),

  computed: {
    isClient() {
      return this.$store.getters.isCliente
    },
  },

  methods: {
    //+-------------------------------------------------
    // open()
    // Method called externally via events.
    // Initializes Dropzone and merges received options
    // Is possible for the event to have a file
    // -----
    // Created on Tue Sep 21 2021
    //+-------------------------------------------------
    open(payload) {
      this.startDropzone()

      this.options = { ...payload }
      delete this.options.blob

      if (payload.blob) {
        this.addFile(
          new File([payload.blob.data], payload.name, {
            type: payload.blob.data.type,
            lastModified: new Date().getTime(),
          })
        )
      }

      this.ui.dialog = true
    },

    //+-------------------------------------------------
    // close()
    // Reset and hides modal
    // -----
    // Created on Tue Sep 21 2021
    //+-------------------------------------------------
    close() {
      this.files = []
      this.options = {}

      this.ui.dialog = false
      this.ui.loading = false
      this.ui.generated = false
      this.ui.afterSharer = false

      // No form validations for the moment
      // this.$refs.form.reset()
    },

    //+-------------------------------------------------
    // showSuccessAndShare()
    // Displays a success message
    // -----
    // Created on Tue Sep 21 2021
    //+-------------------------------------------------
    showSuccessAndShare() {
      this.ui.afterSharer = true
      //si la ruta contiene admin, el evento es documents-reload-admin
      if (this.$route.path.includes('admin')) $nuxt.$emit('documents-reload-admin')
      else $nuxt.$emit('documents-reload')
    },

    //+-------------------------------------------------
    // addFile()
    // Appends a file received by dropzone to this.files
    // -----
    // Created on Wed Jul 14 2021
    //+-------------------------------------------------
    addFile(file) {
      if (this.options.maxFiles) {
        if (this.files.length >= this.options.maxFiles) {
          console.warn('Max files reached')
          this.dropzone.removeFile(file)
          return
        }
      }

      let newFile = { ...this.options }
      newFile.file = file
      newFile.tipo = file.type

      newFile.titulo = ''
      newFile.filename = file.name

      newFile.generated = this.ui.generated || false
      newFile.visible = newFile.visible || newFile.generated
      newFile.created_at = this.$moment().format('YYYY-MM-DD')

      this.files.push(newFile)
      console.log(`File added: ${file.name}`, file, this.files, this.options)

      if (this.dropzone) this.dropzone.removeFile(file)
    },

    //+-------------------------------------------------
    // removeFile()
    // Removes a file from the queue
    // -----
    // Created on Thu Jul 15 2021
    //+-------------------------------------------------
    removeFile(index) {
      this.files.splice(index, 1)
    },

    //+-------------------------------------------------
    // addThumbnail()
    // Appends the data64 thumbnail of the file
    // -----
    // Created on Wed Jul 14 2021
    //+-------------------------------------------------
    addThumbnail(file, dataURL) {
      for (const f in this.files) {
        if (
          this.files[f].file?.upload?.uuid &&
          this.files[f].file.upload.uuid == file.upload.uuid
        ) {
          this.files[f].thumbnail = dataURL
        }
      }

      this.$forceUpdate()
    },

    //+-------------------------------------------------
    // startDropzone()
    // Initializes dropzoneJS if not ready
    // -----
    // Created on Wed Jul 14 2021
    //+-------------------------------------------------
    startDropzone() {
      window.setTimeout(() => {
        let $el = document.getElementById('dropzone')
        if ($el && !$el.dropzone) {
          this.dropzone = new Dropzone('#dropzone', {
            dictDefaultMessage:
              `<i class='v-icon notranslate mdi mdi-cloud-upload-outline theme--light'></i>` +
              `<h4>${this.$t('documentsUploader.dropzone.defaultMessage')}</h4>`,

            url: 'none',
            autoProcessQueue: false,
            parallelUploads: 1,
            thumbnailHeight: 120,
            thumbnailWidth: 120,
            maxFilesize: 500, // in MB
            timeout: 0,

            init: function () {
              console.log(this, this.element.dataset)
            },
          })

          this.dropzone.on('addedfile', (file) => {
            $nuxt.$emit('documents:addedfile', {
              file: file,
            })
          })

          this.dropzone.on('thumbnail', (file, dataURL) => {
            $nuxt.$emit('documents:thumbnail', {
              file: file,
              dataURL: dataURL,
            })
          })
        }
      }, 500)
    },

    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------

    async submit() {
      let step = 'valid' // false

      // 1 . Form validations
      // if (this.validate()) step = 'valid'

      // 2. Api requests
      if (step === 'valid') {
        this.processFiles()
      }
    },

    //+-------------------------------------------------
    // processFiles()
    // Uploads every file on the queue and displays a success
    // -----
    // Updated on Fri Nov 12 2021
    //+-------------------------------------------------
    async processFiles() {
      this.ui.loading = true
      for (const file of this.files) {
        if (file.titulo == '') file.titulo = file.filename

        file.modulo = this.options.modulo
        file.moduloRef = this.options.moduloRef
        file.user = this.options.contacto || this.options.cliente
        file.notify = this.options.notify
        if (this.options.notifyEmail) {
          file.notifyEmail = this.options.notifyEmail
        }
        if (this.options.notifyPush) {
          file.notifyPush = this.options.notifyPush
        }

        await this.store(file).then((xhr) => {
          file.uuid = xhr.uuid
        })
        // Commented out to work with offline
        // file.titulo = xhr.titulo
        // console.warn(xhr)
      }

      // The process to finish the queue now has
      // a success screen that offers to share uploaded files
      this.showSuccessAndShare()
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store(file) {
      const body = { ...file }

      try {
        const xhr = await this.$store.dispatch('documentos/create', body)
        $nuxt.$emit('documents:uploaded', {
          file: body,
          payload: this.options.payload,
        })

        return xhr
      } catch (e) {
        console.info(e)
        this.$store.commit('notification/show', {
          text: 'Ha ocurrido un error subiendo un archivo',
          color: 'error',
          timeout: 3000,
        })
      }
    },

    loadModulos() {
      this.db.modulos = MODULOS
    },

    async init() {
      this.loadModulos()
      this.locale = this.$i18n.locale
      // Fix for "no url provided" error in
      // dinamic dropzone integrations (inside vue for example)
      Dropzone.autoDiscover = false

      this.$nuxt.$on('documents:open', (payload) => {
        this.open(payload)
      })

      this.$nuxt.$on('documents:generated', (payload) => {
        if (!this.$auth.user.roles.includes('ROLE_AUDITOR_ADMIN')) return

        window.setTimeout(() => {
          this.ui.generated = true
          this.open(payload)
        }, 500)
      })

      this.$nuxt.$on('documents:addedfile', (payload) => {
        this.addFile(payload.file)
      })

      this.$nuxt.$on('documents:thumbnail', (payload) => {
        this.addThumbnail(payload.file, payload.dataURL)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('documents:open')
    this.$nuxt.$off('documents:generated')
    this.$nuxt.$off('documents:addedfile')
    this.$nuxt.$off('documents:thumbnail')
  },
}
</script>

<style>
.v-dialog.v-dialog--active::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

.v-dialog.v-dialog--active::-webkit-scrollbar-thumb {
  background-color: #00000069;
  border-radius: 15px;
  border: 1px solid #a5a5a5;
}

.dropzone {
  margin: 8px;
  border-radius: 5px;
  background: #eceded;
  border: 1px dashed #cccccc !important;
}

.dropzone h4 {
  color: rgb(141 142 142);
  font-weight: 400;
}

.dropzone i.v-icon {
  color: #8d8e8e8c;
  font-size: 45px;
  margin-bottom: 10px;
}

.dropzone .dz-size {
  display: none;
}
</style>
