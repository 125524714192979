<template>
  <div>
    <!-- Confirm -->
    <confirmation
      v-model="confirm"
      :text="$t('MuestrasTab.createSample')"
      :textButton="$t('MuestrasTab.create')"
      :valid="valid"
      @confirm="createMuestra()"
      @close="close()"
    >
      <v-form ref="form" lazy-validation v-model="valid">
        <v-container>
          <v-row align="center" justify="space-around">
            <v-col cols="8">
              <v-select
                :label="$t('MuestrasTab.sampleType')"
                v-model="newMuestra.tipoMuestra"
                :rules="rules"
                :muestras="tipos"
                required
                dense
              ></v-select>
            </v-col>
            <v-col cols="8">
              <SelectEstaticos
                v-if="auditoria.alcance"
                v-model="newMuestra.laboratorio"
                :alcance="auditoria.alcance.uuid"
                codigo="laboratorios"
                required
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </confirmation>

    <!-- Muestras -->
    <v-card :loading="loading">
      <v-card-title>
        <span class="headline text-capitalize font-weight-bold">
          {{ $t('MuestrasTab.sample') }}: {{ tab.replace('_', ' ').toLowerCase() }}
        </span>
        <v-spacer></v-spacer>
        <v-tooltip bottom v-for="item in muestras" :key="item.id">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :color="tab === item.tipoMuestra ? 'info' : 'grey lighten-4'"
              :class="tab === item.tipoMuestra ? 'white--text' : ' grey--text text--darken-1'"
              class="mr-4"
              depressed
              @click="tab = item.tipoMuestra"
            >
              <span class="text-capitalize">{{ item.tipoMuestra }}</span>
            </v-btn>
          </template>
          <span class="text-capitalize">{{ item.tipoMuestra.replace('_', ' ') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-window v-model="tab">
          <v-window-item v-for="item in muestras" :key="item.id" :value="item.tipoMuestra">
            <v-row justify="space-between" v-show="tab === item.tipoMuestra">
              <!-- LOTE -->
              <v-col cols="5">
                <v-card flat>
                  <v-card-title>{{ $t('MuestrasTab.lot') }}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row no-gutters justify="space-between">
                        <v-col cols="5" class="pb-1">
                          <v-text-field
                            :label="$t('MuestrasTab.lot')"
                            v-model="item.lote"
                            :disabled="isDisabled"
                            dense
                            outlined
                            @change="updateMuestra($event, item)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5" class="pb-1 ml-2">
                          <v-text-field
                            :label="$t('MuestrasTab.seal')"
                            v-model="item.precinto"
                            :disabled="isDisabled"
                            dense
                            outlined
                            @change="updateMuestra($event, item)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pb-1">
                          <SelectEstaticos
                            :label="$t('MuestrasTab.selectLaboratory')"
                            v-if="auditoria.alcance"
                            v-model="item.laboratorio"
                            :disabled="isDisabled"
                            :alcance="auditoria.alcance.uuid"
                            :estaticos="estaticos"
                            codigo="laboratorios"
                            required
                            dense
                            @change="updateMuestra($event, item)"
                          />
                        </v-col>
                        <v-col cols="6" class="pb-1">
                          <SelectEstaticos
                            :label="$t('MuestrasTab.analysisType')"
                            v-if="auditoria.alcance"
                            v-model="item.tipoMuestra"
                            :disabled="isDisabled"
                            :alcance="auditoria.alcance.uuid"
                            :estaticos="estaticos"
                            codigo="tipos_analisis"
                            required
                            dense
                            @change="updateMuestra($event, item)"
                          />
                        </v-col>
                        <v-col cols="12" class="pb-1">
                          <v-text-field
                            :label="$t('MuestrasTab.substancesDetected')"
                            v-model="item.sustanciasDetectadas"
                            :disabled="isDisabled"
                            dense
                            outlined
                            @change="updateMuestra($event, item)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Fechas -->
              <v-col cols="4" xl="3">
                <v-card flat>
                  <v-card-title>{{ $t('MuestrasTab.shippingDate') }}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12" class="py-1" v-if="!isOceGlobal">
                          <v-menu close-on-content-click :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDate(item.fechaEnvio)"
                                :disabled="isDisabled"
                                :label="$t('MuestrasTab.shippingDate')"
                                prepend-icon="event"
                                clearable
                                readonly
                                outlined
                                dense
                                v-on="on"
                                @click:clear="item.fechaEnvio = ''"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.fechaEnvio"
                              color="primary"
                              :first-day-of-week="1"
                              @change="updateMuestra($event, item)"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="pb-1">
                          <v-menu close-on-content-click :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDate(item.fechaRecepcion)"
                                :disabled="isDisabled"
                                :label="$t('MuestrasTab.receptionDate')"
                                prepend-icon="event"
                                outlined
                                clearable
                                readonly
                                dense
                                v-on="on"
                                @click:clear="item.fechaRecepcion = ''"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.fechaRecepcion"
                              color="primary"
                              :first-day-of-week="1"
                              @change="updateMuestra($event, item)"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Status and Billing -->
              <v-col cols="3">
                <v-row no-gutters>
                  <v-col cols="12" xl="6">
                    <v-switch
                      color="success"
                      v-model="item.estado"
                      :true-value="$t('MuestrasTab.statusComplies')"
                      :false-value="$t('MuestrasTab.statusDoesNotComply')"
                      :disabled="isDisabled"
                      @change="updateMuestra($event, item)"
                    >
                      <template #label>
                        <span class="font-weight-bold" :class="item.estado === 'CUMPLE' ? 'success--text' : 'error--text'">
                          {{ item.estado === 'CUMPLE' ? $t('MuestrasTab.statusComplies') : $t('MuestrasTab.statusDoesNotComply') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="12" xl="6">
                    <v-checkbox
                      :label="$t('MuestrasTab.billable')"
                      v-model="item.facturable"
                      :disabled="isDisabled"
                      @change="updateMuestra($event, item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Sample Code -->
              <v-col cols="12">
                <DatosMuestra
                  v-model="item.params"
                  :auditoria="auditoria"
                  :codigo="toma_muestra.codigo"
                  :isDisabled="isDisabled"
                  @change="updateMuestra($event, item)"
                />
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import DatosMuestra from '~/components/auditorias/muestras/DatosMuestra'
import Analisis from '~/components/auditorias/muestras/MuestrasTabAnalisis'
import SelectEstaticos from '~/components/forms/SelectEstaticos'
import Confirmation from '~/components/Confirmation'
import moment from 'moment'

export default {
  props: {
    auditoria: {
      type: Object,
      default: () => {},
    },
    toma_muestra: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatosMuestra,
    Analisis,
    SelectEstaticos,
    Confirmation,
  },
  data: () => ({
    estaticos: null,
    search: '',
    tab: '',
    valid: true,
    confirm: false,
    analisisIdx: null,
    newMuestra: {},
    tipos: ['NUEVA'],
    rules: [(v) => !!v || 'Obligatorio'],
    item: {},
    muestras: [],
  }),
  watch: {
    toma_muestra() {
      this.listMuestra()
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },
  },
  mounted() {
    this.getEstaticos()
  },
  methods: {
    async getEstaticos() {
      const body = {
        alcance: this.auditoria.alcance.uuid,
        codigos: ['datos_muestra', 'laboratorios', 'tipos_analisis'],
      }
      this.estaticos = await this.$store.dispatch('estaticos/listMember', body)
    },
    formatDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    close() {
      this.confirm = false
      this.newMuestra = {}
      this.$refs.form.reset()
    },
    // Muestras
    async listMuestra() {
      if (this.toma_muestra.uuid) {
        const res = await this.$store.dispatch('tomas_muestra/listMuestra', this.toma_muestra.uuid)
        if (res) {
          let data = res
          let dirimente = ''
          let contradictorio = ''
          /* data.forEach((item,index )=> {
            dirimente = item[1]
            if (dirimente.tipo == item.tipo)
            if (item.fechaEnvio) item.fechaEnvio = moment(item.fechaEnvio).format('YYYY-MM-DD')
            if (item.fechaRecepcion) item.fechaRecepcion = moment(item.fechaRecepcion).format('YYYY-MM-DD')
          }) */
          for (let i = 0; i < data.length; i++) {
            const element = data[i]
            if (element.fechaEnvio)
              element.fechaEnvio = moment(element.fechaEnvio).format('YYYY-MM-DD')
            if (element.fechaRecepcion)
              element.fechaRecepcion = moment(element.fechaRecepcion).format('YYYY-MM-DD')
            if (element.tipoMuestra == 'DIRIMENTE') dirimente = element
            if (element.tipoMuestra == 'CONTRADICTORIO') contradictorio = element
          }
          console.log('muestras', data)
          this.muestras = data
          this.muestras[1] = contradictorio
          this.muestras[2] = dirimente
          console.log('muestras', this.muestras)
          this.$emit('updateMuestras', this.muestras)
        }
      }
    },
    async createMuestra(idx) {
      if (this.$refs.form.validate()) {
        const body = {
          ...this.newMuestra,
          toma_muestra: this.toma_muestra.uuid,
        }
        await this.$store.dispatch('tomas_muestra/createMuestra', body)
        this.close()
        this.listMuestra()
      }
    },
    async updateMuestra(val, item) {
      if (val || val === false) {
        const body = {
          ...item,
          toma_muestra: this.toma_muestra.uuid,
        }
        await this.$store.dispatch('tomas_muestra/updateMuestra', body)
        this.listMuestra()
      }
    },
  },
  async mounted() {
    await this.listMuestra()
    this.tab = 'INICIAL'
  },
}
</script>
