/**
 * @project: certiapp-nuxt
 * @file:    util/formatHelper.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 11th May 2021
 * Last Modified: Tue Jul 25 2023
 **/

export default {
  //+-------------------------------------------------
  // num()
  // Format a number with thousands
  // -----
  // Created on Tue Jun 21 2022
  // Updated on Fri Jul 08 2022
  //+-------------------------------------------------
  num(num) {
    if (num === null || num === undefined) return 0
    return num.toLocaleString('de-DE')
  },

  //+-------------------------------------------------
  // stringToslug()
  // Returns a slug from a given string
  // -----
  // Created on Tue Jul 27 2021
  // Updated on Fri Aug 26 2022
  //+-------------------------------------------------
  stringToslug(str) {
    if (str === null || str === undefined) return ''
    if (typeof str !== 'string') return ''

    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  },

  //+-------------------------------------------------
  // nestedToIRI()
  // Change all nested objects to IRI for the item provided if the item is an object and the property is an object and has an ['@id'] property
  // -----
  // Created on Tue Apr 23 2024
  //+-------------------------------------------------
  nestedToIRI(item) {
    if (typeof item !== 'object') return item

    for (const key in item) {
      if (item[key] && typeof item[key] === 'object' && item[key]['@id']) {
        item[key] = item[key]['@id']
      }
    }

    return item
  },

  removeTimestamps(item) {
    if (typeof item !== 'object') return item

    for (const key in item) {
      if (key === 'createdAt' || key === 'updatedAt') {
        delete item[key]
      }
    }

    return item
  },

  removeBlameables(item) {
    if (typeof item !== 'object') return item

    for (const key in item) {
      if (key === 'createdBy' || key === 'updatedBy') {
        delete item[key]
      }
    }

    return item
  },

  //+-------------------------------------------------
  // phone()
  // Returns a phone number in a readable format
  // -----
  // Created on Tue May 11 2021
  //+-------------------------------------------------
  phone(number) {
    if (number == undefined || number == '--') return '--'

    if (typeof number === 'integer') number = number.toString()
    let phone = number.indexOf('0') == 0 ? number.substring(1) : number

    if (phone.indexOf('+34 ') > -1) phone = phone.replace('+34', '')
    if (phone.indexOf('+34') > -1) phone = phone.replace('+34', '')

    let cleaned = ('' + phone).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/)
    let match_ = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/)

    if (match) return match[1] + ' ' + match[2] + ' ' + match[3]
    if (match_) return match_[1] + ' ' + match_[2] + ' ' + match_[3]

    return phone
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{3})/g, '$1 ')
      .trim()
  },

  //+-------------------------------------------------
  // fullName()
  // Returns contact full name depending on the params
  // -----
  // Created on Fri Nov 26 2021
  // Updated on Thu Feb 24 2022
  // Updated on Tue Jan 17 2023
  // Updated on Thu Apr 27 2023
  //+-------------------------------------------------
  fullName(data, params) {
    let name = params?.default || 'Desconocido (Sin nombre)'
    if (params?.default == 'username') name = data.username

    if (data) {
      
      if (data.nombre) name = data.nombre

      if (data.primer_apellido) name += ' ' + data.primer_apellido
      if (data.segundo_apellido) name += ' ' + data.segundo_apellido

      if (data.apellido1) name += ' ' + data.apellido1
      if (data.apellido2) name += ' ' + data.apellido2

      // Some api endpoints have computed last names
      if (data.apellidos) name += ' ' + data.apellidos

      // Some api endpoints have computed full names
      if (data.nombreCompleto) name = data.nombreCompleto
      if (data.nombre_cliente) name = data.nombre_cliente
      if (data.nombre_completo) name = data.nombre_completo
    }

    if(!data?.tipo) return name

    if(data.tipo === 'tecnico') {
      return name
    }

    // If the contact has also a cliente attribute with name, use contact data
    if (data?.cliente?.nombre) name = data.cliente.nombre
    if (data?.cliente?.apellidos) name += ' ' + data.cliente.apellidos
    if (data?.cliente?.primer_apellido) name += ' ' + data.cliente.primer_apellido
    if (data?.cliente?.segundo_apellido) name += ' ' + data.cliente.segundo_apellido

    // If the contact has also a contact attribute with name, use contact data
    if (data?.contacto?.nombre) name = data.contacto.nombre
    if (data?.contacto?.apellidos) name += ' ' + data.contacto.apellidos
    if (data?.contacto?.primer_apellido) name += ' ' + data.contacto.primer_apellido
    if (data?.contacto?.segundo_apellido) name += ' ' + data.contacto.segundo_apellido

    // If params define is: 'auditor'
    if (params?.is == 'auditor') {
      if (data?.iniciales) name = `(${data.iniciales}) ` + name
    }

    return name
  },

  //+-------------------------------------------------
  // toMoment()
  //
  // -----
  // Created on Thu Nov 03 2022
  //+-------------------------------------------------
  date(theDate, format) {
    return this.toMoment(theDate, format)
  },
  toMoment(theDate, format = 'L') {
    if (!theDate) return ''

    let date = theDate.replace(/\//g, '-')
    let moment = null

    if (typeof date === 'string' && date.indexOf('-') > -1) {
      moment = $nuxt.$moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'])
    }

    if (format == 'nice') format = 'D [de] MMMM, YYYY'
    if (format) return moment.format(format)
    return moment
  },

  //+-------------------------------------------------
  // objectToUrlParams()
  // Converts object attributes into url parameters
  // -----
  // Created on Sat Dec 02 2023
  //+-------------------------------------------------
  objectToUrlParams(obj) {
    const urlSearchParams = new URLSearchParams();

    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          urlSearchParams.append(`${key}[]`, item);
        });
      } else {
        urlSearchParams.append(key, value);
      }
    });

    return urlSearchParams.toString();
  },

  //+-------------------------------------------------
  // flattenObject()
  // La función flattenObject toma un objeto con propiedades anidadas y "aplasta" recursivamente estas propiedades en un
  // nuevo objeto donde todas las propiedades se encuentran a un solo nivel.
  // -----
  // Los nombres de las propiedades del nuevo objeto reflejan la estructura original y muestran el "camino" completo de
  // la ubicación original de cada propiedad. Si una propiedad estaba anidada dentro de uno o más objetos, su nombre en
  // el objeto aplanado es la concatenación de todos los nombres de propiedades de los objetos padres, unidos con
  // puntos ('.').
  // -----
  // Por ejemplo, si el objeto original era { a: { b: { c: 1 } } }, el objeto aplanado sería { 'a.b.c': 1 }.
  // -----
  // Esta función opera utilizando la función reduce para iterar sobre todas las claves en el objeto. Si el valor de una
  // clave es un objeto (y no un array), hace una llamada recursiva a flattenObject, pasando este objeto anidado y el
  // nuevo prefijo. El prefijo es la concatenación del prefijo actual y la clave presente, separados por un punto.
  // -----
  // Si el valor es un array, la función itera sobre cada elemento utilizando un bucle for y hace una llamada recursiva
  // a flattenObject para cada elemento. El prefijo para los elementos del array incluye la clave madre y el índice del
  // elemento dentro del array, todo separado por puntos.
  // -----
  // Finalmente, si el valor de una clave no es ni un objeto ni un array, se agrega una nueva propiedad al objeto
  // acumulador (acc), que es el objeto aplanado final. El nombre de la propiedad es una concatenación del prefijo
  // actual y la clave, y su valor es el valor de la clave en el objeto original.
  // Debe tenerse en cuenta que si el valor de una propiedad es undefined, no se incluirá en el objeto aplanado.

  // Created on Thu Dec 14 2023
  //+-------------------------------------------------
  flattenObject(obj, prefix = '') {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + '.' : '';
      if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k]) && Object.keys(obj[k]).length > 0) {
        Object.assign(acc, this.flattenObject(obj[k], pre + k));
      } else if (Array.isArray(obj[k])){
        for (let i = 0; i < obj[k].length; i++) {
          Object.assign(acc, this.flattenObject(obj[k][i], `${pre + k}.${i}`));
        }
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  },
}
