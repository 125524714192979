/**
 * @project: certiapp-nuxt
 * @file:    localidades.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 4th June 2021
 * Last Modified: Fri Jul 21 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns all families
  // -----
  // Created on Tue Jun 01 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true
    let res = null
    let url = `/localidades?paginate&page=${params.page}&itemsPerPage=${params.perPage}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`
    if (params.search) {
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    if (params.provincia) {
      url = url + `&municipio.provincia.uuid=${params.provincia}`
    }
    res = await this.$axios.get(url)

    rootState.loading = false
    if (res) return res
  },

  async listByUuid({ rootState }, params) {
    rootState.loading = true
    let res = null
    let url = `/localidades/${params.uuid}`
    res = await this.$axios.get(url)
    rootState.loading = false
    if (res) return res
  },

  async list_by_provincia({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.get(
      `/localidades?paginate&municipio.provincia.uuid=${params.provincia}&page=${params.page}&itemsPerPage=${params.perPage}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`
    )

    rootState.loading = false
    if (res) return res
  },

  //+-------------------------------------------------
  // create()
  // payload: uuid, nombre, codigo, municipio
  // -----
  // Created on Fri Jun 04 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(`localidades`, {
      uuid: params.uuid || uuid.v4(),
      nombre: params.nombre,
      codigo: params.codigo || '',
      municipio: params.municipio,
    })

    rootState.loading = false
  },

  //+-------------------------------------------------
  // customList()
  // Retorna localidades por empresa y adicionalmente
  // recibe el parametro provincia, para el filtrado
  //+-------------------------------------------------
  async customlist({ rootState }, params) {
    rootState.loading = true
    let res = null
    let url = '/localidades'
    if (params.page) {
      url = url + `?page=${params.page}`
    }
    if (params.perPage) {
      url = url + `&itemsPerPage=${params.perPage}`
    }
    if(params.empresa){
      url = url + `&empresa.uuid=${params.empresa}`
    }
    if(params.nombre){
      params.nombre = params.nombre.toLowerCase()
      url = url + `&nombre=${params.nombre}`

    }
    res = await this.$axios.get(url)
    rootState.loading = false
    if (res && res.data) return res.data
  },

  async getPaisesInUse({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`/empresas/${params}/paises`)
    rootState.loading = false
    if (res && res.data) return res.data
  },

  async searchbyname({ rootState }, params) {
    rootState.loading = true
    let res = null

    res = await this.$axios.get(`/localidades?paginate&page=1&nombre=${params.nombre}`)

    rootState.loading = false
    if (res && res.data) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Tue Sep 14 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`localidades/${item.uuid}`)
    rootState.loading = false
  },
}
