var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.cliente && !_vm.expediente)?_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v(_vm._s(_vm.$t('widgetAuditoria.auditorias')))])]),_vm._v(" "),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"6"}},[(_vm.ui.showOffline)?_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","outlined":"","color":"purple"},on:{"click":_vm.dropCache}},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("mdi-water-remove")]),_vm._v("\n                "+_vm._s(_vm.$t('widgetAuditoria.vaciar'))+"\n            ")],1):_vm._e(),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","outlined":"","color":(_vm.ui.showOffline) ? 'success' : ''},on:{"click":function($event){_vm.ui.showOffline = !_vm.ui.showOffline; _vm.refreshRows(true)}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("mdi-lightning-bolt-circle")]),_vm._v(" "),(_vm.ui.showOffline)?[_vm._v("\n                    "+_vm._s(_vm.$t('widgetAuditoria.estoyPreparado'))+"\n                ")]:[_vm._v("\n                    "+_vm._s(_vm.$t('widgetAuditoria.prepararseOffline'))+"\n                ")]],2)],1),_vm._v(" "),(_vm.isOffline)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"dark":"","icon":"mdi-wifi-strength-alert-outline"}},[_vm._v("\n                "+_vm._s(_vm.$t('widgetAuditoria.alertaOffline1'))+"\n            ")]),_vm._v(" "),_c('v-alert',{attrs:{"dark":"","icon":"mdi-alert-box-outline"}},[_vm._v("\n                "+_vm._s(_vm.$t('widgetAuditoria.alertaOffline2'))+"\n            ")])],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.ui.showOffline),expression:"ui.showOffline"}],staticClass:"my-5"},[_c('v-col',[_c('offlineSelector',{ref:"offline",attrs:{"nope-component":"auditoria","enabled":_vm.ui.showOffline,"onInit":['auditorias/list'],"onInitBody":[{}],"cols":[
                    { text: _vm.$t('widgetAuditoria.estado'), value: 'estado' },
                    { text: _vm.$t('widgetAuditoria.desde'), value: 'desde_f' },
                    { text: _vm.$t('widgetAuditoria.hasta'), value: 'hasta_f' },
                    { text: _vm.$t('widgetAuditoria.alcance'), value: 'nombre_alcance' },
                    { text: _vm.$t('widgetAuditoria.cliente'), value: 'nombreApellidos' },
                    { text: _vm.$t('widgetAuditoria.emplazamiento'), value: 'emplazamiento' },
                ],"items":_vm.allowedToOffline,"howToLoad":[
                    {
                        'action': 'clearCache',
                        'key': 'auditorias/uuid',
                    },
                    {
                        'action': 'lockAuditoria'
                    },
                    {
                        'component': 'auditoria',
                        'uuid': 'uuid'
                    },
                    {
                        'request': 'checklists/list',
                    },
                    {
                        'component': 'checklist',
                        'uuid': 'uuid',
                        'loop': true,
                    },
                    {
                        'request': 'tomas_muestra/list',
                        'uuid': 'itemUUID',
                    },
                    {
                        'component': 'muestras',
                        'uuid': 'uuid',
                        'loop': true,
                    },
                    {
                        'action': 'downloadClientFiles',
                        'uuid': 'cliente.uuid',
                    }]}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"primary"},on:{"click":_vm.createAuditoria}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("\n                "+_vm._s(_vm.$t('widgetAuditoria.crearAuditoria'))+"\n            ")],1)],1)],1),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ui.showOffline),expression:"!ui.showOffline"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('ag-datatable',{key:_vm.ui.refresh,attrs:{"title":_vm.$t('widgetAuditoria.listado'),"headers":_vm.headers,"items":_vm.items,"emitClickRow":"","auth":_vm.auth},on:{"click-row":_vm.clickRow,"gridApi":function($event){_vm.gridApi = $event}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }