<template>
  <v-dialog v-model="ui.show" width="auto" max-width="890px" min-width="450px" persistent>
    <v-form ref="form" v-model="ui.isValid">
      <v-card>
        <v-card-title v-if="!show_toggles" class="headline">{{ $t('gestionInstalacionesDialog.installationData') }}</v-card-title>
        <v-card-title v-else class="headline">{{ $t('gestionInstalacionesDialog.addressData') }}</v-card-title>

        <v-card-text class="px-10">
          <v-row>
            <v-col cols="4" class="pt-0 pb-2">
              <v-switch
                inset
                v-model="item.principal"
                hide-details="auto"
                color="green"
                :label="
                  item.principal ? $t('gestionInstalacionesDialog.isMainAddress') : $t('gestionInstalacionesDialog.isNotMainAddress')
                ">
              </v-switch>
            </v-col>

            <v-col cols="4" class="pt-0 pb-2">
              <v-switch
                inset
                v-model="item.active"
                hide-details="auto"
                color="green"
                :label="item.active ? $t('gestionInstalacionesDialog.active') : $t('gestionInstalacionesDialog.inactive')">
              </v-switch>
            </v-col>

            <v-col cols="12" class="mt-2">
              <v-text-field
                :label="$t('gestionInstalacionesDialog.name')"
                density="comfortable"
                v-model="item.nombre"
                hide-details="auto"
                :rules="rules"
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('gestionInstalacionesDialog.code')"
                density="comfortable"
                v-model="item.codigo"
                hide-details="auto"
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <SelectEstaticos
                class="mt-2"
                dense
                :version="1"
                :label="$t('gestionInstalacionesDialog.addressType')"
                v-model="item.tipo"
                :codigo="'tipos-de-direccion'"
                required
                @change="showMoreItems"
                :returnObject="true" />
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="9">
              <v-text-field
                :label="$t('gestionInstalacionesDialog.address')"
                density="comfortable"
                hide-details="auto"
                v-model="item.direccion"
                :rules="rules"
                dense></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                :label="$t('gestionInstalacionesDialog.postalCode')"
                density="comfortable"
                hide-details="auto"
                v-model="item.codigoPostal"
                dense></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-left:-20px;">
              <SelectLocalidad v-model="item.localidad" :required="true" :empresa="empresa" />
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="4">
              <v-text-field
                density="comfortable"
                hide-details="auto"
                :label="$t('gestionInstalacionesDialog.email')"
                v-model="item.email"
                dense></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                density="comfortable"
                hide-details="auto"
                :label="$t('gestionInstalacionesDialog.phone')"
                v-model="item.telefono"
                dense></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                density="comfortable"
                hide-details="auto"
                :label="$t('gestionInstalacionesDialog.phone2')"
                v-model="item.telefono2"
                dense></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="12">
              <v-textarea
                density="comfortable"
                rows="3"
                hide-details="auto"
                :label="$t('gestionInstalacionesDialog.description')"
                v-model="item.descripcion"
                dense></v-textarea>
            </v-col>

            <v-col cols="11" class="mx-auto mt-0 mb-3">
              <v-divider></v-divider>
            </v-col>
            <template v-if="ui.showMoreItems">
              <v-col cols="12" class="pt-0 pb-2">
                <v-switch
                  inset
                  v-model="item.empresaGrupo"
                  hide-details="auto"
                  color="green"
                  :label="$t('gestionInstalacionesDialog.groupCompany')">
                </v-switch>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :label="$t('gestionInstalacionesDialog.businessName')"
                  v-model="item.razonSocial"
                  required
                  dense></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field :label="$t('gestionInstalacionesDialog.nif')" v-model="item.nif" maxlength="11" dense></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field :label="$t('gestionInstalacionesDialog.rgseaa')" v-model="item.rgseaa" dense></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  density="comfortable"
                  hide-details="auto"
                  :label="$t('gestionInstalacionesDialog.ria')"
                  v-model="item.ria"
                  dense
                  required></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  density="comfortable"
                  hide-details="auto"
                  :label="$t('gestionInstalacionesDialog.rsi')"
                  v-model="item.rsi"
                  dense
                  required></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :label="$t('gestionInstalacionesDialog.validityDate')"
                      :value="formatDate(item.fechaValidez)"
                      clearable
                      dense
                      readonly
                      v-on="on"
                      @click:clear="item.fechaValidez = ''"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="item.fechaValidez"
                    locale="es"
                    color="primary"
                    :first-day-of-week="1"
                    no-title
                    scrollable
                    @change="menu = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="11" class="mx-auto mt-0 mb-3">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <SelectTipoInstalacion
                  dense
                  v-model="item.tipo_instalacions"
                  :rules="rules"
                  autoSelectField="codigo"
                  autoSelectValue="propia"
                  required />
              </v-col>

              <v-col cols="12">
                <v-textarea
                  :label="$t('gestionInstalacionesDialog.activities')"
                  v-model="item.tramites"
                  dense
                  auto-grow></v-textarea>
              </v-col>

              <v-col cols="12" v-if="isCerticalidad">
                <v-select
                  v-model="item.actividades_rgseaa"
                  :items="[
                    $t('gestionInstalacionesDialog.porkSlaughterhouse'),
                    $t('gestionInstalacionesDialog.ungulateMeat'),
                    $t('gestionInstalacionesDialog.meatProcessing'),
                    $t('gestionInstalacionesDialog.hamSalting'),
                    $t('gestionInstalacionesDialog.meatDrying'),
                    $t('gestionInstalacionesDialog.meatPackaging'),
                    $t('gestionInstalacionesDialog.animalProductRepacker'),
                    $t('gestionInstalacionesDialog.coldStorage'),
                    $t('gestionInstalacionesDialog.nonRefrigeratedStorage'),
                    $t('gestionInstalacionesDialog.polyvalentStorage'),
                    $t('gestionInstalacionesDialog.meatDistribution'),
                    $t('gestionInstalacionesDialog.ungulateMeatDistribution'),
                  ]"
                  :label="$t('gestionInstalacionesDialog.rgseaaActivities')"
                  hide-details="auto"
                  dense
                  multiple></v-select>
              </v-col>
              <v-col cols="11" class="mx-auto mt-0 mb-3">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  class="pa-0"
                  dense
                  v-model="item.certificada"
                  color="primary"
                  :label="$t('gestionInstalacionesDialog.certified')" />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :label="$t('gestionInstalacionesDialog.certifyingEntity')"
                  v-model="item.entidadCertificadora"
                  dense></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :label="$t('gestionInstalacionesDialog.certificateNumber')"
                  v-model="item.numeroCertificado"
                  dense></v-text-field>
              </v-col>

              <v-col
                class="row pa-3 mx-3"
                cols="12"
                style="border: 1px solid #ccc; border-radius: 3px">
                <h3 class="pb-2 col col-12">{{ $t('gestionInstalacionesDialog.customFields') }}</h3>
                <template v-if="!item.instalacionData || item.instalacionData.length == 0">
                  <v-btn @click="addCamposPersonalizados" outlined>{{ $t('gestionInstalacionesDialog.addFields') }}</v-btn>
                </template>
                <template v-else>
                  <v-col cols="6" v-for="(c, i) in item.instalacionData" :key="i">
                    <v-text-field
                      :label="c.keyname"
                      v-model="c.value"
                      outlined
                      dense
                      hide-details="auto"></v-text-field>
                  </v-col>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!ui.loading" class="pa-4">
          <v-btn text @click="close" :disabled="ui.loading">{{ $t('gestionInstalacionesDialog.cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="submit" :disabled="ui.loading || !ui.isValid">
            {{ $t('gestionInstalacionesDialog.save') }}</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else style="flex-direction: column" class="px-8">
          <div class="text-disabled d-block">{{ $t('gestionInstalacionesDialog.savingAddress') }}</div>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="4"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>


<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\clientes\instalaciones\GestionInstalacionesDefaultDialog.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Jul 24 2023
 **/

import notifications from '~/util/notificationHelper'

import CamposDinamicos from '~/components/clientes/instalaciones/araporcei/CamposDinamicos'
import CamposDinamicosManchengo from '~/components/clientes/instalaciones/manchengo/CamposDinamicos'
import SelectComunidad from '~/components/forms/SelectComunidad'
import SelectActividades from '~/components/forms/SelectActividades'
import SelectProvincia from '~/components/forms/SelectProvincia'
import SelectMunicipio from '~/components/forms/SelectMunicipio'
import SelectPais from '~/components/forms/SelectPais'
import SelectLocalidad from '../../forms/SelectLocalidades'
import SelectTipoInstalacion from '../../forms/SelectTipoInstalacion'
import SelectContactos from '../../forms/SelectContactos'
import SelectEstaticos from '~/components/forms/SelectEstaticos.vue'

import fieldsCerticalidad from '~/components/instalaciones/customFields/certicalidad'

export default {
  name: 'GestionInstalacionesDefaultDialog',
  props: {
    value: Boolean,
    cliente: { type: Object, default: null },
    instalacion: { type: Object, default: null },
    isPortal: { type: Boolean, default: false },
    location: { type: Object, default: null },
    show_toggles: { type: Boolean, default: true },
    is_contacto: { type: Boolean, default: false },
    is_empresa: { type: Boolean, default: false },
    empresa: { type: Object, default: null },
  },

  components: {
    SelectTipoInstalacion,
    SelectLocalidad,
    CamposDinamicos,
    SelectPais,
    SelectMunicipio,
    SelectProvincia,
    SelectComunidad,
    SelectActividades,
    SelectContactos,
    CamposDinamicosManchengo,
    fieldsCerticalidad,
    SelectEstaticos,
  },
  data: () => ({
    item: {
      action: 'create',
    },

    dummy: {
      principal: false,
    },
    menu: false,

    rules: [(v) => !!v || 'Obligatorio'],

    datos: [],
    fields: [],

    ui: {
      show: false,
      loading: false,
      isValid: false,
      showPicker: false,
      showMoreItems: false,
    },
  }),

  watch: {
    value(val) {
      if (val) {
        this.ui.show = true
        this.item = val
        this.item.action = 'update'
      }
    },

    instalacion(val) {
      console.info('🔴 instalacion', val)
      if (val) {
        this.ui.show = true
        this.item = val
        this.item.action = 'update'
        if (this.item.tipo == 'Instalación') this.ui.showMoreItems = true
        else this.ui.showMoreItems = false
      }
      //else this.resetForm()
    },
    show_toggles(val) {
      if (!val) this.item.principal = true
    },

    //'ui.show': function (value) {
    //  this.reset()
    //},
  },

  computed: {
    isNew() {
      return this.instalacion === null
    },
    empresa() {
      return this.is_empresa ? this.cliente : null
    },
    isNewCliente() {
      return this.$route.params.uuid === '_' && !this.cliente
    },
    isCerticalidad() {
      return this.$auth.user.empresa.codigo == 'certicalidad'
    },
    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },
  },

  methods: {
    close() {
      this.ui.show = false
      this.$emit('close')
    },
    showMoreItems() {
      console.log('showMoreItems', this.item.tipo)
      if (this.item.tipo.emplazamiento){ 
        this.ui.showMoreItems = true
      }
      else {
        this.ui.showMoreItems = false
        delete(this.item.tipo_instalacions)
      }
    },

    newItem() {
      this.reset()
      this.item = { ...this.dummy }
      this.ui.showMoreItems = false
      this.item.action = 'create'

      this.ui.show = true
    },

    nifResponsableErrors() {
      const errors = []
      if (!this.$v.item.nif.$dirty) return errors
      !this.$v.item.nif.minLength && errors.push(this.$t('gestionInstalacionesDialog.invalidDocument'))
      !this.nifResponsableValid && errors.push(this.$t('gestionInstalacionesDialog.invalidDocument'))
      return errors
    },

    checkResponsableDocument() {
      let document = 'OTRO'
      const passport = new RegExp('^[P](?:-)*[0-9]+$')
      const dni = new RegExp('^[A-Z](?:-)*[0-9]{8}$')
      const nie = new RegExp('^[A-Z][0-9]{7}(?:-)*[A-Z]$')
      const nif = new RegExp('^[0-9]{8}(?:-)*[A-Z]$')
      const other1 = new RegExp('^[0-9]{7}$')
      const other2 = new RegExp('^[0-9]{8}$')
      const other3 = new RegExp('^[0-9]{9}$')
      const other4 = new RegExp('^[0-9]{11}$')
      if (passport.test(this.cliente.responsable_nif)) document = 'PASAPORTE'
      if (dni.test(this.cliente.responsable_nif)) document = 'DNI'
      if (nie.test(this.cliente.responsable_nif)) document = 'NIE'
      if (nif.test(this.cliente.responsable_nif)) document = 'NIF'
      return document
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    formatDateBack(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    formatDateToApi(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : ''
    },

    addCamposPersonalizados(data) {
      this.item.instalacionData = [
        { keyname: 'superficie_total', value: data.superficie_total || '' },
        { keyname: 'edificado', value: data.edificado || '' },
        { keyname: 'cinicial', value: data.cinicial || '' },
        { keyname: 'ampliaciones', value: data.ampliaciones || '' },
        { keyname: 'situacion', value: data.situacion || '' },
        { keyname: 'curbano', value: data.curbano || '' },
        { keyname: 'pindustrial', value: data.pindustrial || '' },
        { keyname: 'trustico', value: data.trustico || '' },
      ]
      this.$forceUpdate()
    },

    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
    },

    // //+-------------------------------------------------
    // // updateLocation()
    // // -----
    // // Created on Fri Sep 24 2021
    // // Updated on Mon Oct 25 2021
    // //+-------------------------------------------------
    updateLocation(localidadUUID, object) {
      //debugger;
      /* if (object.pais?.uuid) this.item.pais.uuid = object.pais?.uuid
      if (object.comunidad?.uuid) this.item.comunidad.uuid = object.comunidad?.uuid
      if (object.provincia?.uuid) this.item.provincia.uuid = object.provincia?.uuid
      if (object.municipio?.uuid) this.item.municipio.uuid = object.municipio?.uuid */
      // if (object.codigo) this.item.cp = object.codigo

      this.$forceUpdate()
    },

    // async getOne() {
    //   const item = Object.assign({}, this.instalacion)
    //   if (!item.pais) item.pais = { uuid: '' }
    //   if (!item.contacto) item.contacto = { uuid: '' }
    //   if (!item.comunidad) item.comunidad = { uuid: '' }
    //   if (!item.provincia) item.provincia = { uuid: '' }
    //   if (!item.municipio) item.municipio = { uuid: '' }
    //   if (!item.localidad) item.localidad = { uuid: '' }

    //   // if (typeof item.instalacionData == 'object' && item.instalacionData.length == undefined)
    //   //   item.instalacionData = [item.instalacionData]

    //   this.item = item

    //   if (this.isQuesoManchego && Object.keys(item.instalacionData).length)
    //     this.addCamposPersonalizados(item.instalacionData)

    //   // console.info('this.item', this.item);
    // },

    async submit() {
      let step = false

      // 1 . Form validations
      if (await this.validate()) step = 'valid'

      // 2. Do api requests
      if (step == 'valid') await this.store()
      else
        notifications.show({
          text: this.$t('notifications.revision'),
          color: 'warning',
        })
    },

    async validate() {
      await this.$refs.form.resetValidation()
      await this.$refs.form.validate()

      if (this.ui.isValid) return true

      console.warn('🔴', this.$refs.form?.errorBag)
      return false
    },

    async NOPEsubmit() {
      if (this.$refs.form.validate()) {
        if (this.isQuesoManchego) {
         
        }

        const body = {
          cliente_uuid: this.cliente == null ? this.$route.params.uuid : this.cliente.uuid,
          instalacion: {
            ...this.item,
          },
        }

        body.instalacion.tramites = this.item.tramites.map((element) => element.uuid)
        body.instalacion.fecha_validez = this.formatDateToApi(this.item.fecha_validez)

        if (this.item.pais) body.instalacion.pais = this.item.pais.uuid
        if (this.item.comunidad) body.instalacion.comunidad = this.item.comunidad.uuid
        if (this.item.provincia) body.instalacion.provincia = this.item.provincia.uuid
        if (this.item.municipio) body.instalacion.municipio = this.item.municipio.uuid
        if (this.item.localidad) body.instalacion.localidad = this.item.localidad.uuid
        if (this.item.contacto) body.instalacion.contacto = this.item.contacto.uuid
        if (!this.item.fecha_validez) delete body.instalacion.fecha_validez

        if (body.instalacion.tipo_instalacions?.length > 0) {
          if (body.instalacion.tipo_instalacions[0].uuid) {
            let reduction = body.instalacion.tipo_instalacions.map(function (a) {
              return a.uuid
            })

            body.tiposInstalacion = reduction
          }
        }
        body.instalacionData = this.item.site_data

        if (this.isNew) {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/ADD_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/create', body)

          if (!this.isNewCliente) {
            this.$emit('created', this.item)
          }
        } else {
          this.isNewCliente
            ? await this.$store.commit('clientes_instalaciones/EDIT_NEW', this.item)
            : await this.$store.dispatch('clientes_instalaciones/update', body)

          if (!this.isNewCliente) {
            this.$emit('updated', this.item)
          }
        }
        if (!this.isNewCliente) {
          this.$emit('init')
        }
        this.close()
      }
    },

    formatToStore(data) {
      console.warn('✨ Formating to store', this.item)

      if (!data.user && this.cliente['@id']) {
        if (this.is_contacto) data.contacto = this.cliente['@id']
        if(this.is_empresa) data.company = this.cliente['@id']
        if (!this.is_contacto && !this.is_empresa) data.user = this.cliente['@id']
      }
      //check if data localidad is a string or an object
      if (typeof data.localidad == 'object')
        data.localidad = '/api/localidades/' + data.localidad.uuid
      else data.localidad = '/api/localidades/' + this.item.localidad
      if (typeof data.tipo == 'object') data.tipo = data.tipo.texto

      console.warn('✨ Formated', data)
      return data
    },

    async store() {
      this.ui.loading = true

      let index = this.item.index || 0
      let action = this.item.action

      let payload = { ...this.item }
      payload = this.formatToStore(payload)

        if (this.isNew && !this.cliente['@id']) {
        this.$emit('created', {
          index,
          action,
          item: payload,
        })
        this.ui.show = false
        return
      } else {
        try {
          if (action == 'create') await this.$store.dispatch('direcciones/create', payload)
          if (action == 'update') await this.$store.dispatch('direcciones/update', payload)

          this.$emit('stored', {
            index,
            action,
            item: payload,
          })

          this.ui.show = false
        } catch (e) {
          console.info(e)
          notifications.show({
            text: 'Ha ocurrido un error al guardar. Revisa todos los campos',
            color: 'error',
          })
        } finally {
          this.ui.loading = false
        }
      }
    },

    async init() {
      this.$nuxt.$on('localidades:selected', (payload) => {
        this.updateLocation(payload.uuid, payload.localidad)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('localidades:selected')
  },
}
</script>
