<template>
  <span>
    <!-- MENÚ LATERAL -->
    <v-navigation-drawer
      v-if="!$nuxt.isOffline"
      v-model="drawer"
      app
      right
      temporary
      width="600"
      class="elevation-6 fondo-app"
      style="background-color: rgb(246, 248, 249) !important; z-index: 10">
      <!-- Header -->
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title class="flex" style="width: 100%">
          Formatos disponibles de {{ subalcance?.nombre }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <v-alert type="error" v-if="!datos || (!datos.length && !db.formatos.length)">
          No hay datos para generar las plantillas
        </v-alert>

        <v-card flat :key="'panel_-' + ui.refresh">
          <v-card-text>
            <!-- list -->
            <v-row
              align="center"
              class="mx-1 my-2"
              style="background-color: white"
              v-for="(item, key) in formatosInAlcances"
              :key="key">
              <!-- v-for="(item, key) in db.formatos.slice().reverse()" -->
              <v-col class="grow">
                {{ item.nombre }}
                <code class="mx-1" style="font-size: 11px">{{ item.codigo }}</code>
                <!-- <code> {{item.alcance.nombre }}</code> -->
                <br />
                <span style="color: #8a8a8a; font-size: 0.85rem">
                  <v-icon small class="mr-1">mdi-calendar</v-icon>
                  {{ $moment(item.updated_at).format('D [de] MMMM, YYYY') }}
                </span>
              </v-col>
              <v-col class="shrink">
                <!-- <div style="font-size: 9px; margin: 0px auto; text-align: center; margin-bottom: -2px;">
                    Descargar documento
                  </div> -->
                <v-btn-toggle class="pa-2">
                  <v-btn small text color="info darken-2" @click.stop="generate(item)">
                    Word
                    <v-icon class="ml-2" color="info darken-2" small>mdi-file-word</v-icon>
                  </v-btn>

                  <!-- <v-btn small text color="error darken-2"
                      @click.stop="generate(item, 'pdf')">
                      PDF
                    </v-btn> -->
                </v-btn-toggle>

                <!-- <v-btn
                    depressed
                    small
                    color="primary"
                    class="white--text"
                    @click.stop="generate(item)">
                    <v-icon small class="mr-2">mdi-cloud</v-icon>
                    Generar archivo
                  </v-btn> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-navigation-drawer>

    <!-- CONFIRMACIÓN DE GENERACION DE FORMATO -->
    <confirmation
      v-model="dialogGenerar"
      text="Esto generará una plantilla con los últimos datos"
      textButton="Generar"
      @confirm="openFormato(tipo)" />

    <!-- CONFIRMACIÓN DE ENVIAR A CLIENTE -->
    <confirmation
      v-model="dialogSend"
      text="Enviar el documento seleccionado al cliente?"
      textButton="Enviar"
      @confirm="sendFormato()" />

    <!-- CONFIRMACIÓN DE BORRADO DE FORMATO -->
    <confirmation
      v-model="dialogDelete"
      text="¿Eliminar el documento seleccionado?"
      colorButton="error"
      @confirm="deleteFormato()" />
  </span>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    components/.slice().reverse().vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Wed Jan 25 2023
 **/

import format from '~/util/formatHelper'
import Confirmation from '~/components/Confirmation'
import Formatos from './Formatos'

export default {
  name: 'Formatos',
  props: {
    value: Boolean,
  },
  components: {
    Confirmation,
    Formatos,
  },

  data: () => ({
    tipo: '',
    drawer: false,
    dialogGenerar: false,
    dialogSend: false,
    dialogDelete: false,
    itemSelected: {},
    subalcance: null,
    db: {
      formatos: [],
      counter: {},
    },

    ui: {
      clean: 0,
      refresh: 0,
      panels: null,
    },
  }),

  watch: {
    value(val) {
      this.drawer = val
      this.ui.refresh++
      this.$forceUpdate()
    },
    drawer(val) {
      if (!val) this.$emit('close')
    },
    $route(to, from) {
      this.$store.commit('formatos/INIT')
      this.reset()
      this.getFormatos(to)
    },
  },

  computed: {
    isCliente() {
      return this.$store.getters.isCliente
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    formatos() {
      return this.$store.getters['formatos/LIST']
    },
    tiposFormatos() {
      return this.$store.state.formatos.tipos
    },
    verFormatos() {
      return this.$store.state.formatos.verFormatos && this.tiposFormatos.length
    },
    datos() {
      return this.$store.state.formatos.datos
    },

    //+-------------------------------------------------
    // hayFormatos()
    // Computed para comprobar si hay formatos en algunas
    // de las dos stores. Se utiliza una computed en vez de un
    // getter para simplificar el flujo de información
    // Created on Fri May 28 2021
    //+-------------------------------------------------
    hayFormatos() {
      return this.db.formatos?.length > 0 || this.verFormatos
    },

    //+-------------------------------------------------
    // formatosCount()
    // Computed que devuelve el número de formatos disponibles,
    // ya sea mediante el sistema antiguo o el nuevo
    // -----
    // Created on Fri May 28 2021
    //+-------------------------------------------------
    formatosCount() {
      return this.db.formatos ? this.db.formatos.length + this.formatos.length : 0
    },

    //+-------------------------------------------------
    // formatosInAlcances()
    // Returns all formatos in an array keyed by alcance
    // also counts the number of formatos in each alcance
    // -----
    // Created on Tue Jun 07 2022
    //+-------------------------------------------------
    formatosInAlcances() {
      let items = {}

      if (this.ui.refresh == 0) return []
      if (this.db.formatos?.length == 0) return []

      this.db.formatos.forEach((item) => {
        // console.warn(item, item.alcance?.codigo)
        if (item.subalcance?.nombre) {
          // Contabilize the amount of documents
          if (!this.db.counter[item.subalcance.nombre]) {
            this.db.counter[item.subalcance.nombre] = {
              nombre: item.subalcance?.nombre,
              count: 0,
            }
          }

          if (!items[item.subalcance.nombre]) {
            items[item.subalcance.nombre] = []
          }

          items[item.subalcance.nombre].push(item)
          this.db.counter[item.subalcance.nombre].count++
          // console.warn(items, items[item.alcance.codigo])
        }
      })
      // only keep the items with same subalcance as the current
      items = items[this.subalcance?.nombre] || []

      // console.warn('formatos in alcances', items)
      this.$forceUpdate()
      return items
    },

    alcancesDeFormato() {
      let items = this.formatosInAlcances
      if (items.length == 0) return []

      return this.db.counter
    },
  },

  methods: {
    confirmGenerarFormato(tipo) {
      this.tipo = tipo
      this.dialogGenerar = true
    },

    openFormato(tipo, documento) {
      const uuid = documento && documento.uuid
      if (uuid) this.$store.commit('formatos/SET_UUID', uuid)
      const route = '/formatos?plantilla=' + tipo
      this.$router.push(route)
    },

    //+-------------------------------------------------
    // getModAndRef()
    // Returns modulo and modulo_ref from URL
    // -----
    // Updated on Thu Jul 28 2022
    //+-------------------------------------------------
    getModAndRef(route) {
      const path = route.path.split('/')
      let modulo = path[1]
      let modulo_ref = path[2]

      if (modulo === 'grupo_solicitudes') modulo = 'solicitudes'
      if (modulo === 'portal') return {}
      if (modulo_ref == 'lotes') modulo_ref = path[3]
      if (this.$route.query?.auditoria_uuid) modulo_ref = this.$route.query?.auditoria_uuid
      return {
        modulo: modulo,
        modulo_ref: modulo_ref,
      }
    },

    async getFormatos(route, subalcance) {
      this.offlineMode ? null : await this.$store.dispatch(`formatos/list`, route, subalcance)
      this.subalcance = subalcance
      if (!this.offlineMode) {
        let data = this.getModAndRef(route)
        if (data.modulo_ref == undefined) return
        if (subalcance) data['subalcance.uuid'] = subalcance.uuid
        const xhr = await this.$store.dispatch(`formato-plantillas/list`, data)
        if (xhr) this.db.formatos = xhr
      }

      this.ui.refresh++
      this.$forceUpdate()
    },

    reset() {
      this.ui.refresh = 0
      this.db.counter = {}
      this.db.formatos = []
    },

    confirmSend(tipo, idx, documento) {
      this.itemSelected.tipo = tipo
      this.itemSelected.idx = idx
      this.itemSelected.formato = documento.uuid
      this.itemSelected.modulo_ref = documento.modulo_ref
      this.itemSelected.modulo = documento.modulo
      this.dialogSend = true
    },

    //+-------------------------------------------------
    // generate()
    // Calls the new formatos api to generate a new dynamic file
    // The file is then downloaded
    // Update: Calls for documents:generated with the result blob
    // -----
    // Created on Fri Apr 30 2021
    // Updated on Tue Jan 04 2022
    //+-------------------------------------------------
    async generate(item, format) {
      let data = this.getModAndRef(this.$route)

      let name = `formato ${item.codigo} - ${item.nombre} - `
      name = name + this.$moment().format('DD-MM-YYYY')

      await this.$store.dispatch(`formato-plantillas/generate`, {
        modulo: item.modulo,
        formato: format,
        modulo_ref: data.modulo_ref,
        formato_plantilla: item.uuid,
        document: {
          name: name,
          modulo: item.modulo,
          modulo_ref: data.modulo_ref,
          submodulo: '',
          submodulo_ref: '',
          cliente: this.datos[0]?.cliente.uuid,
          cliente_name: this.datos[0]?.cliente?.nombre,
        },
      })
    },

    //+-------------------------------------------------
    // sendFormato()
    // Sends an order to the store to perform an api call
    // Pass document uuid
    // -----
    // Created on Thu Feb 04 2021
    //+-------------------------------------------------
    async sendFormato() {
      this.dialogSend = false

      const tipo = this.itemSelected.tipo
      const idx = this.itemSelected.idx
      this.formatos[tipo][idx].sent_by = this.$auth.user.email
      this.formatos[tipo][idx].sent_date = new Date()

      await this.$store.dispatch('formatos/send', {
        // cliente: ,
        formato: this.itemSelected.formato,
        modulo: this.itemSelected.modulo,
        modulo_ref: this.itemSelected.modulo_ref,
      })

      this.itemSelected = {}
    },

    confirmDeleteFormato(item) {
      this.itemSelected = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteFormato() {
      this.dialogDelete = false
      await this.$store.dispatch('formatos/delete', this.itemSelected.uuid)
      this.itemSelected = {}
      this.getFormatos(this.$route)
    },
  },

  mounted() {
    console.log('Formatos componente', this.$route)
    // this.getFormatos(this.$route)

    this.$nuxt.$on('formatos:load', (payload) => {
      this.getFormatos(this.$route, payload.subalcance)
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('formatos:load')
  },
}
</script>
