<template>
  <v-app>
    <notification />
    <v-main>
      <Navigation />
      <v-container class="main-container" :class="padding">
        <v-fade-transition>
          <Nuxt />
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '~/components/portal/Navigation'
import Notification from '~/components/Notification'

export default {
  components: {
    Notification,
    Navigation,
  },
  computed: {
    isPdf() {
      return this.$route.name === 'formatos'
    },
    isPlanificador() {
      return this.$route.name === 'planificador'
    },
    padding() {
      return this.isPlanificador ? 'pa-0' : this.isPdf ? 'white pa-0' : 'px-8'
    },
  },
  mounted() {
    // Asegurarse de que el usuario esté autenticado y aplicar el color
    if (this.$auth.loggedIn) {
      this.applyUserPreferences();
    }
  },
  watch: {
    // Observar cambios en las preferencias de color del usuario
    '$auth.user.preferences.color': function (newColor) {
      this.applyUserPreferences(newColor);
    },
  },
  methods: {
    // Método para aplicar las preferencias del usuario, si están disponibles
    applyUserPreferences(newColor = null) {
      const userPreferences = this.$auth.user.preferences;

      if (userPreferences && userPreferences.color) {
        const colorToSet = newColor || userPreferences.color;

        // Validar el formato del color (debería ser HEX o un nombre de color)
        if (this.isValidColor(colorToSet)) {
          // Forzar la actualización de Vuetify con el nuevo color
          if (this.$vuetify && this.$vuetify.theme && this.$vuetify.theme.themes) {
            this.$set(this.$vuetify.theme.themes.light, 'primary', colorToSet); // Forzar reactividad
            console.log('Color primario aplicado:', colorToSet);

            // Forzar el renderizado en el DOM
            this.$nextTick(() => {
              console.log('Color forzado con $nextTick:', colorToSet);
            });
          }
        } else {
          console.error('El formato del color es inválido:', colorToSet);
        }
      } else {
        console.log('No se encontraron preferencias de color');
      }
    },
    // Validar si el color es un valor válido (puede ser HEX o un nombre de color)
    isValidColor(color) {
      const isHexColor = /^#[0-9A-F]{6}$/i.test(color); // Verifica formato HEX
      const validColors = ['blue', 'red', 'green', 'yellow', 'orange', 'purple', 'pink', 'grey']; // Colores Material Design

      return isHexColor || validColors.includes(color);
    },
  },
}
</script>

<style scoped>
.main-container {
  max-width: 100% !important;
}
.ag-theme-material .ag-icon-checkbox-checked {
  color: #2e3444 !important;
}
</style>
