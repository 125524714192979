<template>
  <div>
    <v-card outlined>
      <v-card-title>
        {{ $t('datosMuestra.title') }}
        <v-spacer></v-spacer>

        <!-- Añadir Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="success" class="white--text mr-2" v-on="on"
              :disabled="auditoriaCerrada || isDisabled" @click="editItem(item)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('datosMuestra.addRow') }}</span>
        </v-tooltip>

        <!-- Eliminar Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn x-small fab depressed color="error" class="white--text" v-on="on"
              :disabled="auditoriaCerrada || isDisabled" @click="removeRow()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('datosMuestra.deleteRow') }}</span>
        </v-tooltip>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <AgDatatable :title="$t('datosMuestra.tableTitle')" :headers="grid.columnDefs" :items="grid.rowData" :height="'65'"
          :rowSelection="'multiple'" sizeColumnsToFit hideOverlay emitClickRow @click-row="editItem($event)"
          @gridApi="onGridReady($event)" />
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="750px">
      <v-card v-if="dialog">
        <v-card-title primary-title>
          {{ $t('datosMuestra.sampleData') }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <template v-if="!isQuesoManchego">
                <v-col :cols="header.tipo === 'texto_largo' ? 12 : 6 " v-for="header in fields" :key="header.id">

                  <v-text-field v-if="header.tipo === 'texto'" :label="$t(header.texto)" v-model="item[header.valor]"
                    required outlined flat dense hide-details="auto"></v-text-field>
                  <v-text-field v-if="header.tipo === 'numerico'" :label="$t(header.texto)" type="number"
                    v-model="item[header.valor]" required outlined flat dense hide-details="auto"></v-text-field>

                  <v-menu v-if="header.tipo === 'fecha'" close-on-content-click :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field :value="formatDate(item[header.valor])" :label="$t(header.texto)" prepend-inner-icon="event"
                        clearable outlined flat dense readonly v-on="on"
                        @click:clear="item[header.valor] = ''"></v-text-field>
                    </template>
                    <v-date-picker v-model="item[header.valor]" color="primary" :first-day-of-week="1">
                    </v-date-picker>
                  </v-menu>

                    <SelectEstaticos v-if="header.tipo === 'estatico'" v-model="item[header.valor]" :label="$t(header.texto)"
                    :alcance="auditoria.subalcance.uuid" :estaticos="estaticos" :codigo="header.tipoEstatico" multiple outlined required dense />

                  <v-textarea v-if="header.tipo === 'texto_largo'" :label="$t(header.texto)" v-model="item[header.valor]"
                    required outlined flat dense hide-details="auto"></v-textarea>
                </v-col>
              </template>

              <template v-if="isOceGlobal">
                <v-col cols="12">
                  <v-select :label="$t('datosMuestra.parcels')" :items="auditoria.sites" outlined dense hide-details="auto"
                    :item-text="nombreYLugar" item-value="site_data" autocomplete @change="setSite"></v-select>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.crop')" v-model="item.cultivo" disabled outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.municipality')" v-model="item.municipio" disabled outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.polygon')" v-model="item.poligono" disabled outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.plot')" v-model="item.parcela" disabled outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.enclosure')" v-model="item.recinto" disabled outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>
              </template>

              <template v-if="isQuesoManchego">

                <v-col cols="12">
                  <h3>{{ $t('datosMuestra.sampleReportTitle') }}</h3>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.sampleId')" v-model="item.identificacion_muestra" outlined flat
                    dense hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-dialog ref="datepicker" v-model="ui.showPicker" :return-value.sync="item.fecha" persistent
                    width="290px">

                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="$moment(item.fecha).format('D [de] MMMM, YYYY')"
                        :label="$t('datosMuestra.elaborationDate')" outlined flat dense hide-details="auto" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>

                    <v-date-picker v-model="item.fecha" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="ui.showPicker = false">
                        {{ $t('datosMuestra.cancel') }}
                      </v-btn>

                      <v-btn text color="primary" @click="$refs.datepicker.save(item.fecha);">
                        {{ $t('datosMuestra.accept') }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.analysis')" v-model="item.analisis" outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <h3>{{ $t('datosMuestra.inspectionSheetTitle') }}</h3>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.commercialBrand')" v-model="item.marca_comercial" outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.caseinDisk')" v-model="item.disco_caseina" outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field :label="$t('datosMuestra.counterLabel')" v-model="item.contraetiqueta" outlined flat dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea v-model="item.observaciones" :label="$t('datosMuestra.observations')" auto-grow outlined></v-textarea>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="mr-3" @click="close()">
            {{ $t('datosMuestra.cancel') }}
          </v-btn>
          <v-btn depressed color="primary" @click="saveItem()">
            {{ $t('datosMuestra.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\auditorias\muestras\DatosMuestra.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Dec 27 2022
 **/

import Estados from '~/models/estados'
import AgDatatable from '~/components/AgDatatable'
import moment from 'moment'
import SelectEstaticos from '~/components/forms/SelectEstaticos.vue';

export default {
  props: {
    value: { type: Array, default: () => [] },
    auditoria: { type: Object, default: () => {} },
    codigo: { type: String, default: () => '' },
    isDisabled: { type: Boolean, default: false },
  },
  components: {
    AgDatatable,
    SelectEstaticos,
  },
  data: () => ({
    dialog: false,
    item: {
      fecha: moment().format('YYYY-MM-DD'),
    },
    estaticos: [],
    fields: [],
    itemSelected: null,
    grid: { api: null, title: '', columnDefs: [], rowData: [] },

    ui: {
      showPicker: false,
    },
  }),
  watch: {
    value(val) {
      this.grid.rowData = val
    },
  },
  computed: {
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },

    auditoriaCerrada() {
      return (
        (this.auditoria &&
          this.auditoria.estado?.toUpperCase() === Estados.AUDITORIA_ESTADO.CERRADA) ||
        (this.auditoria && this.auditoria.estado?.toUpperCase() == 'REALIZADA')
      )
    },
  },
  methods: {
    nombreYLugar(item) {
      if (!item.site_data) return item.nombre

      const mun_n = item.site_data.find((el) => el.keyname === 'municipio_nombre') || { value: '' }
      const mun_c = item.site_data.find((el) => el.keyname === 'municipio_codigo') || { value: '' }
      const poligono = item.site_data.find((elem) => elem.keyname === 'polg') || { value: '' }
      const parcela = item.site_data.find((elem) => elem.keyname === 'parcela') || { value: '' }
      const recinto = item.site_data.find((elem) => elem.keyname === 'recinto') || { value: '' }

      const nombre = item.site_data.find((elem) => elem.keyname === 'nombre')
      if (!nombre) {
        item.site_data.push({ keyname: 'nombre', value: item.nombre })
      }

      // return `${item.nombre} -- (Mun: ${mun.value} - Pol: ${poligono.value} - Par: ${parcela.value} - Rec: ${recinto.value}`
      return `${item.nombre} -- ( ${mun_n.value} / ${poligono.value} / ${parcela.value} / ${recinto.value} )`
    },

    setSite(site) {
      this.item.cultivo = site.find((elem) => elem.keyname === 'nombre')?.value
      this.item.municipio = site.find((elem) => elem.keyname === 'municipio_nombre')?.value
      this.item.poligono = site.find((elem) => elem.keyname === 'polg')?.value
      this.item.parcela = site.find((elem) => elem.keyname === 'parcela')?.value
      this.item.recinto = site.find((elem) => elem.keyname === 'recinto')?.value

      this.$forceUpdate()
    },

    emitChange() {
      this.$emit('input', this.grid.rowData)
      this.$emit('change', this.grid.rowData)
    },
    async init() {
      console.log('init -> this.auditoria', this.auditoria)
      const codigo = 'datos-de-muestra'
      const body = {
        //empresa.uuid: this.$auth.user.empresa.uuid,
        'subalcance.uuid': this.auditoria.subalcance.uuid,
        codigo: codigo,
      }
      let element = null
      if (this.offlineMode) {
        const storage = JSON.parse(localStorage.getItem('estaticos'))
        element = storage && storage[codigo]
      } else {
        const res = await this.$store.dispatch('estaticos/listMember', body)
        console.log('init -> res', res)
        if (res && res.length) element = res[0]
        const body1 = {
        'subalcance.uuid': this.auditoria.subalcance.uuid,
      }
        const res1 = await this.$store.dispatch('estaticos/listMember', body1)
        if (res1) this.estaticos = res1
      }

      console.warn(element)
      if (element) {
        this.grid.title = element.codigo_nombre
        const items = element.valor
        const columnDefs = []

        columnDefs.push({
          headerName: '#',
          sortable: true,
          filter: true,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          valueGetter: (params) => params.node.rowIndex + 1,
        })

        for (let i = 0; i < items.length; i++) {
          const element = items[i]
          const obj = {
            headerName: element.texto,
            field: element.valor,
            sortable: true,
            filter: true,
            resizable: true,
            editable: true,
          }
          columnDefs.push(obj)
        }

        if (this.isOceGlobal) {
          columnDefs.push({
            headerName: 'Cultivo',
            field: 'cultivo',
          })

          columnDefs.push({
            headerName: 'Municipio',
            field: 'municipio',
          })

          columnDefs.push({
            headerName: 'Polígono',
            field: 'poligono',
          })

          columnDefs.push({
            headerName: 'Parcela',
            field: 'parcela',
          })

          columnDefs.push({
            headerName: 'Recinto',
            field: 'recinto',
          })
        }

        this.grid.columnDefs = columnDefs
        this.fields = items
      }
      this.grid.rowData = this.value
      this.grid.api.refreshCells()
      console.log('init -> this.items', this.items, this.grid.rowData)
      console.log('init -> this.fields', this.fields)
      console.log('init -> this.item', this.item)
    },
    onGridReady(api) {
      this.grid.api = api
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    close() {
      this.editIndex = -1
      this.item = {}
      this.dialog = false
    },
    editItem(item) {
      this.editIndex = this.grid.rowData.indexOf(item)
      this.item = item ? Object.assign({}, item) : {}
      this.item.codigo_muestra = this.codigo
      this.item.fecha = new Date().toISOString().substr(0, 10)
      this.dialog = true
    },
    saveItem() {
      this.editIndex > -1 ? this.editRow() : this.addRow()
    },
    addRow() {
      this.grid.rowData.push(this.item)
      this.grid.api.updateRowData({ add: [this.item] })
      this.close()
      this.emitChange()
    },
    editRow() {
      this.grid.rowData.splice(this.editIndex, 1, this.item)
      this.close()
      this.emitChange()
    },
    removeRow() {
      const selectedRows = this.grid.api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach((element) => {
          const index = this.grid.rowData.indexOf(element)
          this.grid.rowData.splice(index, 1)
        })
      }
      this.grid.api.updateRowData({ remove: this.grid.api.getSelectedRows() })
      this.emitChange()
    },
  },

  created() {
    this.init()
  },
}
</script>
