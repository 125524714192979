<template>
  <v-dialog eager v-model="dialog" :max-width="conditions ? '800' : '600'" persistent>
    <v-card>
      <v-container class="title text-center" v-html="text" style="word-break: initial !important"></v-container>
      <v-container v-if="secondaryText" class="text-subtitle-2" v-html="secondaryText" style="word-break: initial !important"></v-container>
      <slot />

      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="red darken-1"
          class="mr-3"
          text
          @click="close()"
        >
          {{ $t('confirmation.cancel') }}
        </v-btn>

        <v-btn
          class="mx-3"
          :color="colorButton"
          :disabled="!valid"
          depressed
          @click="$emit('confirm', true)"
        >
          {{ textButton }}
        </v-btn>

        <v-btn v-if="secondary"
          class="mr-3"
          :color="secondaryColorButton"
          :disabled="!valid"
          depressed
          @click="$emit('confirm2', true)"
        >
          {{ secondaryTextButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: 'Confirmation',
  props: {
    value: Boolean,
    dark: { type: Boolean, default: false },
    valid: { type: Boolean, default: true },
    conditions: { type: Boolean, default: false },
    text: { type: String, default: '¿Está seguro de que desea eliminar el objeto seleccionado?' },
    textButton: { type: String, default: 'Eliminar' },
    secondaryText: { type: String, default: '' },
    secondaryTextButton: { type: String, default: '' },
    colorButton: { type: String, default: 'primary' },
    secondary: { type: Boolean, default: false },
    secondaryColorButton: { type: String, default: 'primary darken-2' },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      val || this.close()
    },
  },
  methods: {
    close() {
      this.dialog = false
      this.$emit('input', null)
      this.$emit('close')
    },
  },
}
</script>
