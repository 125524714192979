import ObjectTools from '../plugins/ObjectTools'
import moment from 'moment'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, uuid) {
    rootState.loading = true
    const body = {}
    if (uuid) body.expediente = uuid
    const res = await this.$axios.get('dictamenes', body)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async listByIteracion({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`dictamenes?iteracion.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async listBySubalcance({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`dictamenes?subalcance.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`dictamenes/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, dictamen) {
    rootState.loading = true
    await this.$axios.post('dictamenes', dictamen)
    rootState.loading = false
  },

  async update({ rootState, dispatch }, dictamen) {
    console.log('update -> dictamen', dictamen)
    let body = {
      estado: dictamen.estado,
      proposicion: dictamen.proposicion,
      certificadoEmitido: dictamen.certificadoEmitido,
      fechaProposicion: moment(dictamen.fechaProposicion).format('YYYY-MM-DD'),
      decision: dictamen.decision,
      fechaDecision: moment(dictamen.fechaDecision).format('YYYY-MM-DD'),
      accion: dictamen.accion,
      motivo: dictamen.motivo,
      dictamen: dictamen['@id'],
      auditor1: dictamen.auditor1 ? dictamen.auditor1['@id'] : null,
      auditor2: dictamen.auditor2 ? dictamen.auditor2['@id'] : null,
      enCalidadDeAuditor1: dictamen.enCalidadDeAuditor1,
      enCalidadDeAuditor2: dictamen.enCalidadDeAuditor2,
      revision: dictamen.revision['@id'],
    }

    if (dictamen.desde) body.suspendidoDesde = moment(dictamen.desde).format('YYYY-MM-DD')
    if (dictamen.hasta) body.suspendidoHasta = moment(dictamen.hasta).format('YYYY-MM-DD')
    if (dictamen.noConformidades)
      body.noConformidades = dictamen.noConformidades.map((element) => element.uuid)

    console.info('body', body)
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async delete({ rootState }, dictamen) {
    rootState.loading = true
    await this.$axios.delete(`dictamenes/${dictamen.uuid}`)
    rootState.loading = false
  },

  async noemitir({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/no-emitir`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
  },

  async suspender({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(
      `dictamenes/${dictamen.uuid}/suspender`,
      {
        dictamen: dictamen.uuid,
        desde: moment(dictamen.desde).format('YYYY-MM-DD'),
        hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
        motivo: dictamen.comentario,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  async no_emitir({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(
      `dictamenes/${dictamen.uuid}/no-emitir`,
      {
        dictamen: dictamen.uuid,
        motivo: dictamen.comentario,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  async retirar({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(
      `dictamenes/${dictamen.uuid}/retirar`,
      {
        dictamen: dictamen.uuid,
        desde: moment(dictamen.desde).format('YYYY-MM-DD'),
        hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
        motivo: dictamen.comentario,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  async baja_temporal({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(
      `dictamenes/${dictamen.uuid}/baja-temporal`,
      {
        dictamen: dictamen.uuid,
        desde: moment(dictamen.desde).format('YYYY-MM-DD'),
        hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
        motivo: dictamen.comentario,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  async baja_voluntaria({ rootState }, dictamen) {
    rootState.loading = true

    await this.$axios.patch(
      `dictamenes/${dictamen.uuid}/baja-voluntaria`,
      {
        dictamen: dictamen.uuid,
        desde: moment(dictamen.desde).format('YYYY-MM-DD'),
        hasta: moment(dictamen.hasta).format('YYYY-MM-DD'),
        motivo: dictamen.comentario,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  async levantar({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/levantar`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async ampliacion({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/ampliacion`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async reduccion({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/reduccion`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async mantener({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/mantener`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async apercibimento({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/apercibir`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
  },

  async emitir({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/emitir`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async renovar({ rootState }, dictamen) {
    const body = {
      dictamen: dictamen.uuid,
    }
    rootState.loading = true
    await this.$axios.patch(`dictamenes/${dictamen.uuid}/renovar`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },


  async addPlanificacion({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'addPlanificacion',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('dictamen'))
      storage.planificaciones.push(data.planificacion)
      localStorage.setItem('dictamen', JSON.stringify(storage))

      rootState.loading = false
      return storage
    } else {
      const planificacion = data.planificacion
      console.info('plan', planificacion)
      const res = await this.$axios.patch(
        `dictamenes/${data.dictamen}/addChecklist`,
        planificacion,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      rootState.loading = false
      if (res) return res.data
    }
  },

  async editRespuesta({ rootState, state, commit, dispatch }, params) {
    console.log('[STORE] editRespuesta', params)
    if (state.offlineMode) {
      const action = {
        action: 'editRespuesta',
        body: params,
      }
      commit('OFFLINE_ACTION', action)
      const storage = JSON.parse(localStorage.getItem('dictamen_checklist'))
      storage.forEach((element) => {
        if (element.uuid === params.checklist_uuid) {
          element.preguntas.forEach((item) => {
            if (item.uuid === params.checklist_pregunta_uuid) {
              if (params.observacion) item.observacion = params.observacion
              if (params.no_conformidad) item.no_conformidad = params.no_conformidad
              if (params.respuesta) item.respuesta = params.respuesta['@id']
              if (params.nivel) item.nivel = params.nivel
              if (params.numero) item.numero = params.numero
              if (params.comentarios) item.comentarios = params.comentarios
              if (params.requisito_afectado) item.requisito_afectado = params.requisito_afectado
            }
          })
        }
      })
      localStorage.setItem('dictamen_checklist', JSON.stringify(storage))
      //dispatch('noConformidadesPendientesPac')
    } else {
      const body = {
        ...params,
      }
      console.log('[STORE] editRespuesta', body)
      const res = await this.$axios.patch(
        `pregunta_checklists/${params.checklist_pregunta_uuid}`,
        body,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      if (res) return res.data
    }
  },
  
  async saveChecklist({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'saveChecklist',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('dictamen_checklist'))
      storage.forEach((element) => {
        if (element.uuid === data.checklist_uuid) {
          if (data.fecha) element.fecha = data.fecha
          if (data.inicio) element.inicio = data.inicio
          if (data.fin) element.fin = data.fin
        }
      })
      localStorage.setItem('dictamen_checklist', JSON.stringify(storage))
    } else {
      await this.$axios.patch(`checklists/${data.checklist_uuid}`, data, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
    }
    rootState.loading = false
  },

}
