import formatHelper from '~/util/formatHelper'

export const state = () => ({
  items: [],
})

export const mutations = {
  setLicenses(state, licenses) {
    state.items = licenses
  },
  addLicense(state, license) {
    state.items.push(license)
  },
  updateLicense(state, updatedLicense) {
    const index = state.items.findIndex((item) => item.uuid === updatedLicense.uuid)
    if (index !== -1) {
      state.items.splice(index, 1, updatedLicense)
    }
  },
  deleteLicense(state, uuid) {
    state.items = state.items.filter((item) => item.uuid !== uuid)
  },
  resetLicenses(state) {
    state.items = []
  }
}

export const actions = {
  async fetchUserMemberships({ commit }, params) {
    let url = 'licenses'
    const queryParams = []
    console.log('My params are: ', params)
    if (params['user.uuid']) {
      queryParams.push(`user.uuid=${params['user.uuid']}`)
    }

    if (params.tipo) {
      queryParams.push(`tipo=${params.tipo}`)
    }

    if (queryParams.length) {
      url += '?' + queryParams.join('&')
    }

    const licensesResponse = await this.$axios.$get(url)
    const licenses = licensesResponse['hydra:member']
    commit('setLicenses', licenses)
  },

  async fetchCompanyMemberships({ commit }, params) {
    let url = 'licenses' 
    const queryParams = []

    if (params['empresa.uuid']) {
      queryParams.push(`empresa.uuid=${params['empresa.uuid']}`)
    }

    if (params.tipo) {
      queryParams.push(`tipo=${params.tipo}`)
    }

    if (queryParams.length) {
      url += '?' + queryParams.join('&')
    }

    const licensesResponse = await this.$axios.$get(url)
    const licenses = licensesResponse['hydra:member']
    commit('setLicenses', licenses)
  },

  async createLicenseMembership({ commit }, license) {
    license = formatHelper.nestedToIRI(license)
    const response = await this.$axios.post('licenses', license)
    commit('addLicense', response.data)
  },
  async updateLicenseMembership({ commit }, license) {
    // Simulación de actualización en API
    commit('updateLicense', license)
  },
  async deleteLicenseMembership({ commit }, uuid) {
    await this.$axios.delete(`licenses/${uuid}`)
    commit('deleteLicense', uuid)
  },

  async createLicenseBrand({ commit }, license) {
    license = formatHelper.nestedToIRI(license)
    const response = await this.$axios.post('licenses', license)
    commit('addLicense', response.data)
  },
  async updateLicenseBrand({ commit }, license) {
    // Simulación de actualización en API
    commit('updateLicense', license)
  },
  async deleteLicenseBrand({ commit }, uuid) {
    await this.$axios.delete(`licenses/${uuid}`)
    commit('deleteLicense', uuid)
  },
}
