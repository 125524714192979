export const state = () => ({
  reportes: [],
  reporte: {},
  lineasReporte: [],
})

export const mutations = {
  SET_REPORTES(state, reportes) {
    state.reportes = reportes
  },
  SET_REPORTE(state, reporte) {
    state.reporte = reporte
  },
  ADD_REPORTE(state, reporte) {
    state.reportes.push(reporte)
  },
  REMOVE_REPORTE(state, reporte) {
    state.reportes = state.reportes.filter((r) => r['@id'] !== reporte['@id'])
  },
  UPDATE_REPORTE(state, reporte) {
    const index = state.reportes.findIndex((r) => r['@id'] === reporte['@id'])
    state.reportes.splice(index, 1, reporte)
  },
  SET_LINEAS_REPORTE(state, lineasReporte) {
    state.lineasReporte = lineasReporte
  },
  ADD_LINEA_REPORTE(state, lineaReporte) {
    state.lineasReporte.push(lineaReporte)
  },
  REMOVE_LINEA_REPORTE(state, lineaReporte) {
    state.lineasReporte = state.lineasReporte.filter((lr) => lr['@id'] !== lineaReporte['@id'])
  },
  UPDATE_LINEA_REPORTE(state, lineaReporte) {
    const index = state.lineasReporte.findIndex((lr) => lr['@id'] === lineaReporte['@id'])
    state.lineasReporte.splice(index, 1, lineaReporte)
  },
}

export const actions = {
  async fetchReportes({ commit }, params) {
    let url = 'reportes'
    if (params?.codigo) {
      url += `?codigo=${params.codigo}`
    }
    if (params?.user) {
      url += url.includes('?') ? '&' : '?'
      url += `user.uuid=${params.user}`
    }
    const reportes = await this.$axios.$get(url)
    commit('SET_REPORTES', reportes['hydra:member'])
  },
  async fetchLineasReporte({ commit }, params) {
    let url = 'linea_reportes'
    if (params?.filters) {
      params.filters = params.filters.filter((filter) => {
        if (filter.campo === 'operador') {
          params.user = filter.valor.uuid
          return false
        }
        return true
      })

      console.log('Fetching reportes with params:', params.filters)
      url += '?'
      for (const filter of params.filters) {
        const { campo, operador, valor, tipo } = filter
        url += `campos[${campo}][${operador}][${tipo}]=${valor}&`
      }
      // Remover el último &
      url = url.slice(0, -1)
    }
    if (params?.subalcance) {
      url += url.includes('?') ? '&' : '?'
      url += `subalcance.uuid=${params.subalcance}`
    }
    if (params?.codigo) {
      url += url.includes('?') ? '&' : '?'
      url += `reporte.codigo=${params.codigo}`
    }
    if (params?.user) {
      url += url.includes('?') ? '&' : '?'
      url += `user.uuid=${params.user}`
    }
    const reportes = await this.$axios.$get(url)
    commit('SET_LINEAS_REPORTE', reportes['hydra:member'])
  },
  async fetchReporte({ commit }, id) {
    const reporte = await this.$axios.$get(`reportes/${id}`)
    commit('SET_REPORTE', reporte)
  },
  async createReporte({ commit }, reporte) {
    const newReporte = await this.$axios.$post('reportes', reporte)
    commit('ADD_REPORTE', newReporte)
  },
  async updateReporte({ commit }, reporte) {
    const updatedReporte = await this.$axios.$patch('reportes/' + reporte.id, reporte)
    commit('UPDATE_REPORTE', updatedReporte)
  },
  async deleteReporte({ commit }, reporte) {
    await this.$axios.$delete(reporte['@id'])
    commit('REMOVE_REPORTE', reporte)
  },
}
