<template>
  <div>

    <loading v-model="$store.getters.loading" text="Espere por favor..."/>

    <!-- HEADER -->
    <header-component noAction v-if="!cliente && !expediente && !uuid"/>

    <!-- LISTADO -->
    <v-row>
      <v-col cols="12">
        <ag-datatable :headers="headers" :items="items" emitClickRow @click-row="clickRow" urlData="certificados"></ag-datatable>
      </v-col>
    </v-row>

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    certificados/index.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Thu Feb 02 2023
 **/

import HeaderComponent from '~/components/HeaderComponent'
import AgDatatable from '~/components/AgDataTableNew'
import Loading from '~/components/Loading'
import Estado from '~/models/estados'
import moment from 'moment'

export default {
  props: {
    uuid: { type: String, default: null },
    cliente: { type: String, default: null },
    expediente: { type: String, default: null },
    iteracion: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    HeaderComponent,
    AgDatatable,
    Loading,
  },

  data: () => ({
    items: [],
    db: { items: [] },
    auth: {},
  }),

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },
  },

  computed: {
    revisiones() {
      return this.items && this.items.length > 0
        ? this.items.filter((auditoria) => auditoria.estado === this.APTA)
        : this.items
    },

    headers() {
      let headers = [
        {
          headerName: this.$t('certificados.codigo'),
          field: 'codigo',
          sortable: true,
          enableRowGroup: true,
          filter: true,
          resizable: true,
        },
        {
          headerName: this.$t('certificados.cliente'),
          field: 'expediente.user.nombre',
          colId: 'expediente.user.nombre',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            return item.expediente.user.nombreCompleto ?? ''
          
          },
        },
        {
          headerName: this.$t('certificados.marca'),
          field: 'expediente.subalcance',
          colId: 'expediente.subalcance',
          enableRowGroup: true,
          sortable: true,
          filter: true,
          resizable: true,
          cellRendererFramework: 'SubalcanceRendererColor',
        },
        {
          headerName: this.$t('certificados.desde'),
          field: 'desde',
          colId: 'desde',
          sortable: true,
          filter: 'agDateColumnFilter',
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.desde
            return value ? moment(value).format('DD/MM/YYYY') : ''
          },
        },
        {
          headerName: this.$t('certificados.hasta'),
          field: 'hasta',
          colId: 'hasta',
          sortable: true,
          filter: 'agDateColumnFilter',
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.hasta
            return value ? moment(value).format('DD/MM/YYYY') : ''
          },
        },
        {
          headerName: this.$t('certificados.fechaExpedicion'),
          field: 'fechaExpedicion',
          colId: 'fechaExpedicion',
          sortable: true,
          filter: 'agDateColumnFilter',
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.fechaExpedicion
            return value ? moment(value).format('DD/MM/YYYY') : ''
          },
        },
        {
          headerName: this.$t('certificados.accionDictamen'),
          field: 'dictamen.accion',
          colId: 'dictamen.accion',
          sortable: true,
          filter: 'agDateColumnFilter',
          resizable: true,
          valueGetter: (params) => {
            const item = params.data
            const value = item && item.dictamen.accion
            return value ?? ''
          },
        },
        {
          headerName: this.$t('certificados.estado'),
          field: 'estado',
          colId: 'estado',
          sortable: true,
          enableRowGroup: true,
          filter: true,
          resizable: true,
        },
      ]

      if (this.auth.canEdit) {
        headers = [
          {
            headerName: this.$t('certificados.activo'),
            field: 'activo',
            colId: 'activo',
            sortable: true,
            enableRowGroup: true,
            filter: true,
            resizable: true,
            cellRendererFramework: 'SwitchRenderer',
            cellRendererParams: {
              label: '',
              readonly: false,
              controlClick: function (params) {
                console.warn(params)
                if (params.value) {
                  $nuxt.$store.dispatch('certificados/desactivar', params.item.uuid)
                } else {
                  $nuxt.$store.dispatch('certificados/activar', params.item.uuid)
                }
              },
            },
          },
          {
            headerName: this.$t('certificados.publicado'),
            field: 'publicado',
            colId: 'publicado',
            sortable: true,
            enableRowGroup: true,
            filter: true,
            resizable: true,
            cellRendererFramework: 'SwitchRenderer',
            cellRendererParams: {
              label: '',
              readonly: false,
              controlClick: function (params) {
                console.warn(params)
                if (params.value) {
                  $nuxt.$store.dispatch('certificados/despublicar', params.item.uuid)
                } else {
                  $nuxt.$store.dispatch('certificados/publicar', params.item.uuid)
                }
              },
            },
          },
          ...headers,
        ]
      }

      return headers
    },
  },
  methods: {
    clickRow(data) {
      this.$router.push(`certificados/${data.uuid}`)
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'certificados',
      })
    },

    async init() {
      this.checkRoles()

      const body = {}
      if (this.cliente) body.cliente = this.cliente
      if (this.expediente) body.expediente = this.expediente
      const res = await this.$store.dispatch('certificados/list', body)

      if (res) {
        this.items = res
        this.db.items = res
        this.filteredItems = res
      }

      this.filterIteracion()
    },

    //+-------------------------------------------------
    // filterIteracion()
    // Select only items where iteracion match the prop
    // -----
    // Created on Mon Nov 29 2021
    //+-------------------------------------------------
    filterIteracion() {
      if (!this.iteracion?.uuid) {
        this.items = [...this.db.items]
        return
      }

      let items = []
      items = this.db.items.filter((item) => {
        return item.iteracion_uuid == this.iteracion?.uuid
      })

      this.items = items
    },
  },

  async beforeMount() {
    // let canPublicar = await this.$store.dispatch('user/can', {
    //   module: 'certificados',
    //   action: 'canPublicar',
    // })
    // if (canPublicar) {
    //   this.headers.push({
    //     headerName: 'Acciones',
    //     sortable: false,
    //     width: 300,
    //     cellRendererFramework: 'CertificadoRenderer',
    //     cellRendererParams: {
    //       reload: this.init.bind(this),
    //     },
    //   })
    // }
  },

  mounted() {
    if (!this.cliente && !this.expediente) this.$store.commit('menu/setPageTitle', 'Certificados')
  },
  async created() {
    await this.init()
  },
}
</script>
