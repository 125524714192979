<template>
  <div>
    <!-- MENÚ LATERAL CON LOS DOCUMENTOS -->
    <v-navigation-drawer
      v-model="gestorDocumental"
      app
      right
      temporary
      width="600"
      class="elevation-6 fondo-app"
      style="background-color: rgb(246, 248, 249) !important"
      @input="controlOpen()">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title class="flex" style="width: 100%"> Documentos </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn text @click="dialogNewDocument = true">
          <v-icon left>mdi-upload</v-icon>
          Adjuntar documento
        </v-btn> -->
      </v-toolbar>

      <div
        style="
          background-color: whitesmoke;
          padding: 20px;
          margin: 20px;
          border: 2px dashed;
          border-radius: 5px;
          cursor: pointer;
          text-align: center;
        "
        @click.stop="openUploader">
        Añadir documentos
        <v-icon left class="px-2">mdi-cloud-upload-outline</v-icon>
      </div>

      <div v-for="(items, modulo) in documentos" :key="modulo" class="ml-3 mr-3">
        <v-list two-line subheader dense>
          <v-divider class="ml-5 mr-5 mb-3"></v-divider>

          <v-subheader class="title-vuely">{{ modulo.replace('_', ' ') }}</v-subheader>

          <v-list-item v-for="(documento, i) in items" :key="i" @click="download(documento)">
            <v-list-item-avatar>
              <v-icon class="blue white--text">mdi-text-box-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ documento.nombreArchivo }}</v-list-item-title>
              <v-list-item-subtitle
                ><i
                  >{{ documento.createdAt | formatDate }} por
                  <b>{{ documento.createdBy.nombreCompleto }}</b></i
                ></v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon class="download">
                <v-icon small style="font-size: 20px"> mdi-download</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  small
                  @click.stop="confirmDeleteDocument(documento)"
                  style="font-size: 20px">
                  mdi-delete-outline</v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>

    <!-- DIALOG PARA SUBIR DOCUMENTOS -->
    <!-- <v-dialog eager v-model="dialogNewDocument" max-width="40%" hide-overlay persistent>
      <v-card>
        <v-card-title>{{text}}</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-model="valid" style="height: 100%">

            <v-row class="pl-10 pr-10" v-if="documento">
              <v-col cols="12">
                <v-text-field label="Título" required :rules="rules" v-model="documento.titulo"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="documento && documento.tipo">
                <v-file-input required :rules="rules" label="Documento" :input="documento.file" v-model="documento.file"></v-file-input>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pr-3">
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            class="mr-3"
            text
            @click="closeDialogNewDocument()">
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="upload()">
            <v-icon left>mdi-upload</v-icon>
            Adjuntar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- CONFIRMACIÓN DE BORRADO DE DOCUMENTOS -->
    <confirmation
      v-model="dialogConfirmDeleteDocument"
      text="¿Eliminar el documento seleccionado?"
      colorButton="error"
      @confirm="deleteDocument()" />
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\GestorDocumental.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Mon Jul 17 2023
 **/

import Confirmation from '~/components/Confirmation'

export default {
  name: 'GestorDocumental',
  props: {
    value: Boolean,
    text: {
      type: String,
      default: 'Datos del documento',
    },
  },
  components: {
    Confirmation,
  },
  data() {
    return {
      dialogNewDocument: false,
      gestorDocumental: false,
      valid: false,
      rules: [(v) => !!v || 'El campo es obligatorio'],
      tipos_fichero: [
        { name: 'PDF', accept: 'application/pdf' },
        { name: 'Excel', accept: '.xlsx, .xls' },
        { name: '.zip', accept: '.zip,application/zip' },
      ],
      documento: {
        uuid: '',
        titulo: null,
        tipo: { name: 'PDF', accept: 'application/pdf' },
        file: null,
      },
      documentoSeleccionado: null,
      dialogConfirmDeleteDocument: false,
      cliente: null,
    }
  },
  watch: {
    'value': function (val) {
      this.gestorDocumental = val
    },
    // 'dialogNewDocument'(val) {
    //   if (!val) this.closeDialogNewDocument()
    // },
    '$route'(to, from) {
      this.$store.commit('gestor_documental/RESET_DOCUMENTOS_MODULO_EXTERNO')
      this.getDocumentos(to)
    },
    '$store.state.gestor_documental.adjuntarEvidencia': function () {
      if (this.$store.state.gestor_documental.adjuntarEvidencia) {
        this.$store.commit('gestor_documental/ADJUNTAR_EVIDENCIA', false)
        this.dialogNewDocument = true
      }
    },
  },
  computed: {
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },
    documentos() {
      const documentos = this.$store.getters['gestor_documental/documentos']
      return this.groupBy(documentos, 'modulo', 'submodulo')
    },
  },

  methods: {
    //+-------------------------------------------------
    // openUploader()
    // Opens the new document dialog to upload files
    // -----
    // Created on Tue Aug 16 2022
    //+-------------------------------------------------
    openUploader() {
      let path = this.$route.path.split('/')
      let modulo = path[1]
      let moduloRef = path[2]

      /* Código copiado de create en gestor_documental.js */
      if (moduloRef === 'tabs') {
        const modulo_uuid = this.$route.fullPath.split('uuid=')
        moduloRef = modulo_uuid[1]
      }

      let submodulo = ''
      let submoduloRef = ''
      if (path[3]) submodulo = path[3]
      if (path[4]) submoduloRef = path[4]

      let config = {
        modulo: modulo,
        moduloRef: moduloRef,
        submodulo: submodulo,
        submoduloRef: submoduloRef,
      }
      if (this.$route.query.type === 'cliente') { config.cliente = this.$route.params.uuid }
      else if (this.cliente) config.cliente = this.cliente

      if (config.cliente) config.cliente_name = this.$t('gestorDocumental.currentClient')
      /*

      if (this.contact.cliente?.uuid) config.cliente = this.contact.cliente.uuid
      if (this.contact['@type'] == 'User') config.cliente = this.contact.uuid
      else config.contacto = this.contact.uuid
      */
      // config.cliente = this.auditoria.cliente.uuid
      // config.cliente_name = this.auditoria.cliente.nombre

      this.$nuxt.$emit('documents:open', config)
    },
    // closeDialogNewDocument() {
    //   this.dialogNewDocument = false
    //   this.documento = {
    //     uuid: '',
    //     titulo: null,
    //     tipo: { name: 'PDF', accept: 'application/pdf' },
    //     file: null,
    //   }
    //   this.$refs.form.reset()
    // },
    controlOpen() {
      if (!this.gestorDocumental) {
        this.$emit('input', null)
        this.$emit('close')
      }
    },
    // async upload() {
    //   if (this.$refs.form.validate()) {
    //     this.dialogNewDocument = false
    //     const body = {
    //       fullPath: this.$route.fullPath,
    //       path: this.$route.path,
    //       documento: this.documento,
    //     }
    //     const res = this.offlineMode
    //       ? await this.$store.dispatch('offlineMode/createDocument', body)
    //       : await this.$store.dispatch('gestor_documental/create', body)
    //     this.$refs.form.reset()
    //     this.getDocumentos(this.$route)
    //   }
    // },
    async getDocumentos(route) {
      const documentos = this.offlineMode
        ? null
        : await this.$store.dispatch(`gestor_documental/list`, route)
      return this.groupBy(documentos, 'modulo', 'submodulo')
    },

    async download(doc) {
      await this.$store.dispatch('documentos/download', doc)
    },
    confirmDeleteDocument(doc) {
      this.documentoSeleccionado = doc
      this.dialogConfirmDeleteDocument = true
    },
    async deleteDocument() {
      this.dialogConfirmDeleteDocument = false
      await this.$store.dispatch('gestor_documental/delete', this.documentoSeleccionado.uuid)
      this.documentoSeleccionado = null
      this.getDocumentos(this.$route)
      await this.$store.commit('gestor_documental/ACTUALIZAR_EVIDENCIAS', true)
    },

    groupBy(documentos, modulo, submodulo) {
      return documentos.reduce(function (result, current) {
        if (current[submodulo] === 'undefined') return result
        else if (current[submodulo] === '') current[submodulo] = current[modulo]
        result[current[submodulo]] = result[current[submodulo]] || []
        result[current[submodulo]].push(current)
        console.log('result', result)
        return result
      }, {})
    },
  },

  mounted() {
    this.getDocumentos(this.$route)

    this.$nuxt.$on('documents:uploaded', (body) => {
      this.getDocumentos(this.$route)
    })
    this.$nuxt.$on('documents:setCliente', (cliente) => {
      this.cliente = cliente
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('documents:uploaded')
  },
}
</script>

<style scoped>
.download {
  position: absolute !important;
  right: 60px !important;
}
</style>
