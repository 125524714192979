<template>
    <div>

        <!-- LISTADO -->
        <v-row v-show="!ui.showOffline">
            <v-col cols="12">
                <ag-datatable :key="ui.refresh" title="Listado" :headers="headers" :items="items" emitClickRow
                    @click-row="clickRow" @gridApi="gridApi = $event" :auth="auth">
                </ag-datatable>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Estados from '~/models/estados'
import AgDatatable from '~/components/Datatable'

export default {
    props: {
        iteracion: {
            type: Object,
            default: () => ({}),
        },
        cliente: { type: String, default: null },
        expediente: { type: String, default: null },
    },
    components: {
        AgDatatable,
    },

    data: () => ({
        headersPinned: true,
        gridApi: null,

        items: [],
        db: { items: [] },
        tipos_auditorias: {},

        auth: {},

        ui: {
            retry: 0,
            refresh: 0,
            showOffline: false,
        },
    }),

    watch: {
        iteracion: {
            handler(newVal, oldVal) {
                if (newVal.uuid !== oldVal.uuid) {
                    this.refreshRows(true)
                }
            },
            deep: true,
        },

        isOffline: function (weAreOffline) {
            this.filterOffline(weAreOffline)
        },
    },

    computed: {
        isOffline: function () {
            return this.$nuxt.isOffline
        },

        isCliente() {
            return this.$store.getters.isCliente
        },

        headers() {
            let headers = [
                {
                    text: '',
                    value: 'id',
                },
                {
                    text: this.$t('widgetDictamenes.status'),
                    value: 'estado',
                },
                {
                    text: this.$t('widgetDictamenes.subscope'),
                    value: 'subalcance',
                },
                {
                    text: this.$t('widgetDictamenes.facilities'),
                    value: 'instalaciones',
                },
                {
                    text: this.$t('widgetDictamenes.reviewer'),
                    value: 'revisor',
                },
                {
                    text: this.$t('widgetDictamenes.reviewDate'),
                    value: 'fecha_revision',
                },
                {
                    text: this.$t('widgetDictamenes.proposition'),
                    value: 'proposicion',
                },
                {
                    text: this.$t('widgetDictamenes.qualityManager'),
                    value: 'responsable_calidad',
                },
                {
                    text: this.$t('widgetDictamenes.rulingDate'),
                    value: 'fecha_dictamen',
                },
                {
                    text: '',
                    value: 'actions',
                }
            ];


            return headers
        },

        //+-------------------------------------------------
        // allowedToOffline()
        // return items where estado == 'abierta' and checklists.length > 0
        // -----
        // Created on Wed Jan 11 2023
        //+-------------------------------------------------
        allowedToOffline() {
            return this.items.filter(
                (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
            )
        },
    },
    methods: {
        async init() {
            const body = {}
            if (this.iteracion) body.iteracion = this.iteracion.uuid
            // Cliente
            if (this.cliente) body.cliente = this.cliente
            else if (this.isCliente) {
                const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
                if (clientes) body.cliente = clientes[0].uuid
            }

            // Auditorias
            const res = await this.$store.dispatch('dictamenes/listByIteracion', this.iteracion.uuid)
            if (res) {
                console.log('res', res)
                this.db.items = res
                this.items = this.transformItems(res)
                console.log('this.items', this.items)
            }
        },

        transformItems(items) {
            return items.map(item => ({
                id: item.id,
                subalcance: item.revision.auditoria.expediente.subalcance,
                estado: item.decision ? item.decision : 'Sin decision',
                instalaciones: [item.revision.auditoria.emplazamientos ? item.revision.auditoria.emplazamientos.map(e => e.nombre).join(', ') : ''],
                revisor: item.auditor1.nombreCompleto,
                fecha_revision: this.$moment(item.revision.updatedAt).format('DD/MM/YYYY'),
                proposicion: item.revision.proposicion,
                responsable_calidad: item.auditor1.nombreCompleto,
                fecha_dictamen: this.$moment(item.createdAt).format('DD/MM/YYYY'),
                uuid: item.uuid,
                actions: item.actions,
            }))
        },

        clickRow(data) {
            if (data) this.$router.push(`/dictamenes/${data.uuid}`)
        },

        async refreshRows(init = false) {
            if (init) await this.init()
            this.gridApi.refreshCells({ force: true })
        },



        async checkRoles() {
            this.auth = await this.$store.dispatch('user/can', {
                module: 'dictamenes',
                return: 'all',
            })
        },
    },

    mounted() {

        this.checkRoles()
    },

    async created() {
        await this.init()
    },
}
</script>
