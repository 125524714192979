<template>
    <div>
        <!-- HEADER -->
        <v-row class="align-center" v-if="!cliente && !expediente">
            <v-col cols="6">
                <h1>{{ $t('widgetAuditoria.auditorias') }}</h1>
            </v-col>

            <v-col cols="6" style="text-align: right">
                <v-btn v-if="ui.showOffline" depressed outlined @click="dropCache" color="purple" class="mr-1">
                    <v-icon small class="pr-2">mdi-water-remove</v-icon>
                    {{ $t('widgetAuditoria.vaciar') }}
                </v-btn>

                <v-btn depressed outlined @click="ui.showOffline = !ui.showOffline; refreshRows(true)"
                    :color="(ui.showOffline) ? 'success' : ''">
                    <v-icon small class="pr-2">mdi-lightning-bolt-circle</v-icon>
                    <template v-if="ui.showOffline">
                        {{ $t('widgetAuditoria.estoyPreparado') }}
                    </template>
                    <template v-else>
                        {{ $t('widgetAuditoria.prepararseOffline') }}
                    </template>
                </v-btn>
            </v-col>

            <v-col cols="12" v-if="isOffline">
                <v-alert dark icon="mdi-wifi-strength-alert-outline">
                    {{ $t('widgetAuditoria.alertaOffline1') }}
                </v-alert>

                <v-alert dark icon="mdi-alert-box-outline">
                    {{ $t('widgetAuditoria.alertaOffline2') }}
                </v-alert>
            </v-col>
        </v-row>

        <v-row v-show="ui.showOffline" class="my-5">
            <v-col>
                <offlineSelector ref="offline" nope-component="auditoria" :enabled="ui.showOffline"
                    :onInit="['auditorias/list']" :onInitBody="[{}]" :cols="[
                        { text: $t('widgetAuditoria.estado'), value: 'estado' },
                        { text: $t('widgetAuditoria.desde'), value: 'desde_f' },
                        { text: $t('widgetAuditoria.hasta'), value: 'hasta_f' },
                        { text: $t('widgetAuditoria.alcance'), value: 'nombre_alcance' },
                        { text: $t('widgetAuditoria.cliente'), value: 'nombreApellidos' },
                        { text: $t('widgetAuditoria.emplazamiento'), value: 'emplazamiento' },
                    ]" :items="allowedToOffline" :howToLoad="[
                        {
                            'action': 'clearCache',
                            'key': 'auditorias/uuid',
                        },
                        {
                            'action': 'lockAuditoria'
                        },
                        {
                            'component': 'auditoria',
                            'uuid': 'uuid'
                        },
                        {
                            'request': 'checklists/list',
                        },
                        {
                            'component': 'checklist',
                            'uuid': 'uuid',
                            'loop': true,
                        },
                        {
                            'request': 'tomas_muestra/list',
                            'uuid': 'itemUUID',
                        },
                        {
                            'component': 'muestras',
                            'uuid': 'uuid',
                            'loop': true,
                        },
                        {
                            'action': 'downloadClientFiles',
                            'uuid': 'cliente.uuid',
                        }]" />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-btn color="primary" style="float: right;" @click="createAuditoria">
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('widgetAuditoria.crearAuditoria') }}
                </v-btn>
            </v-col>
        </v-row>

        <!-- LISTADO -->
        <v-row v-show="!ui.showOffline">
            <v-col cols="12">
                <ag-datatable :key="ui.refresh" :title="$t('widgetAuditoria.listado')" :headers="headers" :items="items"
                    emitClickRow @click-row="clickRow" @gridApi="gridApi = $event" :auth="auth">
                </ag-datatable>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import Estados from '~/models/estados'
import AgDatatable from '~/components/Datatable'
import HeaderComponent from '~/components/HeaderComponent'
import offlineSelector from '~/components/offline/selectorTable'

export default {
    props: {
        iteracion: {
            type: Object,
            default: () => ({}),
        },
        cliente: { type: String, default: null },
        expediente: { type: String, default: null },
        subalcance: { type: Object, default: {} },
        cliente: { type: Object, default: null },
    },
    components: {
        AgDatatable,
        HeaderComponent,
        offlineSelector,
    },

    data: () => ({
        headersPinned: true,
        gridApi: null,

        items: [],
        db: { items: [] },
        tipos_auditorias: {},

        auth: {},

        ui: {
            retry: 0,
            refresh: 0,
            showOffline: false,
        },
    }),

    watch: {
        iteracion: {
            handler(newVal, oldVal) {
                if (newVal.uuid !== oldVal.uuid) {
                    this.filterIteracion()
                    this.refreshRows(true)
                }
            },
            deep: true,
        },

        isOffline: function (weAreOffline) {
            this.filterOffline(weAreOffline)
        },
    },

    computed: {
        isOffline: function () {
            return this.$nuxt.isOffline
        },

        isCliente() {
            return this.$store.getters.isCliente
        },


        headers() {
            let headers = [
                {
                    text: '',
                    value: 'id',
                },
                {
                    text: this.$t('widgetAuditoria.estado'),
                    value: 'estado',
                },
                {
                    text: this.$t('widgetAuditoria.subalcance'),
                    value: 'subalcance',
                },
                {
                    text: this.$t('widgetAuditoria.auditores'),
                    value: 'auditores',
                },
                {
                    text: this.$t('widgetAuditoria.tipoAuditoria'),
                    value: 'tipoAuditoria',
                },
                {
                    text: this.$t('widgetAuditoria.nPacs'),
                    value: 'n_pacs',
                },
                {
                    text: this.$t('widgetAuditoria.fecha'),
                    value: 'fecha',
                },
                {
                    text: '',
                    value: 'actions',
                }
            ];


            return headers
        },

        //+-------------------------------------------------
        // allowedToOffline()
        // return items where estado == 'abierta' and checklists.length > 0
        // -----
        // Created on Wed Jan 11 2023
        //+-------------------------------------------------
        allowedToOffline() {
            return this.items.filter(
                (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
            )
        },
    },
    methods: {
        createAuditoria() {
            this.$router.push('/auditorias/nueva?iteracion=' + this.iteracion.uuid + '&expediente=' + this.expediente + '&cliente=' + this.cliente.uuid + '&subalcance=' + this.subalcance.uuid)
        },
        async init() {
            const body = {}
            if (this.iteracion) body.iteracion = this.iteracion.uuid
            // Cliente
            if (this.cliente) body.cliente = this.cliente.uuid
            else if (this.isCliente) {
                const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
                if (clientes) body.cliente = clientes[0].uuid
            }

            // Auditorias
            const res = await this.$store.dispatch('auditorias/listByIteracion', body)
            if (res) {
                this.items = res
                this.db.items = res
            }

            await this.formatRows()
            if (this.$nuxt.isOffline) {
                this.filterOffline(true)
                this.retryOffline()
            }
        },

        async getTiposVisita() {
            const body = {
                empresa: this.$auth.user.empresa.uuid,
                codigos: ['tipos_auditorias'],
            }
            const res = await this.$store.dispatch('estaticos/list', body)
            if (res) {
                const tipos_auditorias = {}
                for (let i = 0; i < res.length; i++) {
                    const estatico = res[i]
                    const alcance_uuid = estatico.alcances?.uuid
                    const items = estatico.valor

                    if (!tipos_auditorias[alcance_uuid]) tipos_auditorias[alcance_uuid] = {}

                    for (let k = 0; k < items.length; k++) {
                        const item = items[k]
                        tipos_auditorias[alcance_uuid][item.valor] = item.texto
                    }
                }
                this.tipos_auditorias = tipos_auditorias
            }
        },

        clickRow(data) {
            if (data) this.$router.push(`/auditorias/${data.uuid}?tab=0`)
        },

        //+-------------------------------------------------
        // refreshRows()
        // Reload rows and cell data
        // -----
        // Created on Mon Mar 07 2022
        //+-------------------------------------------------
        async refreshRows(init = false) {
            if (init) await this.init()
            this.formatRows()
            this.gridApi.refreshCells({ force: true })
        },

        //+-------------------------------------------------
        // formatRows()
        // Prepare data fields for grid
        // Has offline functionality
        // -----
        // Created on Thu Nov 04 2021
        // Updated on Tue Nov 09 2021
        //+-------------------------------------------------
        async formatRows() {
            for (const item of this.items) {
                item.nombreApellidos = item.nombre_cliente

                item.desde_f = this.$moment(item.desde).format('DD/MM/YYYY')
                item.hasta_f = this.$moment(item.hasta).format('DD/MM/YYYY')

                let cache = await this.$store.dispatch('offline/getCache', 'auditorias/' + item.uuid)
                let toRemove = false

                if (cache?.data && cache?.data?.data?.updated_at) {
                    let diff = this.$moment(cache?.data && cache?.data?.data?.updated_at).diff(
                        item.updated_at,
                        'minutes'
                    )

                    // Delete if cache is older than 2 days
                    // Disabled because there is no item.updated_at anymore 💁‍♀️
                    // if (diff > 60 * 24 * 2) toRemove = true

                    // Delete if item is no longer locked
                    // if (!item.bloqueada && !item.bloqueada_at) toRemove = true

                    // if (diff * -1 > 30) item.cached = 'conflicted'

                    console.warn(
                        // diff,
                        // cache?.data?.data.updated_at,
                        // item.updated_at,
                        cache?.data?.data.uuid,
                        item.uuid
                    )

                    item.cached = cache ? true : false
                    // item.cached = cache && !toRemove ? true : false
                }

                if (!this.isOffline && toRemove) {
                    console.warn(
                        `Se ha eliminado el offline de ${item.uuid} porque la auditoria ya no está bloqueada`
                    )
                    this.$store.commit('offline/removeItem', 'auditorias/' + item.uuid)
                }
            }

            this.ui.refresh++
        },

        //+-------------------------------------------------
        // filterIteracion()
        // Select only items where iteracion match the prop
        // -----
        // Created on Mon Nov 29 2021
        //+-------------------------------------------------
        filterIteracion() {
            if (!this.iteracion?.uuid) {
                this.items = [...this.db.items]
                return
            }

            let items = []
            items = this.db.items.filter((item) => {
                return item.iteracion_uuid == this.iteracion?.uuid
            })

            this.items = items
        },

        //+-------------------------------------------------
        // filterOffline()
        // Set items only with preloaded items
        // Used while offline to see only preloaded items
        // -----
        // Created on Wed Mar 02 2022
        //+-------------------------------------------------
        filterOffline(offline) {
            if (!offline) {
                this.items = [...this.db.items]
                this.formatRows()
                return
            }

            let items = []
            items = this.db.items.filter((item) => {
                return item.cached
            })

            this.items = items
        },

        retryOffline() {
            if (this.ui.retry > 5) return
            if (this.items.length > 0) return

            window.setTimeout(() => {
                this.refreshRows(true)
                this.ui.retry++
            }, 1000)
        },

        //+-------------------------------------------------
        // dropCache()
        // Created on Wed Jan 11 2023
        //+-------------------------------------------------
        dropCache() {
            this.$store.commit('offline/resetCache')
            this.$store.commit('offline/clearLocalData')
            location.reload()
        },

        async checkRoles() {
            this.auth = await this.$store.dispatch('user/can', {
                module: 'auditorias',
                return: 'all',
            })
        },
    },

    mounted() {
        this.$store.commit('menu/setPageTitle', 'Auditorías')
        this.checkRoles()

        // this.$nuxt.$on('offline:loaded', (payload) => {
        //   if (payload.item == 'auditoria') {
        //     this.refreshRows()
        //     // this.lockAuditoria(payload)
        //   }
        // })

        // this.$nuxt.$on('offline:end', (payload) => {
        //   window.setTimeout(() => {
        //     this.refreshRows()
        //   }, 300)
        // })
    },

    async created() {
        this.$store.commit('menu/update', 'auditorias')
        await this.getTiposVisita()
        await this.init()
    },

    beforeDestroy() {
        this.$nuxt.$off('offline:loaded')
        this.$nuxt.$off('offline:end')
    },
}
</script>
