export const state = () => ({
  list: [],
  item: {},
  itemOwner: {},
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  GET_ITEM(state) {
    return state.item
  },
  SET_ITEM(state, item) {
    state.item = item
  },
  GET_ITEM_OWNER(state) {
    return state.itemOwner
  },
  SET_ITEM_OWNER(state, item) {
    state.itemOwner = item
  },
  SET_EMPRESAS(state, empresas) {
    state.list = empresas
  },
  SET_TOTAL_ITEMS(state, total) {
    state.totalItems = total
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading
  },
}

//dispatch actions
export const actions = {
  async list({ commit }) {
    const res = await this.$axios.get(
      'empresas' + '?owner.uuid=' + this.$auth.user.empresaOwner.uuid
    )
    if (res && res.data['hydra:member']) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },
  async listActive({ commit }, params = {}) {
    commit('SET_LOADING', true)
    const queryParams = []

    // Agregar parámetros de búsqueda si existen
    if (params.search) {
      queryParams.push(`nombre=${encodeURIComponent(params.search)}`)
    }
    // Agregar parámetros de paginación
    if (params.page) {
      queryParams.push(`page=${params.page}`)
    }
    if (params.itemsPerPage) {
      queryParams.push(`itemsPerPage=${params.itemsPerPage}`)
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    try {
      const res = await this.$axios.get(`empresas${queryString}`)

      if (res?.data && res.data['hydra:member']) {
        const items = res.data['hydra:member']
        const totalItems = res.data['hydra:totalItems']

        // Actualizar el estado del store si es necesario
        commit('SET_EMPRESAS', items)
        commit('SET_TOTAL_ITEMS', totalItems)

        commit('SET_LOADING', false)

        // Devolver los datos al componente
        return {
          items,
          totalItems,
        }
      } else {
        commit('SET_EMPRESAS', [])
        commit('SET_TOTAL_ITEMS', 0)
        commit('SET_LOADING', false)
        return {
          items: [],
          totalItems: 0,
        }
      }
    } catch (error) {
      commit('SET_EMPRESAS', [])
      commit('SET_TOTAL_ITEMS', 0)
      commit('SET_LOADING', false)
      console.error('Error al obtener empresas:', error)
      return {
        items: [],
        totalItems: 0,
      }
    }
  },
  async get({ commit }, params) {
    const res = await this.$axios.get('empresas/' + params)
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },
  async getOwner({ commit }, params) {
    const res = await this.$axios.get('empresas_owners/' + params)
    if (res && res.data) {
      commit('SET_ITEM_OWNER', res.data)
      return res.data
    }
  },
  async updateOwner({ commit }, params) {
    try {
      const res = await this.$axios.patch('empresas_owners/' + params.uuid, params, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      if (res && res.data) {
        commit('SET_ITEM_OWNER', res.data)
        commit(
          'notification/show',
          {
            color: 'success',
            text: 'Datos actualizados correctamente',
            timeout: 3000,
          },
          { root: true }
        )
        return res.data
      }
    } catch (error) {
      commit(
        'notification/show',
        {
          color: 'error',
          text: 'Error al actualizar los datos',
          timeout: 3000,
        },
        { root: true }
      )
      console.error('Error al actualizar los datos:', error)
    }
  },
  async create({ commit }, params) {
    const res = await this.$axios.post('empresas', params)
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async update({ commit }, params) {
    const res = await this.$axios.patch('empresas/' + params.uuid, params, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async delete({ commit }, params) {
    const res = await this.$axios.delete('empresas/' + params.uuid)
    if (res) {
      commit('SET_ITEM', {})
      return res
    }
  },

  async updateLogo({ commit }, params) {
    const res = await this.$axios.post(
      'empresas/' + params.uuid + '/update-logo',
      params.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async getLogo({ commit }, params) {
    const res = await this.$axios.get('empresas/' + params + '/logo', { responseType: 'blob' })
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },

  async addPaises({ commit }, params) {
    let body = {
      paises: params.paises,
    }
    const res = await this.$axios.post('empresas/' + params.uuid + '/add-paises', body)
    if (res && res.data) {
      commit('SET_ITEM', res.data)
      return res.data
    }
  },
}
