/**
 * @project: certiapp-nuxt
 * @file:    \store\marcas.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 3rd November 2022
 * Last Modified: Fri Apr 28 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({
  items: [],
  item: {},
})

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },

  SET_ITEM(state, item) {
    state.item = item
  },

  ADD_ITEM(state, item) {
    state.items.push(item)
  },

  UPDATE_ITEM(state, item) {
    state.items = state.items.map((i) => (i.uuid === item.uuid ? item : i))
  },

  DELETE_ITEM(state, item) {
    state.items = state.items.filter((i) => i.uuid !== item.uuid)
  },
}

export const actions = {
  async list({ rootState, commit }, params) {
    rootState.loading = true

    params = params || {}
    const res = await this.$axios.get(`marcas`)

    if (res) {
      commit('SET_ITEMS', res.data)
      return res.data
    }
    rootState.loading = false
  },

  async get({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`marcas/${uuid}`)

    if (res) {
      commit('SET_ITEM', res.data)
      return res.data
    }
    rootState.loading = false
  },

  async create({ rootState, commit }, params) {
    rootState.loading = true

    const res = await this.$axios.post(`marcas`, params)

    if (res) {
      commit('ADD_ITEM', res.data)
      return res.data
    }

    rootState.loading = false
  },

  async update({ rootState,commit }, params) {
    rootState.loading = true

    let payload = { ...params }
    const res = await this.$axios.patch(`marcas/${params.uuid}`, payload)

    if (res) {
      commit('UPDATE_ITEM', res.data)
      return res.data
    }

    rootState.loading = false
  },

  async delete({ rootState,commit }, item) {
    rootState.loading = true
    await this.$axios.delete(`marcas/${item.uuid}`)
    commit('DELETE_ITEM', item)
    rootState.loading = false
  },
}
