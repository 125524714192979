/**
 * @project: certiapp-nuxt
 * @file:    \store\index.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Thu Jan 26 2023
 **/

import role from '~/models/role'

export const strict = false

export const state = () => ({
  is_new: false,
  locale: 'es',
  loading: false,
  onesignal_subscribed: false,
  tinyApiKey: process.env.TINY_APIKEY,
})

export const mutations = {
  LOADING(state, value) {
    state.loading = value
  },
  SET_NEW(state, value) {
    state.is_new = value
  },
  SET_ONESIGNAL_SUBSCRIBED(state, value) {
    state.onesignal_subscribed = value
  },
  SET_LOCALE(state, locale) {
    state.locale = locale
    this.$i18n.setLocale(locale)
  },
}

export const getters = {
  loading(state) {
    return state.loading
  },
  isNew(state) {
    return state.is_new
  },
  isOnesignalSubscribed(state) {
    return state.onesignal_subscribed
  },
  isCliente() {
    return (
      $nuxt.$auth.user &&
      $nuxt.$auth.user.roles &&
      $nuxt.$auth.user.roles.includes(role.ROLE_CLIENT) &&
      !$nuxt.$auth.user.roles.some((element) => element.includes('ROLE_AUDITOR')) &&
      !$nuxt.$auth.user.roles.some((element) => element.includes('ROLE_ADMIN'))
    )
  },
  isAdmin() {
    return (
      $nuxt.$auth.user &&
      $nuxt.$auth.user.roles &&
      $nuxt.$auth.user.roles.includes(role.ROLE_ADMIN)
    )
  },
  isAdminGroup() {
    return (
      $nuxt.$auth.user &&
      $nuxt.$auth.user.roles &&
      $nuxt.$auth.user.roles.includes(role.ROLE_ADMIN_GROUP)
    )
  },
  isKaam() {
    return (
      $nuxt.$auth.user &&
      $nuxt.$auth.user.username &&
      $nuxt.$auth.user.username === 'kaam'
    )
  },
  isTecnico() {
    return (
      $nuxt.$auth.user &&
      $nuxt.$auth.user.roles &&
      $nuxt.$auth.user.roles.includes(role.ROLE_TECNICO) &&
      !$nuxt.$auth.user.roles.some((element) => element.includes('ROLE_AUDITOR')) &&
      !$nuxt.$auth.user.roles.some((element) => element.includes('ROLE_ADMIN'))
    )
  },
  isRevisor() {
    return (
      ($nuxt.$auth.user &&
        $nuxt.$auth.user.roles &&
        ($nuxt.$auth.user.roles.includes(role.ROLE_AUDITOR_REVISOR) ||
          $nuxt.$auth.user.roles.includes(role.ROLE_AUDITOR_ADMIN))) ||
      $nuxt.$auth.user.roles.includes(role.ROLE_ADMIN) ||
      $nuxt.$auth.user.roles.includes(role.ROLE_ADMIN_GROUP)
    )
  },
}

export const modules = {
  /**
   * Store para las notificaciones del menú
   */
  notification: {
    namespaced: true,
    state: () => ({
      display: false,
      timeout: 4000,
      text: '',
      color: 'success',
    }),
    mutations: {
      show(state, notification) {
        state.display = true
        state.text = notification.text
        state.color = notification.color
        state.timeout = notification.timeout
      },
      close(state) {
        state.display = false
      },
    },
  },

  /**
   * Store para el menú de la izquierda
   */
  menu: {
    namespaced: true,
    state: () => ({
      pageTitle: '',
      openMenu: true,
      menuSeleccionado: -1,
      menuTheme: 'oscuro',
      menuThemes: ['oscuro', 'azul-gradiente', 'verde-gradiente', 'verde-oscuro-gradiente'],
      menuEmpresa: [],
    }),
    mutations: {
      setPageTitle(state, title) {
        state.pageTitle = title
      },
      drawerMenu(state, open) {
        state.openMenu = open
      },
      update(state, menu) {
        state.menuSeleccionado = menu
      },
      updateTheme(state, theme) {
        state.menuTheme = theme
      },
      SET_MENU_EMPRESA(state, val) {
        state.menuEmpresa = val
      },
    },
    actions: {
      // async get({ rootState, commit, dispatch }, cliente) {
      //   const menu = cliente ? 'cliente' : 'menu'
      //   await dispatch(menu)
      // },
      // async menu({ rootState, commit }) {
      //   rootState.loading = true
      //   const res = await this.$axios.get(`menu`)
      //   rootState.loading = false
      //   if (res) {
      //     commit('SET_MENU_EMPRESA', res.data)
      //     return res.data
      //   }
      // },
      // async cliente({ rootState, commit }, cliente) {
      //   rootState.loading = true
      //   const body = {}
      //   if (cliente) body.cliente = cliente
      //   const res = await this.$axios.put(`menu/cliente`, body)
      //   rootState.loading = false
      //   if (res) {
      //     commit('SET_MENU_EMPRESA', res.data)
      //     return res.data
      //   }
      // },
    },
  },
}
