<template>
  <div>
    <!-- LISTADO -->
    <v-row v-show="!ui.showOffline">
      <v-col cols="12">
        <ag-datatable
          :key="ui.refresh"
          :headers="headers"
          :items="items"
          emitClickRow
          @click-row="clickRow"
          @gridApi="gridApi = $event"
          :auth="auth">
        </ag-datatable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Estados from '~/models/estados'
import AgDatatable from '~/components/Datatable'

export default {
  props: {
    iteracion: {
      type: Object,
      default: () => ({}),
    },
    cliente: { type: String, default: null },
    expediente: { type: String, default: null },
  },
  components: {
    AgDatatable,
  },

  data: () => ({
    headersPinned: true,
    gridApi: null,

    items: [],
    db: { items: [] },
    tipos_auditorias: {},

    auth: {},

    ui: {
      retry: 0,
      refresh: 0,
      showOffline: false,
    },
  }),

  watch: {
    iteracion: {
      handler(newVal, oldVal) {
        if (newVal.uuid !== oldVal.uuid) {
          this.filterIteracion()
        }
      },
      deep: true,
    },

    isOffline: function (weAreOffline) {
      this.filterOffline(weAreOffline)
    },
  },

  computed: {
    isOffline: function () {
      return this.$nuxt.isOffline
    },

    isCliente() {
      return this.$store.getters.isCliente
    },

    headers() {
      let headers = [
        {
          text: '',
          value: 'id',
        },
        {
          text: 'Marca',
          value: 'subalcance',
        },
        {
          text: 'Instalaciones',
          value: 'instalaciones',
        },
        {
          text: 'Tipo de Auditoria',
          value: 'visita',
        },
        {
          text: 'Fecha',
          value: 'fecha',
        },
      ]

      return headers
    },

    //+-------------------------------------------------
    // allowedToOffline()
    // return items where estado == 'abierta' and checklists.length > 0
    // -----
    // Created on Wed Jan 11 2023
    //+-------------------------------------------------
    allowedToOffline() {
      return this.items.filter(
        (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
      )
    },
  },
  methods: {
    async init() {
      const body = {}
      if (this.iteracion) body.iteracion = this.iteracion.uuid
      // Cliente
      if (this.cliente) body.cliente = this.cliente
      else if (this.isCliente) {
        const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
        if (clientes) body.cliente = clientes[0].uuid
      }

      // Auditorias
      const res = await this.$store.dispatch('auditorias/list', body)
      if (res) {

        let pacs = []
        res.forEach((auditoria) => {
          auditoria.pacs.forEach((pac) => {
            pacs.push({
              id: pac.uuid,
              subalcance: {
                color: auditoria.subalcance.color,
                nombre: auditoria.subalcance.nombre,
              },
              instalaciones: pac.auditoria.instalaciones,
              visita: auditoria.tipo,
              fecha: auditoria.fecha,
            })
          })
        })

        this.items = pacs
        this.db.items = pacs
      }
    },

    clickRow(row) {
      this.$router.push({
        name: 'auditorias-view',
        params: {
          uuid: row.uuid,
        },
      })
    },

    async checkRoles() {
      this.auth = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        return: 'all',
      })
      console.log('auth', this.auth)
    },
  },

  mounted() {
    this.checkRoles()
  },

  async created() {
    await this.init()
  },
}
</script>
