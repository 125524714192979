<template>
  <v-dialog v-model="_ui.quickCreate" max-width="750" scrollable>
    <v-card>
      <v-card-title class="grey lighten-4 py-3 mb-4">
        {{ $t('quickCreate.new_contact') }}
        <v-spacer></v-spacer>
        <v-btn icon text @click="_ui.quickCreate = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="pb-1 pt-2 mb-5 align-baseline" style="">
          <v-col cols="2" class="form-label text-right px-0 pr-7">{{ $t('quickCreate.details') }}</v-col>

          <v-col cols="10" md="10" sm="12" class="row ma-0 pa-0">

            <v-col cols="12" class="py-0">
              <v-radio-group v-model="contact.forma_juridica" row class="py-0">
                <v-radio :label="$t('quickCreate.physical_person')" value="fisica" class="py-0"></v-radio>
                <v-radio :label="$t('quickCreate.legal_entity')" value="juridica" class="py-0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-text-field outlined dense hide-details="auto"
                :label="contact.forma_juridica == 'fisica' || ui.showLastName ? $t('quickCreate.name_required') : $t('quickCreate.company_name_required')"
                v-model="contact.nombre" @blur="$v.contact.nombre.$touch()" @input="$v.contact.nombre.$touch()"
                :error="$v.contact.nombre.$error">
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator='{ on }'>
                      <v-icon v-on='on' style='cursor: pointer' @click="ui.showLastName = !ui.showLastName"
                        v-show="contact.forma_juridica !== 'fisica'">
                        {{ showLastName ? 'mdi-eye-outline' : 'mdi-eye-remove-outline' }}
                      </v-icon>
                    </template>
                    {{ $t('quickCreate.show_last_names') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6" v-show="showLastName">
              <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.first_lastname')"
                v-model="contact.apellido1"></v-text-field>
            </v-col>

            <v-col cols="6" v-show="showLastName">
              <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.second_lastname')"
                v-model="contact.apellido2"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field outlined dense hide-details="auto" :label="contact.formaJuridica == 'fisica' ? $t('quickCreate.nif') : $t('quickCreate.cif')"
                v-model="contact.nif"></v-text-field>
            </v-col>
          </v-col>
        </v-row>

        <v-row class="pb-1 pt-2 mb-3 align-baseline" style="">
          <v-col cols="2" class="form-label text-right px-0 pr-7">{{ $t('quickCreate.contact') }}</v-col>
          <v-col cols="5">
            <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.email')" v-model="contact.email"></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.phone')" v-model="contact.telefono"></v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="pb-1 pt-2 mt-3 align-baseline" style="">
          <v-col cols="2" class="form-label text-right px-0 pr-7">{{ $t('quickCreate.others') }}</v-col>

          <v-col cols="10" md="10" sm="12" class="row ma-0 pa-0">

            <v-col cols="12">
              <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.contact_person')"
                v-model="contact.personaContacto"></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-autocomplete outlined dense small :label="$t('quickCreate.contact_type')" hide-details="auto" :items="db.tipos"
                v-model="contact.tipoContacto"></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.position')" v-model="contact.cargo"></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field outlined dense hide-details="auto" :label="$t('quickCreate.company')" v-model="contact.nombreEmpresa"></v-text-field>
            </v-col>

            <v-col cols="6" v-if="empresa && empresa.codigo == 'ccl'">
              <v-autocomplete outlined dense multiple small :label="$t('quickCreate.contact_interests')" hide-details="auto" :items="[
                'VTCYL',
                'VV',
                'VTC',
                'ECO ET',
                'ECO PV',
                'VEGANO'
              ]" v-model="contact.intereses"></v-autocomplete>
            </v-col>

            <v-col cols="6" v-if="empresa && empresa.codigo == 'ccl'">
              <v-autocomplete outlined dense multiple small :label="$t('quickCreate.query_type')" hide-details="auto" :items="[
                'Telefónicamente',
                'Mail info@...',
                'Chat',
                'Jornada Eco',
              ]" v-model="contact.intereses"></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-textarea outlined dense auto-grow rows="3" :label="$t('quickCreate.description')" hide-details="auto"
                v-model="contact.descripcion"></v-textarea>
            </v-col>

            <v-col cols="6">
              <v-textarea outlined dense auto-grow rows="3" :label="$t('quickCreate.observations')" hide-details="auto"
                v-model="contact.observaciones"></v-textarea>
            </v-col>

          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn color="error darken-1" text @click="_ui.quickCreate = false" :disabled="ui.loading">
          {{ $t('quickCreate.close') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submit" text outlined :disabled="ui.loading"> {{ $t('quickCreate.save') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
/**
 * @project: certiapp-nuxt
 * @file:    /pages/personal/quickCreate.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 11th May 2021
 * Last Modified: Wed Jun 08 2022
 **/

import moment from 'moment'
import notifications from '~/util/notificationHelper'

import { uuid } from 'vue-uuid'
import { validationMixin } from 'vuelidate'
import { email, minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators'

export default {
  props: ['_ui'],
  mixins: [validationMixin],

  validations: {
    contact: {
      nombre: { required },
    },
  },

  data: () => ({
    contact: {
      tipo: 'Potencial',
      forma_juridica: 'fisica',
    },

    db: {
      alcances: [],
      tipos: [
        'Potencial',
        'Comercial',
        'Proveedor',
        'Administrativo',
        'Administraciones',
        'Contrataciones',
        'Otros',
      ],
    },

    ui: {
      loading: false,
      showLastName: false,
    },
  }),

  watch: {
    '_ui.quickCreate': function (value) {
      if (value == false) this.reset()
    },
  },

  computed: {
    empresa() {
      return this.$auth.user.empresa
    },

    showLastName() {
      return this.contact.forma_juridica == 'fisica' || this.ui.showLastName
    },
  },

  methods: {
    //+-------------------------------------------------
    // Funciones de flujo
    // Submit, validate, Upload...
    //+-------------------------------------------------
    async submit() {
      let step = false

      // 1 . Form validations
      if (this.validate()) step = 'valid'

      // 2. Api requests
      if (step == 'valid') this.store()
    },

    validate() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.warn(this.$v)
        notifications.show({
          text: 'El nombre del contacto es obligatorio',
          color: 'warning',
        })

        return false
      }

      return true
    },

    reset() {
      this.$v.$reset()
      this.contact = {
        tipo: 'Potencial',
        forma_juridica: 'fisica',
      }
    },

    //+-------------------------------------------------
    // Async API crud actions
    //+-------------------------------------------------

    async store() {
      const payload = { ...this.contact }
      payload.uuid = uuid.v4()

      try {
        this.ui.loading = true
        payload.empresa = this.empresa['@id']

        await this.$store.dispatch('contactos/create', payload)
        this.$emit('created', payload)
      } catch (e) {
        console.info(e)
        notifications.show({
          text: 'Ha ocurrido un error guardando los cambios',
          color: 'error',
        })
      } finally {
        this.ui.loading = false
      }
    },

    //+-------------------------------------------------
    // Async API requests
    //+-------------------------------------------------

    async getAlcances() {
      const xhr = await this.$store.dispatch('alcances/list')
      this.db.alcances = xhr
    },
  },

  mounted() {
    this.getAlcances()
    // this.ui = { ...this._ui }
  },
}
</script>

<style></style>
