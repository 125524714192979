/**
 * @project: certiapp-nuxt
 * @file:    models/modulos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th March 2021
 * Last Modified: Tue Aug 31 2021
 **/

export default [
  {
    label: 'General',
    translation: {
      es: 'General',
      en: 'General',
      fr: 'Général',
      pt: 'Geral',
      it: 'Generale',
      ja: '一般',
      ru: 'Общее',
      de: 'Allgemein',
      zh: '一般',
      tr: 'Genel',
      ar: 'عام',
    },
    single: 'General',
    value: 'general',
    link: '',
  },

  {
    label: 'Solicitudes',
    translation: {
      es: 'Solicitudes',
      en: 'Requests',
      fr: 'Demandes',
      pt: 'Pedidos',
      it: 'Richieste',
      ja: 'リクエスト',
      ru: 'Запросы',
      de: 'Anfragen',
      zh: '请求',
      tr: 'İstekler',
      ar: 'طلبات',
    },
    single: 'Solicitud',
    value: 'solicitudes',
    link: 'grupo_solicitudes/{uuid}',
  },

  {
    label: 'Grupo solicitudes',
    translation: {
      es: 'Grupo solicitudes',
      en: 'Request Group',
      fr: 'Groupe de demandes',
      pt: 'Grupo de pedidos',
      it: 'Gruppo richieste',
      ja: 'リクエストグループ',
      ru: 'Группа запросов',
      de: 'Anfragegruppe',
      zh: '请求组',
      tr: 'İstek Grubu',
      ar: 'مجموعة الطلبات',
    },
    single: 'Solicitud',
    value: 'grupo_solicitudes',
    link: 'grupo_solicitudes/{uuid}',
  },

  {
    label: 'Expedientes',
    translation: {
      es: 'Expedientes',
      en: 'Files',
      fr: 'Dossiers',
      pt: 'Arquivos',
      it: 'File',
      ja: 'ファイル',
      ru: 'Файлы',
      de: 'Dateien',
      zh: '文件',
      tr: 'Dosyalar',
      ar: 'ملفات',
    },
    single: 'Expediente',
    value: 'expedientes',
    link: 'expedientes/{uuid}',
  },

  {
    label: 'Auditorías',
    translation: {
      es: 'Auditorías',
      en: 'Audits',
      fr: 'Audits',
      pt: 'Auditorias',
      it: 'Audits',
      ja: '監査',
      ru: 'Аудиты',
      de: 'Audits',
      zh: '审计',
      tr: 'Denetimler',
      ar: 'تدقيق',
    },
    single: 'Auditoría',
    value: 'auditorias',
    link: 'auditorias/{uuid}',
  },

  {
    label: 'PACS',
    translation: {
      es: 'PACS',
      en: 'PACS',
      fr: 'PACS',
      pt: 'PACS',
      it: 'PACS',
      ja: 'PACS',
      ru: 'PACS',
      de: 'PACS',
      zh: 'PACS',
      tr: 'PACS',
      ar: 'PACS',
    },
    single: 'PAC',
    value: 'pacs',
    link: 'auditorias/{uuid}/pacs',
  },

  {
    label: 'Revisiones',
    translation: {
      es: 'Revisiones',
      en: 'Reviews',
      fr: 'Révisions',
      pt: 'Revisões',
      it: 'Recensioni',
      ja: 'レビュー',
      ru: 'Обзоры',
      de: 'Bewertungen',
      zh: '评论',
      tr: 'İncelemeler',
      ar: 'مراجعات',
    },
    single: 'Revisión',
    value: 'revisiones',
    link: 'revisiones/{uuid}',
  },

  {
    label: 'Dictámenes',
    translation: {
      es: 'Dictámenes',
      en: 'Dictums',
      fr: 'Dictons',
      pt: 'Ditados',
      it: 'Dettami',
      ja: '判決',
      ru: 'Постановления',
      de: 'Urteile',
      zh: '裁决',
      tr: 'Kararlar',
      ar: 'أحكام',
    },
    single: 'Dictámen',
    value: 'dictamenes',
    link: 'dictamenes/{uuid}',
  },

  {
    label: 'Certificados',
    translation: {
      es: 'Certificados',
      en: 'Certificates',
      fr: 'Certificats',
      pt: 'Certificados',
      it: 'Certificati',
      ja: '証明書',
      ru: 'Сертификаты',
      de: 'Zertifikate',
      zh: '证书',
      tr: 'Sertifikalar',
      ar: 'شهادات',
    },
    single: 'Certificado',
    value: 'certificados',
    link: 'certificados/{uuid}',
  },

  {label: 'Noticias',
    translation: {
      es: 'Noticias',
      en: 'News',
      fr: 'Nouvelles',
      pt: 'Notícias',
      it: 'Notizie',
      ja: 'ニュース',
      ru: 'Новости',
      de: 'Nachrichten',
      zh: '新闻',
      tr: 'Haberler',
      ar: 'أخبار',
    },
    single: 'Noticia',
    value: 'newsboard',
    link: 'newsboard',
  }
]
