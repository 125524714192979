export const state = () => ({
  items: [],
})

export const mutations = {
  setMemberships(state, memberships) {
    state.items = memberships
  },
  addMembership(state, membership) {
    state.items.push(membership)
  },
  updateMembership(state, updatedMembership) {
    const index = state.items.findIndex((item) => item.uuid === updatedMembership.uuid)
    if (index !== -1) {
      state.items.splice(index, 1, updatedMembership)
    }
  },
  deleteMembership(state, uuid) {
    state.items = state.items.filter((item) => item.uuid !== uuid)
  },
}

export const actions = {
  async fetchMemberships({ commit }) {
    const membershipsResponse = await this.$axios.$get('memberships')

    const memberships = membershipsResponse['hydra:member']

    commit('setMemberships', memberships)

    memberships.forEach(async (membership) => {
      if (membership.image) {
        membership.image = null
        this.dispatch('memberships/fetchLogo', membership.uuid)
      }
    })
  },
  async fetchLogo({ commit, state }, uuid) {
    try {
      const response = await this.$axios({
        url: `memberships/${uuid}/image`,
        method: 'GET',
        responseType: 'blob',
      })

      if (response.data instanceof Blob) {
        const image = URL.createObjectURL(response.data)

        const membership = state.items.find((item) => item.uuid === uuid)
        if (membership) {
          membership.image = image
          commit('updateMembership', membership)

          setTimeout(() => {
            URL.revokeObjectURL(image)
          }, 60000)
        }
      } else {
        console.error('La respuesta no es un blob válido:', response)
      }
    } catch (error) {
      console.error('Error al obtener la imagen de la membresía:', error)
    }
  },

  async createMembership({ commit }, membership) {
    try {
      const formData = new FormData()

      if (membership.image && membership.image.startsWith('data:image')) {
        const blob = await fetch(membership.image).then((res) => res.blob())
        formData.append('imageFile', blob, 'membership_image.jpg')
      }

      for (const key in membership) {
        if (key !== 'image') {
          formData.append(key, membership[key])
        }
      }

      const response = await this.$axios.post('memberships', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      commit('addMembership', response.data)
    } catch (error) {
      console.error('Error al crear la membresía:', error)
      throw error
    }
  },
  async updateMembership({ commit }, membership) {
    // Simulación de actualización en API
    commit('updateMembership', membership)
  },
  async deleteMembership({ commit }, uuid) {
    // Simulación de eliminación en API
    commit('deleteMembership', uuid)
  },
}
