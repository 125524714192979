/**
 * @project: certiapp-nuxt
 * @file:    store/clientes.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 1st February 2021
 * Last Modified: Thu Jul 13 2023
 **/

import { uuid } from 'vue-uuid'
import { f } from 'vuetify-daterange-picker'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, params = {}) {
    rootState.loading = true
    let res = null
    params.page = params.page || 1
    params.perPage = params.perPage || 100
    params.sortBy = params.sortBy || 'nombre'
    params.sortDesc = params.sortDesc || false
    //?order[property]=<asc|desc>
    let url = `/usuarios?tipo=cliente&page=${params.page}&itemsPerPage=${params.perPage}&order[${
      params.sortBy || 'nombre'
    }]=${params.sortDesc ? 'desc' : 'asc'}`
    if (params.search) {
      params.nombre = params.search.toLowerCase()
      url = url + `&nombre=${params.nombre}`
    }
    res = await this.$axios.get(url)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res
    }
  },

  async listSelect({ rootState, commit }, params) {
    rootState.loading = true
    let res = null
    let url = `/usuarios?tipo=cliente&itemsPerPage=100`
    if (params.search) {
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    res = await this.$axios.get(url)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res
    }
  },

  async listActive({ rootState, commit }, params) {
    rootState.loading = true

    let prefix = this.$auth.user?.empresa?.codigo == 'dovino' ? 'apis/toro/' : ''
    const res = await this.$axios.get(prefix + 'usuarios?tipo=cliente')
    //, { params: { activo: 1 } }
    if (this.$auth.user?.empresa?.codigo == 'dovino') {
      res.data = processVinoClients(res.data)
    }

    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },
  async listActivePaginated({ rootState }, params = {}) {
    rootState.loading = true
    const queryParams = []
    queryParams.push(`tipo=cliente`)

    if (params.search) {
      queryParams.push(`search=${encodeURIComponent(params.search)}`)
    }
    if (params.page) {
      queryParams.push(`page=${params.page}`)
    }
    if (params.itemsPerPage) {
      queryParams.push(`itemsPerPage=${params.itemsPerPage}`)
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    try {
      const res = await this.$axios.get(`usuarios${queryString}`)
      rootState.loading = false

      if (res?.data && res.data['hydra:member']) {
        return {
          items: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
        }
      } else {
        return {
          items: [],
          totalItems: 0,
        }
      }
    } catch (error) {
      rootState.loading = false
      console.error('Error al obtener clientes:', error)
      return {
        items: [],
        totalItems: 0,
      }
    }
  },
  async listActivePaginatedWithLicense({ rootState }, params = {}) {
    rootState.loading = true
    const queryParams = []
    queryParams.push(`tipo=cliente`)

    if (params.search) {
      queryParams.push(`search=${encodeURIComponent(params.search)}`)
    }
    if (params.page) {
      queryParams.push(`page=${params.page}`)
    }
    if (params.itemsPerPage) {
      queryParams.push(`itemsPerPage=${params.itemsPerPage}`)
    }
    if (params['subalcance.uuid']) {
      queryParams.push(`activeLicense=${params['subalcance.uuid']}`)
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    try {
      const res = await this.$axios.get(`usuarios${queryString}`)
      rootState.loading = false

      if (res?.data && res.data['hydra:member']) {
        return {
          items: res.data['hydra:member'],
          totalItems: res.data['hydra:totalItems'],
        }
      } else {
        return {
          items: [],
          totalItems: 0,
        }
      }
    } catch (error) {
      rootState.loading = false
      console.error('Error al obtener clientes:', error)
      return {
        items: [],
        totalItems: 0,
      }
    }
  },
  async get({ rootState }, uuid) {
    rootState.loading = true

    // let prefix = this.$auth.user?.empresa?.codigo == 'dovino' ? 'apis/toro/' : ''
    const res = await this.$axios.get(`usuarios/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getExpedientes()
  // Returns the expedientes associated to a client
  // -----
  // Created on Tue May 18 2021
  //+-------------------------------------------------
  async getExpedientes({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`expedientes?user.uuid=${uuid}`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // getImports()
  // Returns the Imports associated to a client
  // -----
  // Created on Tue May 18 2021
  //+-------------------------------------------------
  async getImports({ rootState }, params) {
    rootState.loading = true

    const res = await this.$axios.put(`importaciones`, params)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getEvents()
  // Returns the events associated to a client
  // events are history elements, not calendar events
  // -----
  // Created on Tue May 18 2021
  //+-------------------------------------------------
  async getEvents({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`eventos?cliente=${uuid}`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  //+-------------------------------------------------
  // getAlcances()
  // Returns the alcances for this client
  // -----
  // Created on Tue May 19 2021
  //+-------------------------------------------------
  async getAlcances({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`clientes/${uuid}/alcances`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // getDocumentos()
  // Returns documents associated to this user
  // -----
  // Created on Wed May 19 2021
  // Updated on Wed Jan 19 2022
  //+-------------------------------------------------
  async getDocumentos({ rootState }, params) {
    console.log(params)
    rootState.loading = true
    let res = null
    if (params.type == 'Contacto') {
      res = await this.$axios.get(`documentos?contacto.uuid=${params.contact_uuid}`)
    } else {
      res = await this.$axios.get(`documentos?user.uuid=${params.contact_uuid}`)
    }

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async getDocumentosPortal({ rootState }, uuid) {
    rootState.loading = true

    const res = await this.$axios.get(`documentos?user.uuid=${uuid}`)

    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  // Legacy
  // async create({ rootState }, cliente) {
  //   rootState.loading = true
  //   if (cliente.user) {
  //     cliente.username = cliente.user.username
  //     cliente.password = cliente.user.password
  //   }
  //   if (cliente.empresa) cliente.empresa = cliente.empresa.uuid
  //   if (cliente.pais) cliente.pais = cliente.pais.uuid
  //   if (cliente.provincia) cliente.provincia = cliente.provincia.uuid
  //   if (cliente.municipio) cliente.municipio = cliente.municipio.uuid
  //   if (cliente.comunidad) cliente.comunidad = cliente.comunidad.uuid
  //   await this.$axios.post('clientes', cliente)
  //   await this.dispatch('custom_variables/check', cliente.uuid)
  //   const body = {
  //     cliente_uuid: cliente.uuid,
  //   }
  //   await this.dispatch('clientes_tecnicos/create', body)
  //   await this.dispatch('clientes_instalaciones/create', body)
  //   rootState.loading = false
  // },

  //+-------------------------------------------------
  // create()
  // A new way to format the payload to store the client
  // user, and contact in the same call.
  // -----
  // Created on Tue Jun 08 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let data = { ...params }
    data.uuid = params.uuid || uuid.v4()
    if (data.pais?.uuid) data.pais = data.pais.uuid
    if (data.provincia?.uuid) data.provincia = data.provincia.uuid
    if (data.municipio?.uuid) data.municipio = data.municipio.uuid
    if (data.comunidad?.uuid) data.comunidad = data.comunidad.uuid
    if (data.localidad?.uuid) data.localidad = data.localidad.uuid

    await this.$axios.post('clientes', data)

    rootState.loading = false
  },

  // async update({ rootState }, cliente) {
  //   rootState.loading = true
  //   cliente.cliente = cliente.uuid
  //   cliente.user.user = cliente.user.uuid
  //   cliente.empresa = cliente.empresa.uuid
  //   cliente.user = cliente.user.uuid
  //   cliente.pais = cliente.pais.uuid
  //   if (cliente.provincia) cliente.provincia = cliente.provincia.uuid
  //   if (cliente.municipio) cliente.municipio = cliente.municipio.uuid
  //   if (cliente.comunidad) cliente.comunidad = cliente.comunidad.uuid

  //   await this.$axios.patch(`clientes/${cliente.uuid}`, cliente)
  //   await this.dispatch('custom_variables/check')

  //   rootState.loading = false
  // },

  //+-------------------------------------------------
  // update()
  // -----
  // Created on Tue Jun 08 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    let data = { ...params }

    if (data.pais?.uuid) data.pais = data.pais.uuid
    if (data.provincia?.uuid) data.provincia = data.provincia.uuid
    if (data.municipio?.uuid) data.municipio = data.municipio.uuid
    if (data.comunidad?.uuid) data.comunidad = data.comunidad.uuid
    if (data.localidad?.uuid) data.localidad = data.localidad.uuid

    await this.$axios.patch(`clientes/${params.uuid}`, data)

    rootState.loading = false
  },

  //+-------------------------------------------------
  // changeStatus()
  // Updates the status (alta, baja) for a client
  // -----
  // Created on Thu May 20 2021
  //+-------------------------------------------------
  async changeStatus({ rootState }, params) {
    rootState.loading = true

    let endpoint = params.status == 'alta' ? 'alta' : 'baja'
    let key = 'fecha_' + params.status
    let body = {
      cliente: params.uuid,
      observaciones: params.observaciones,
    }

    body[key] = params.fecha_valor
    await this.$axios.post(`clientes/${endpoint}`, body)

    rootState.loading = false
  },

  async changeImagen({ rootState }, data) {
    rootState.loading = true
    let formData = new FormData()
    formData.append('cliente', data.uuid)
    formData.append('file', data.file)
    await this.$axios.post(`uploads/cliente-avatar`, formData)
    rootState.loading = false
  },

  async changePassword({ rootState }, cliente) {
    rootState.loading = true
    const data = {
      cliente: cliente.uuid,
      password: cliente.user.password,
    }
    await this.$axios.patch(`clientes/${cliente.uuid}/change-password`, data)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // addContacto()
  // Links a contacto this client
  // -----
  // Created on Wed May 19 2021
  //+-------------------------------------------------
  async addContacto({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(
      `usuarios/${params.cliente}`,
      {
        contactos: params.contacto,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  //+-------------------------------------------------
  // addTecnico()
  // Links a tecnico to this client
  // -----
  // Created on Wed May 19 2021
  //+-------------------------------------------------
  async addTecnico({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(
      `usuarios/${params.cliente}`,
      {
        tecnicos: params.tecnico,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )

    rootState.loading = false
  },

  //+-------------------------------------------------
  // deleteTecnico()
  // Removes the relation between a tecnico and a client
  // -----
  // Created on Tue Jul 20 2021
  //+-------------------------------------------------
  async deleteTecnico({ rootState }, params) {
    rootState.loading = true
    await this.$axios.delete(`clientes/${params.cliente}/tecnicos/${params.tecnico}`)
    rootState.loading = false
  },

  async delete({ rootState }, cliente) {
    rootState.loading = true
    let params = {
      activo: false,
    }
    let response = await this.$axios.patch(`usuarios/${cliente.uuid}`, params, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
    return response
  },

  async search({ rootState }, user) {
    return null
    rootState.loading = true
    const data = {
      user: user,
    }
    const res = await this.$axios.put(`clientes/search`, data)
    if (res) return res.data
    rootState.loading = false
  },

  async emplazamientos_ultima_iteracion({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.post(`usuarios/${params.uuid}/emplazamientos-last-iteracion`, {
      subalcance: params.subalcance['@id'],
    })
    if (res) return res.data['hydra:member']
    rootState.loading = false
  },
}

function processVinoClients(clients) {
  let items = []
  for (const type in clients) {
    const client = clients[type]
    for (const id in client) {
      let row = client[id]

      row.contacto = {
        nombre: row.nombre,
        email: row.email,
        telefono: row.telefono.toString(),

        user: {
          email: row.email,
          username: row.code || row.codigo,
        },

        localidad: {
          provincia: {
            nombre: row.localidad,
          },
        },
      }

      items.push(row)
    }
  }

  console.warn(items)
  return items
}
