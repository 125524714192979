export const state = () => ({
  list: [],
  item: {},
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  },
}

//dispatch actions
export const actions = {
  async list({ rootState, commit }, params) {
    rootState.loading = true
    let res = null
    if (!params) {
      params = {
        page: 1,
        perPage: 100,
        sortBy: 'nombre',
        sortDesc: false,
      }
    }
    let url = `/usuarios?page=${params.page}&itemsPerPage=${params.perPage}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`
    if (params.search) {
      params.nombre = params.search.toLowerCase()

      url = url + `&nombre=${params.nombre}`
    }
    res = await this.$axios.get(url)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res
    }
  },
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`usuarios/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  async getByTipo({ rootState }, params) {
    rootState.loading = true
    if (Array.isArray(params.tipo)) {
      params.tipo = params.tipo.join('&tipo[]=')
    }
    const res = await this.$axios.get(
      `usuarios?tipo[]=${params.tipo}&empresa.uuid=${params.empresa}`
    )
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.post(`usuarios`, params)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // update()
  // -----
  // Updated on Thu Jul 20 2023
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true
    if (params.contactos) params.contactos = params.contactos.map((item) => item['@id'])
    //params.cliente.contactos = params.cliente.contactos.map((item) => item['@id'])
    if (params.tecnicos) params.tecnicos = params.tecnicos.map((item) => item['@id'])
    //delete null values of params.tecnicos
    if (params.tecnicos) params.tecnicos = params.tecnicos.filter((item) => item)

    if (params.cliente) params.cliente = params.cliente['@id']
    if (params.user) params.user = params.user['@id']
    if (params.empresa) params.empresa = params.empresa['@id']

    await this.$axios.patch(`usuarios/${params.uuid}`, params, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
  },

  async importClients({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(params.empresaUuid + '/importar-clientes', params.clientes)
    rootState.loading = false
  },

  async importHistorical({ rootState }, params) {
    rootState.loading = true

    await this.$axios.post(params.empresaUuid + '/importar-historico', params.historico)
    rootState.loading = false
  },

  async changePassword({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`users/${params.user}/change-password`, {
      user: params.user,
      password: params.password,
    })

    rootState.loading = false
  },

  async checkUsername({ rootState }, username) {
    rootState.loading = true
    const res = await this.$axios.get(`usuarios/check-username/${username}`)
    rootState.loading = false
    if (res) return res.data
  },

  async checkEmail({ rootState }, email) {
    rootState.loading = true
    const res = await this.$axios.put(`users/check-email`, {
      email: email,
    })
    rootState.loading = false
    if (res) return res.data
  },
}
