var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.cliente && !_vm.expediente)?_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h1',[_vm._v("Auditorías")])]),_vm._v(" "),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"6"}},[(_vm.ui.showOffline)?_c('v-btn',{staticClass:"mr-1",attrs:{"depressed":"","outlined":"","color":"purple"},on:{"click":_vm.dropCache}},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v("mdi-water-remove")]),_vm._v("\n        Vaciar\n      ")],1):_vm._e()],1),_vm._v(" "),(_vm.isOffline)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"dark":"","icon":"mdi-wifi-strength-alert-outline"}},[_vm._v("\n        Durante el modo sin conexión únicamente se muestran las auditorías que hayan sido\n        precargadas en este dispositivo.\n      ")]),_vm._v(" "),_c('v-alert',{attrs:{"dark":"","icon":"mdi-alert-box-outline"}},[_vm._v("\n        Es importante que no refresque la aplicación mientras se encuentra sin conexión o podría\n        quedarse bloqueado."),_c('br'),_vm._v("\n        También es importante que el dispositivo no entre en modo reposo mientras se encuentra sin\n        conexión.\n      ")])],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.ui.showOffline),expression:"ui.showOffline"}],staticClass:"my-5"},[_c('v-col',[_c('offlineSelector',{ref:"offline",attrs:{"nope-component":"auditoria","enabled":_vm.ui.showOffline,"onInit":['auditorias/list'],"onInitBody":[{}],"cols":[
          { text: 'Estado', value: 'estado' },
          { text: 'Desde', value: 'desde_f' },
          { text: 'hasta', value: 'hasta_f' },
          { text: 'Alcance', value: 'nombre_alcance' },
          { text: 'Cliente', value: 'nombreApellidos' },
          { text: 'Emplazamiento', value: 'emplazamiento' },
        ],"items":_vm.allowedToOffline,"howToLoad":[
          {
            action: 'clearCache',
            key: 'auditorias/uuid',
          },
          {
            action: 'lockAuditoria',
          },
          {
            component: 'auditoria',
            uuid: 'uuid',
          },
          {
            request: 'checklists/list',
          },
          {
            component: 'checklist',
            uuid: 'uuid',
            loop: true,
          },
          {
            request: 'tomas_muestra/list',
            uuid: 'itemUUID',
          },
          {
            component: 'muestras',
            uuid: 'uuid',
            loop: true,
          },
          {
            action: 'downloadClientFiles',
            uuid: 'cliente.uuid',
          },
        ]}})],1)],1),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ui.showOffline),expression:"!ui.showOffline"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('ag-datatable',{key:_vm.ui.refresh,attrs:{"headers":_vm.headers,"items":_vm.items,"emitClickRow":"","urlData":"auditorias"},on:{"click-row":_vm.clickRow,"gridApi":function($event){_vm.gridApi = $event}}})],1)],1),_vm._v(" "),_c('confirmation',{attrs:{"text":'¿Eliminar la auditoria seleccionada?',"colorButton":"error"},on:{"confirm":function($event){return _vm.deleteItem()}},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }