<template>
    <v-autocomplete filled outlined dense label="Dictamen" hide-details="auto" :items="db.dictamenes"
        :item-text="displayText" item-value="uuid" v-model="plantilla.refUuid" return-object @change="submit">
        <template v-slot:append-outer class="ma-0">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="info darken-1" size="30" @click="generate"
                        style="cursor: pointer">mdi-file-download-outline</v-icon>
                </template>
                <span>Genera formato de ejemplo con la entidad seleccionada y descarga el
                    fichero</span>
            </v-tooltip>
        </template>
    </v-autocomplete>
</template>

<script>
import moment from 'moment'


export default {
    props: {
        plantilla: {
            type: Object,
            default: () => ({})
        },


    },
    data: () => ({
        db: {
            dictamenes: []
        }
    }),
    methods: {
        displayText(item) {
            return `Dictámen del ${moment(item.fechaProposicion).format('DD/MM/YYYY')}`
        },
        submit() {
            this.$emit('input', this.plantilla)
        },
        generate() {
            this.$emit('generate')
        },

        async getDictamenes() {
            if (!this.plantilla.subalcance) return
            let subalcance_uuid = null
            if (typeof this.plantilla.subalcance == 'object')
                subalcance_uuid = this.plantilla.subalcance.uuid
            else subalcance_uuid = this.plantilla.subalcance
            const xhr = await this.$store.dispatch('dictamenes/listBySubalcance', subalcance_uuid)
            console.log(xhr)
            this.db.dictamenes = xhr
        },

        async init() {
            await this.getDictamenes()
        }
    },

    mounted() {
        this.init()
    }
}
</script>