<template>
  <div v-if="isNotGroup">
    <span v-if="!hide">
      <v-icon
        style="font-size: 20px"
        color="primary"
        :disabled="!CAN_DELETE"
        @click.stop="controlClick()"
        >mdi-delete-outline</v-icon
      >
    </span>
    <!-- delete forever for admins -->
    <span v-if="isAdminGroup && !hide">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            style="font-size: 20px"
            color="error"
            @click.stop="controlClick()"
            >mdi-delete-forever</v-icon
          >
        </template>
        <span>Delete Forever</span>
      </v-tooltip>
    </span>

    <confirmation
      v-model="confirmDelete"
      :text="params.text || '¿Eliminar el objeto seleccionado?'"
      colorButton="error"
      @confirm="deleteItem()" />
  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  components: {
    Confirmation,
  },
  data: function () {
    return {
      confirmDelete: false,
    }
  },
  computed: {
    IS_LIST() {
      console.log('hide', this.params)
      return !this.$route.params.uuid
    },
    CAN_DELETE() {
      console.log('permisos', this.$store.state.permisos)
      return this.IS_LIST ? this.$store.state.permisos.can_delete : true
    },
    isAdminGroup() {
      // return this.$store.getters.isAdminGroup
      // de momento only for kaam
      return this.$store.getters.isKaam
    },
    isNotGroup() {
      // console.log('hide', this.params)
      return !!this.params.data
    },
    hide() {
      // console.log('hide', this.params)
      return this.params.value === true
    },
  },
  methods: {
    init() {
      if (this.params.init instanceof Function) this.params.init()
    },
    controlClick() {
      if (this.params.dispatch || this.params.confirm) this.confirmDelete = true
      else this.deleteItem()
    },
    refreshData() {
      this.$parent.gridOptions.api.purgeServerSideCache()
    },
    async deleteItem() {
      this.confirmDelete = false

      let response = { status: 200 }
      if (this.params.dispatch && this.params.node.data.uuid) {
        response = await this.$store.dispatch(`${this.params.dispatch}`, this.params.node.data)
      }
      if (response && response.status !== 403) {
        this.$store.commit('notification/show', {
          text: this.params.onSuccess || 'Elemento eliminado correctamente',
          color: 'success',
          timeout: 3000,
        })

        this.refreshData()
      } else {
        this.$store.commit('notification/show', {
          text: this.params.onError || 'No se puede eliminar el objeto seleccionado',
          color: 'error',
          timeout: 3000,
        })
      }
    },
  },
}
</script>
