export const state = () => ({});

//commit mutations
export const mutations = {};


//dispatch actions
export const actions = {

  // ITERACIONES PENDIENTES DE AUDITAR
  async pendientesAuditar({rootState}) {
    rootState.loading = true
    const res = await this.$axios.get(`iteraciones/pendientes-auditar`)
    rootState.loading = false
    if (res) return res.data
  },

  async updateAnexo({rootState}, data) {
    rootState.loading = true
    const res = await this.$axios.patch(`iteraciones/${data.uuid}`, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
    rootState.loading = false
    if (res) return res.data
  }

};
