<template>
  <v-dialog v-model="ui.show" width="100%" max-width="600px" persistent scrollable>
    <v-form ref="form_dinamic" v-model="ui.isValid" :disabled="$store.getters.loading">
      <v-card flat :loading="$store.getters.loading">
        <v-card-title>
          {{ isNew ? $t('formGridDinamico.nuevoRegistro') : $t('formGridDinamico.editarRegistro') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6" v-for="(conf, index) in gridConfig" :key="index">
              <campo-formulario
                :tipo="conf.tipo"
                :subalacance_tipo="tipo"
                :nombre="conf.nombre"
                :required="conf.tipo === 'estatico' ? true : false"
                :estatico="
                  conf.tipo === 'estatico' && typeof conf.selector == 'object'
                    ? conf.selector
                    : null
                "
                :valor="valor[index]"
                :cliente="cliente"
                :multiple="conf.multiple"
                @change="item[conf.nombre] = $event" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions v-if="!ui.loading" class="pa-4">
          <v-btn text @click="ui.show = false" :disabled="ui.loading">
            {{ $t('formGridDinamico.cancelar') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            @click="submit"
            :disabled="ui.loading || $store.getters.loading">
            {{ $t('formGridDinamico.guardar') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else style="flex-direction: column" class="px-8">
          <div class="text-disabled d-block">{{ $t('formGridDinamico.guardando') }}</div>
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="4"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>


<script>
import MODEL from '~/models/tiposDatos'
import RULES from '~/models/form.rules'
import notifications from '~/util/notificationHelper'
import SelectEstaticos from '~/components/forms/SelectEstaticos.vue'
import CampoFormulario from '~/components/solicitudes/CampoFormulario.vue'

export default {
  name: 'FormGridDinamico',

  props: {
    cliente: { type: Object, default: null },
    tipo: { type: String, default: '' },
  },

  components: { CampoFormulario, SelectEstaticos },

  data: () => ({
    gridConfig: [],
    group: null,
    uuid_config: null,
    MODEL,
    RULES,
    action: 'create',
    tipoProducto: 'propio',
    ui: {
      show: false,
      loading: false,
      isValid: false,
    },
    item: {},
  }),

  watch: {
    'ui.show': function (value) {
      if (!value) this.reset()
    },
  },

  computed: {
    isNew() {
      return this.action == 'create' ? true : false
    },
    valor() {
      let valor = []
      for (let i = 0; i < this.gridConfig.length; i++) {
        const element = this.gridConfig[i]
        valor.push(this.item[element.nombre])
      }
      return valor
    },
  },

  methods: {
    reset() {
      if (this.$refs.form_dinamic) {
        this.$refs.form_dinamic.reset()
        this.$refs.form_dinamic.resetValidation()
      }
    },
    async new(group, uuid, configs, tipo) {
      await this.reset()
      this.item = {}
      this.action = 'create'
      this.ui.show = true
      this.group = group
      this.uuid_config = uuid
      this.gridConfig = configs
      this.tipoProducto = tipo
    },

    edit(group, uuid, configs, item, index, tipo) {
      this.item = { ...item }
      this.item.index = index
      //if(!this.item.isArray)
      this.group = group
      this.uuid_config = uuid
      this.gridConfig = configs
      this.tipoProducto = tipo
      this.action = 'update'
      this.ui.show = true
      this.$forceUpdate()
    },

    async submit() {
      if (await this.$refs.form_dinamic.validate()) {
        await this.store()
      } else {
        notifications.show({
          text: this.$t('formGridDinamico.error'),
          color: 'warning',
        })
      }
    },

    async store() {
      this.ui.loading = true
      let action = this.action

      this.$emit('stored', {
        action: action,
        group: this.group,
        uuid_config: this.uuid_config,
        item: this.item,
        tipo: this.tipoProducto ?? 'propio',
      })

      this.ui.loading = false
      this.ui.show = false
      this.reset()
      this.$forceUpdate()
    },

    async init() {
      //clear form
      this.reset()
    },
  },

  created() {
    this.init()
  },

  beforeDestroy() {},
}
</script>
