/**
 * @project: certiapp-nuxt
 * @file:    \store\plantillas-checklist.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Wed Jun 21 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  // Checklist
  async list({ rootState, commit }, alcance_uuid) {
    rootState.loading = true
    let itemsPerPage = 100

    const res = await this.$axios.get('respuesta_checklists?page=1&itemsPerPage='+itemsPerPage)
    let listResults = []
    if (alcance_uuid) {
      listResults = res.data['hydra:member']
    } else {
      listResults = res.data['hydra:member']
    }
    rootState.loading = false
    if (res) {
      commit('SET_LIST', listResults)
      return listResults
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`respuesta_checklists/${uuid}`)
    console.log(res)
    rootState.loading = false
    if (res) return res.data
  },


  // Preguntas
  async createPregunta({ rootState }, pregunta) {
    pregunta.uuid = uuid.v4()
    const res = await this.$axios.post(
      `respuesta_checklists`,
      pregunta
    )
    if (res) {
      return res.data
    }
  },


  async updatePregunta({ rootState }, pregunta) { 
    try {
      await this.$axios.patch(
        `respuesta_checklists/${pregunta.uuid}`,
        pregunta,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }
      )
    } catch (error) {
      console.error(`There was a problem with the axios operation: ${error.message}`);
    }
  },

  async updateOpcionRespuesta({ rootState }, opcion) { 
    try {
      await this.$axios.patch(
        `opcion_respuestas/${opcion.uuid}`,
        opcion,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json'
          }
        }
      )
    } catch (error) {
      console.error(`There was a problem with the axios operation: ${error.message}`);
    }
  },

  // Crear Opciones Respuesta
  async createOpcionRespuesta({ rootState }, pregunta) {
    // rootState.loading = true
    pregunta.uuid = uuid.v4()
    await this.$axios.post(
      `opcion_respuestas`,
      pregunta
    )
    // rootState.loading = false
  },

 
  async listOpcionesRespuesta({ rootState, commit }, respuesta) {
    rootState.loading = true
    let itemsPerPage = 100
    const res = await this.$axios.get('opcion_respuestas?page=1&itemsPerPage='+itemsPerPage+'&respuestaChecklist.uuid='+respuesta)
    let listResults = []
    if (respuesta) {
      listResults = res.data['hydra:member']
    } else {
      listResults = res.data['hydra:member']
    }
    rootState.loading = false
    if (res) {
      commit('SET_LIST', listResults)
      return listResults
    }
  },


  async deleteOpcionRespuesta({ rootState }, opcion) {
    await this.$axios.delete(
      `opcion_respuestas/${opcion.uuid}`
    )
  },

  async deleteRespuesta({ rootState }, respuesta) {
    await this.$axios.delete(
      `respuesta_checklists/${respuesta.uuid}`
    )
  },


  async getRespuestaChecklistForSubalcance({ rootState }, subalcance) {
    rootState.loading = true
    const res = await this.$axios.get('respuesta_checklists?subalcance.uuid='+subalcance)
    let listResults = []
    listResults = res.data['hydra:member']
    rootState.loading = false
    if (res) {
      return listResults
    }
  },


  async getOpcionesRespuestaChecklistForSubalcance({ rootState }, subalcance) {
    rootState.loading = true
    const res = await this.$axios.get('opcion_respuestas?respuestaChecklist.subalcance.uuid='+subalcance)
    let listResults = []
    listResults = res.data['hydra:member']
    rootState.loading = false
    if (res) {
      return listResults
    }
  },
  async getOpcionesRespuestaForChecklist({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get('opcion_respuestas?respuestaChecklist.uuid='+uuid)
    let listResults = []
    listResults = res.data['hydra:member']
    rootState.loading = false
    if (res) {
      return listResults
    }
  },

  async getOpcionesRespuestaChecklistForCrudPregunta({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get('opcion_respuestas?r&codigo='+uuid)
    let listResults = []
    listResults = res.data['hydra:member']
    rootState.loading = false
    if (res) {
      return listResults
    }
  }


}
