import menu from '~/models/menu'
import menuClients from '~/models/menuClients'
import moduloToLink from '~/models/moduloToLink'
import ROLE from '~/models/role'

export default async function (ctx) {
  const whiteListLinks = [
    'login',
    'logout',
    'dashboard',
    'formatos',
    // 'generador_formatos',
    'informes',
    'settings',
    'membresias',
    'errors',
    'marcas',
    'profile',
  ]

  const getLinkFromModulo = (moduleName) => moduloToLink[moduleName]

  if (ctx.$auth.user) {
    // console.log('🔐', 'middleware/controlMenuRoles', ctx.route.path, ctx.route.name, ctx.$auth.user, ctx.route)
    const path = ctx.route?.path.split('/')
    const roles = ctx.$auth?.user.roles || []
    const permisos = ctx.$auth?.user.permisos || []

    let isPortal = ctx.route.path.indexOf('portal/') > -1 ? true : false
    let routePath = ctx.route.path.replace('/', '')
    let routePath_ = path[1]

    // console.warn(routePath, routePath_, routeName, ctx.route)

    //+-------------------------------------------------
    // Roles and permissions disabled for clients and tecnicos
    // that want to access the portal pages
    //+-------------------------------------------------
    if (ctx.$auth.user.tipo == 'cliente' || ctx.$auth.user.tipo == 'tecnico') {
      if (isPortal) return
      else if (!whiteListLinks.indexOf(routePath_)) return ctx.redirect('/dashboard')
    }

    // if the current routeName is in the whiteListLinks
    // then we don't need to check the roles and permissions
    if (whiteListLinks.indexOf(routePath_) > -1) {
      return
    }

    if (routePath_ == 'grupo_solicitudes') routePath_ = 'solicitudes'
    if (routePath.indexOf('personal/') == 0) routePath_ = 'personal/usuarios'
    //si la ruta contiene /admin/ entonces se toma el primer segmento despues de /admin/ como el modulo
    if (routePath.indexOf('admin/') == 0) routePath_ = routePath.split('/')[1]

    //si la ruta es marcas entonces se toma el modulo como alcances
    if (routePath.indexOf('marcas') > -1) routePath_ = 'alcances'

    let menuItem = permisos.find((item) => {
      // console.log('ITEM', item.modulo)
      // console.log('ROUTE', routePath_)

      if (routePath.indexOf('grupo_solicitudes') > -1) {
        if (item.modulo == 'solicitudes' && item.submodulo == 'solicitudes') return item
      }
      if (getLinkFromModulo(item.modulo) !== null && getLinkFromModulo(item.modulo) !== undefined) {
        if (
          (getLinkFromModulo(item.modulo) == routePath ||
            getLinkFromModulo(item.modulo) == routePath_ ||
            getLinkFromModulo(item.modulo).indexOf(routePath_) > -1) &&
          item.canConfig?.canList == true
        )
          return item
      }
    })

    if (!menuItem) {
      ctx.$auth.user.unauthorized = { module: routePath_ }
      console.warn(
        '🔐',
        'No se encuentra permiso para el modulo',
        { module: routePath_ },
        roles,
        ctx.route.path
      )

      if (roles.indexOf('ROLE_ADMIN_GROUP') >= 0) {
        return
      } else {
        return ctx.redirect('/errors/unauthorized')
      }
    }

    let roleToCheck = menuItem.rol
    let moduloToCheck = menuItem.modulo

    if (roles.indexOf(roleToCheck) < 0) {
      ctx.$auth.user.unauthorized = { module: moduloToCheck }
      console.warn('🔐', 'No se permite el acceso', `No tiene el rol mínimo ${roleToCheck}`, roles)
      return ctx.redirect('/errors/unauthorized')
    }

    if (menuItem.canConfig?.canList !== true) {
      let someTrue = Object.values(menuItem.canConfig).some((item) => item == true)

      if (!someTrue) {
        ctx.$auth.user.unauthorized = { module: moduloToCheck }
        console.warn(
          '🔐',
          'No se permite el acceso',
          `No tiene permiso para listar ${moduloToCheck}`,
          menuItem
        )
        return ctx.redirect('/errors/unauthorized')
      }
    }
    ctx.store.commit('permisos/SET_PERMISOS', {
      can_list: menuItem.canConfig?.canList || false,
      can_create: menuItem.canConfig?.canCreate || false,
      can_edit: menuItem.canConfig?.canEdit || false,
      can_delete: menuItem.canConfig?.canDelete || false,
      ...menuItem.canConfig,
    })
  }
}
