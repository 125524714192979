<template>
    <div>

        <!-- LISTADO -->
        <v-row v-show="!ui.showOffline">
            <v-col cols="12">
                <ag-datatable :key="ui.refresh" title="Listado" :headers="headers" :items="items" emitClickRow
                    @click-row="clickRow" @gridApi="gridApi = $event" :auth="auth">
                </ag-datatable>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Estado from '~/models/estados'
import AgDatatable from '~/components/Datatable'
import { uuid } from 'vue-uuid';

export default {
    props: {
        iteracion: {
            type: Object,
            default: () => ({}),
        },
        cliente: { type: String, default: null },
        expediente: { type: String, default: null },
    },
    components: {
        AgDatatable,
    },

    data: () => ({
        headersPinned: true,
        gridApi: null,

        items: [],
        db: { items: [] },
        tipos_auditorias: {},

        auth: {},

        ui: {
            retry: 0,
            refresh: 0,
            showOffline: false,
        },
    }),

    watch: {
        iteracion: {
            handler(newVal, oldVal) {
                if (newVal.uuid !== oldVal.uuid) {
                    this.refreshRows(true)
                }
            },
            deep: true,
        },

        isOffline: function (weAreOffline) {
            this.filterOffline(weAreOffline)
        },
    },

    computed: {
        isOffline: function () {
            return this.$nuxt.isOffline
        },

        isCliente() {
            return this.$store.getters.isCliente
        },

        headers() {
            let headers = [
                {
                    text: '',
                    value: 'id',
                },
                {
                    text: this.$t('widgetRevisiones.status'),
                    value: 'estado',
                },
                {
                    text: this.$t('widgetRevisiones.subscope'),
                    value: 'subalcance',
                },
                {
                    text: this.$t('widgetRevisiones.facilities'),
                    value: 'instalaciones',
                },
                {
                    text: this.$t('widgetRevisiones.auditors'),
                    value: 'auditores',
                },
                {
                    text: this.$t('widgetRevisiones.auditType'),
                    value: 'tipoAuditoria',
                },
                {
                    text: this.$t('widgetRevisiones.reviewer'),
                    value: 'revisor',
                },
                {
                    text: this.$t('widgetRevisiones.date'),
                    value: 'fecha',
                },
                {
                    text: '',
                    value: 'actions',
                }
            ];


            return headers
        },

        //+-------------------------------------------------
        // allowedToOffline()
        // return items where estado == 'abierta' and checklists.length > 0
        // -----
        // Created on Wed Jan 11 2023
        //+-------------------------------------------------
        allowedToOffline() {
            return this.items.filter(
                (item) => item.estado === Estados.AUDITORIA_ESTADO.ABIERTA //  item.checklists > 0
            )
        },
    },
    methods: {
        async init() {
            const body = {}
            if (this.iteracion) body.iteracion = this.iteracion.uuid
            // Cliente
            if (this.cliente) body.cliente = this.cliente
            else if (this.isCliente) {
                const clientes = await this.$store.dispatch('clientes/search', this.$auth.user.uuid)
                if (clientes) body.cliente = clientes[0].uuid
            }

            // Auditorias
            const res = await this.$store.dispatch('revisiones/listByIteracion', this.iteracion.uuid)
            if (res) {
                console.log('res', res)
                this.db.items = res
                this.items = this.transformItems(res)
                console.log('this.items', this.items)
            }
        },

        transformItems(items) {
            return items.map(item => {
                // Lógica para determinar el estado
                let estado = 'SIN PROPUESTA';
                if (!item.estado && !item.proposicion) {
                    estado = 'SIN PROPUESTA';
                } else if (item.proposicion && (item.estado !== Estado.REVISION_ESTADO.OK && item.estado !== Estado.REVISION_ESTADO.KO)) {
                    estado = 'CON PROPUESTA';
                } else if (item.estado === Estado.AUDITORIA_ESTADO.CERRADA && item.estado === Estado.REVISION_ESTADO.OK) {
                    estado = Estado.REVISION_ESTADO.OK;
                } else if (item.estado === Estado.AUDITORIA_ESTADO.CERRADA && item.estado === Estado.REVISION_ESTADO.KO) {
                    estado = Estado.REVISION_ESTADO.KO;
                } else {
                    estado = item.estado;
                }

                return {
                    id: item.id,
                    subalcance: item.auditoria.expediente.subalcance,
                    estado: estado, // Usa la variable estado calculada
                    instalaciones: [item.auditoria.emplazamientos ? item.auditoria.emplazamientos.map(e => e.nombre).join(', ') : ''],
                    auditores: [item.auditor1, item.auditor2 ? item.auditor2 : null].filter(Boolean),
                    tipoAuditoria: item.auditoria.tipoVisita,
                    tipoVisita: item.auditoria.tipoVisita,
                    revisor: item.auditor1.nombreCompleto,
                    fecha: item.createdAt,
                    uuid: item.uuid,
                    actions: item.actions // Asegúrate de que 'actions' exista en los datos originales o ajusta según sea necesario
                };
            });
        },


        clickRow(data) {
            if (data) this.$router.push(`/revisiones/${data.uuid}`)
        },

        async refreshRows(init = false) {
            if (init) await this.init()
            this.gridApi.refreshCells({ force: true })
        },

        async checkRoles() {
            this.auth = await this.$store.dispatch('user/can', {
                module: 'revisiones',
                return: 'all',
            })
        },
    },

    mounted() {

        this.checkRoles()
    },

    async created() {
        await this.init()
    },
}
</script>
