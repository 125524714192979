<template>
  <v-row>
    <v-col cols="4">
      <p class="pl-0 info-expediente">{{ $t('selectEmplazamientoProductos.emplazamientos') }}</p>
      <v-subheader class="pl-0 mt-2 info-expediente">
        {{ $t('selectEmplazamientoProductos.seleccionaEmplazamientos') }}
      </v-subheader>
      <v-list>
        <v-list-item-group v-model="selected_emplazamientos" multiple>
          <v-list-item v-for="(emplazamiento, index) in emplazamientos" :key="'emplazamiento' + index"
            :value="emplazamiento">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" @change="updateSelectedProductos(emplazamiento, active)"
                  :color="subalcance.color"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ emplazamiento.nombre }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ emplazamiento.tipo }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="8">
      <v-tabs v-model="tabProductos" :color="subalcance.color" :slider-color="subalcance.color">
        <v-tab v-for="emplazamiento in listadoEmplazamientos" :key="emplazamiento.tipo"
          :href="'#' + emplazamiento.tipo">
          <span class="primary--text text-no-wrap text-truncate">{{ emplazamiento.tipo }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabProductos">
        <v-tab-item :value="emplazamiento.tipo" v-for="emplazamiento in listadoEmplazamientos"
          :key="emplazamiento.tipo">
          <v-row class="mt-3">
            <v-data-table :headers="getHeaders(emplazamiento.uuid)" :items="listadoProductos(emplazamiento.tipo)"
              :items-per-page="5" show-select @current-items="updateSelected" v-model="selected_productos"
              :checkbox-color="subalcance.color" item-key="uuid" class="elevation-1">
              <template v-for="header in getHeaders(emplazamiento.uuid)" v-slot:[`item.${header.value}`]="{ item }">
                {{
                  (() => {
                    const param = item.params.find((param) => param.nombre === header.value);
                    if (param && param.valor) {
                      return typeof param.valor[0] === 'object' ? param.valor[0].nombre : param.valor.join(', ');
                }
                return '';
                })()
                }}
              </template>
            </v-data-table>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>


<script>
import Datatable from '~/components/Datatable.vue'
export default {
  components: {
    Datatable,
  },
  props: {
    emplazamientos: {
      type: Array,
      required: true,
    },
    productos: {
      type: Array,
      required: true,
    },
    subalcance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tabProductos: '',
      selected_emplazamientos: [],
      selected_productos: [],
    }
  },
  computed: {
    listadoEmplazamientos() {
      let acc = []
      this.emplazamientos.forEach((emplazamiento) => {
        if (!acc.find((item) => item.tipo === emplazamiento.tipo)) {
          acc.push({ tipo: emplazamiento.tipo, uuid: emplazamiento.uuid })
        }
      })
      return acc
    },
  },
  methods: {
    getHeaders(uuid) {
      const product = this.productos.find((producto) => producto.emplazamiento.uuid === uuid)
      return Object.values(product.params).map((item) => {
        return {
          text: item.nombre,
          value: item.nombre,
          sortable: false,
        }
      })
    },

    listadoProductos(tipo) {
      return this.productos.filter((producto) => producto.emplazamiento.tipo === tipo)
    },
    updateSelectedProductos(emplazamiento, active) {
      if (active) {
        this.selected_productos = this.selected_productos.filter(
          (producto) => producto.emplazamiento.uuid !== emplazamiento.uuid
        )
      } else {
        this.selected_productos = [
          ...this.selected_productos,
          ...this.productos.filter(
            (producto) => producto.emplazamiento.uuid === emplazamiento.uuid
          ),
        ]
      }
    },
    updateSelected() {
      this.$emit('updateSelectedProductos', this.selected_productos)
      this.$emit('updateSelectedEmplazamientos', this.selected_emplazamientos)
      this.updateSelectedEmplazamientos()
    },
    updateSelectedEmplazamientos() {
      this.emplazamientos.forEach((emplazamiento) => {
        const productos = this.productos.filter(producto => producto.emplazamiento.uuid === emplazamiento.uuid)
        const allSelected = productos.every(producto => this.selected_productos.includes(producto))
        if (allSelected && !this.selected_emplazamientos.includes(emplazamiento)) {
          this.selected_emplazamientos.push(emplazamiento)
        } else if (!allSelected && this.selected_emplazamientos.includes(emplazamiento)) {
          const index = this.selected_emplazamientos.indexOf(emplazamiento)
          this.selected_emplazamientos.splice(index, 1)
        }
      })
    }
  },
}
</script>
