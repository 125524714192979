<template>
  <v-hover v-slot:default="{ hover }" :disabled="disableHover">
    <v-card :elevation="disableHover ? 0 : hover ? 8 : 3" v-if="auth.canList">
      <v-card-title v-if="showHeader">
        <span class="title-vuely" v-if="title">{{ $t('datatable.title') }}</span>
        <v-spacer v-if="title"></v-spacer>
        <v-text-field v-model="search" append-icon="search" :label="$t('datatable.quickSearch')" single-line hide-details
          clearable></v-text-field>
        <slot name="button"></slot>
        <v-tooltip bottom v-if="showAddButton">
          <template v-slot:activator="{ on }">
            <v-btn small fab depressed color="success" class="ml-4 white--text" v-on="on"
              @click="$emit('click-button')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('datatable.create') }}</span>
        </v-tooltip>
      </v-card-title>

      <v-data-table @click:row="clickRow" :search="search" :loading="loading" :loading-text="$t('datatable.loading')"
        :sort-by="['created_at']" :sort-desc="true" :no-data-text="$t('datatable.emptyList')" :show-select="showSelect"
        :headers="headers" :items="items" v-bind="$attrs" v-on="$listeners" :page.sync="page"
        :items-per-page="itemsPerPage" hide-default-footer @page-count="pageCount = $event">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>

        <template v-slot:item.id="{ item }">
          <v-checkbox v-model="selected" :value="item.id"></v-checkbox>
        </template>

        <template v-slot:item.estado="{ item }">
          <v-chip v-if="item.estado == 'ABIERTA'" color="info" dark>
            {{ $t('datatable.open') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'Con Propuesta'" color="info" dark>
            {{ $t('datatable.withProposal') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'CERRADA'" color="success" dark>
            {{ $t('datatable.closed') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'Apta'" color="success" dark>
            {{ $t('datatable.suitable') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'CONFIRMADA'" color="warning" dark>
            {{ $t('datatable.confirmed') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'Sin Propuesta'" color="warning" dark>
            {{ $t('datatable.noProposal') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'Sin Decision' || item.estado == 'Sin decision'" color="warning" dark>
            {{ $t('datatable.noDecision') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'Cerrada'" color="error" dark>
            {{ $t('datatable.closedLower') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'No Apta'" color="error" dark>
            {{ $t('datatable.notSuitable') }}
          </v-chip>
          <v-chip v-else-if="item.estado == 'PREVISTA'" color="primary" dark>
            {{ $t('datatable.planned') }}
          </v-chip>
          <template v-else-if="item.estado == 'Activo'">
            <v-switch color="success" :value="true" :input-value="true"></v-switch>
          </template>
          <template v-else-if="item.estado == 'Inactivo'">
            <v-switch color="error" :value="false" :input-value="false"></v-switch>
          </template>
          <template v-else-if="item.estado == 'APTO'">
            <v-chip color="green" dark>{{ $t('datatable.suitableCaps') }}</v-chip>
          </template>
          <template v-else-if="item.estado == 'NO APTO'">
            <v-chip color="error" dark>{{ $t('datatable.notSuitableCaps') }}</v-chip>
          </template>
          <template v-else-if="item.estado == 'SIN PROPUESTA'">
            <v-chip color="warning" dark>{{ $t('datatable.noProposalCaps') }}</v-chip>
          </template>
          <template v-else-if="item.estado == 'CON PROPUESTA'">
            <v-chip color="info" dark>{{ $t('datatable.withProposalCaps') }}</v-chip>
          </template>
          <template v-else-if="item.estado == 'PENDIENTE'">
            <v-chip color="info" dark>{{ $t('datatable.pending') }}</v-chip>
          </template>
          <v-chip v-else color="primary" dark>
            {{ item.estado }}
          </v-chip>
        </template>

        <template v-slot:item.subalcance="{ item }">
          <v-chip v-if="item.subalcance" class="ml-1 cursor-pointer" :color="item.subalcance.color" outlined>
            {{ item.subalcance.nombre }}
          </v-chip>
        </template>

        <template v-slot:item.auditoria.subalcance="{ item }">
          <v-chip v-if="item.auditoria.subalcance" class="ml-1 cursor-pointer" :color="item.auditoria.subalcance.color" outlined>
            {{ item.auditoria.subalcance.nombre }}
          </v-chip>
        </template>

        <template v-slot:item.cumpleNoCumple="{ item }">
          <v-chip v-if="item.cumpleNoCumple" class="ml-1 cursor-pointer" :color="item.cumpleNoCumple ? 'success' : 'error'" outlined>
            {{ item.cumpleNoCumple ? $t('datatable.complies') : $t('datatable.notComplies') }}
          </v-chip>
        </template>

        <template v-slot:item.numero_operador="{ item }">
          <span class="ml-1">{{ item.cliente.nOperador }}</span>
        </template>

        <template v-slot:item.user="{ item }">
          <span class="ml-1">{{ item.user.nombreCompleto }}</span>
        </template>

        <template v-slot:item.auditores="{ item }">
          <span class="ml-1">{{ item.auditores.map((a) => a.nombreCompleto).join(', ') }}</span>
        </template>

        <template v-slot:item.tipoAuditoria="{ item }">
          <span class="ml-1">{{ item.tipoVisita.map((v) => v).join(', ') }}</span>
        </template>

        <template v-slot:item.n_pacs="{ item }">
          <span class="ml-1">{{ item.pacs.length }}</span>
        </template>

        <template v-slot:item.fecha="{ item }">
          <span v-if="item.start" class="ml-1">{{ item.start }} -> {{ item.end }}</span>
          <span v-else class="ml-1">{{ getFecha(item.fecha) }}</span>
        </template>

        <template v-slot:item.estadoAuditoria="{ item }">
          <v-badge v-if="item.estadoAuditoria == 'Sin auditoría'" left overlap :color="'grey'"></v-badge>
          <v-badge v-if="item.estadoAuditoria == 'Prevista'" left overlap></v-badge>
          <v-badge v-if="item.estadoAuditoria == 'Confirmada'" left overlap :color="'orange'"></v-badge>
          <v-badge v-if="item.estadoAuditoria == 'Abierta'" left overlap :color="'blue'"></v-badge>
          <v-badge v-if="item.estadoAuditoria == 'Cerrada'" left overlap :color="'green'"></v-badge>
        </template>

        <template v-slot:item.estadoRevision="{ item }">
          <v-badge v-if="item.estadoRevision == 'Sin revisión'" left overlap :color="'grey'"></v-badge>
          <v-badge v-if="item.estadoRevision == 'Prevista'" left overlap></v-badge>
          <v-badge v-if="item.estadoRevision == 'Sin propuesta'" left overlap :color="warning"></v-badge>
          <v-badge v-if="item.estadoRevision == 'Con propuesta'" left overlap :color="info"></v-badge>
          <v-badge v-if="item.estadoRevision == 'Apto'" left overlap :color="'green'"></v-badge>
          <v-badge v-if="item.estadoRevision == 'No apto'" left overlap :color="'error'"></v-badge>
        </template>

        <template v-slot:item.estadoDictamen="{ item }">
          <v-badge v-if="item.estadoDictamen == 'Sin dictamen'" left overlap :color="'grey'"></v-badge>
          <v-badge v-if="item.estadoDictamen == 'Previsto'" left overlap></v-badge>
          <v-badge v-if="item.estadoDictamen == 'Sin propuesta'" left overlap :color="warning"></v-badge>
          <v-badge v-if="item.estadoDictamen == 'Con propuesta'" left overlap :color="info"></v-badge>
          <v-badge v-if="item.estadoDictamen == 'APTO'" left overlap :color="success"></v-badge>
          <v-badge v-if="item.estadoDictamen == 'NO APTO'" left overlap :color="error"></v-badge>
        </template>

        <template v-slot:item.estadoCertificado="{ item }">
          <v-badge v-if="item.estadoCertificado == 'Sin certificado'" left overlap :color="'grey'"></v-badge>
          <v-badge v-if="item.estadoCertificado == 'Prevista'" left overlap></v-badge>
          <v-badge v-if="item.estadoCertificado == 'Confirmada'" left overlap :color="warning"></v-badge>
          <v-badge v-if="item.estadoCertificado == 'Abierta'" left overlap :color="info"></v-badge>
          <v-badge v-if="item.estadoCertificado == 'Cerrada'" left overlap :color="success"></v-badge>
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ getCreatedAt(item) }}
        </template>

        <template v-slot:item.updatedAt="{ item }">
          {{ getUpdatedAt(item) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon v-if="auth.canEdit" small class="mr-2" @click.stop="clickRowEdit(item)">mdi-pencil</v-icon>
          <v-icon v-if="auth.canConfirm" small class="mr-2" @click.stop="clickRowConfirm(item)">mdi-check</v-icon>
          <v-icon v-if="auth.canClose" small class="mr-2" @click.stop="clickRowClose(item)">mdi-alert-octagon</v-icon>
          <v-icon v-if="auth.canAmpliarRevision" small class="mr-2"
            @click.stop="clickRowAmpliarRevision(item)">mdi-check</v-icon>
          <v-icon v-if="auth.canGenerarCertificado" small class="mr-2"
            @click.stop="clickRowGenerarCertificado(item)">mdi-check</v-icon>
          <v-icon v-if="auth.canDelete" small @click.stop="clickRowDelete(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <div class="text-center pt-3 pb-3">
        <v-pagination color="info" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-card>
    <v-card :elevation="disableHover ? 0 : hover ? 8 : 3" v-else>
      <v-alert type="warning" class="mt-1" outlined>
        {{ $t('datatable.noPermission') }}
      </v-alert>
    </v-card>
  </v-hover>
</template>


<script>
import moment from 'moment'
export default {
  name: 'Datatable',
  props: {
    disableHover: { type: Boolean, default: () => false },
    showSelect: { type: Boolean, default: () => false },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    title: { type: String, default: () => null },
    disableClickRow: { type: Boolean, default: () => false },
    checklistRow: { type: Boolean, default: () => false },
    auditoria: { type: Object, default: null },
    revision: { type: Object, default: null },
    dictamen: { type: Object, default: null },
    emitClickRow: { type: Boolean, default: () => false },
    showHeader: { type: Boolean, default: () => true },
    itemsPerPage: { type: Number, default: () => 10 },
    auth: {
      type: Object,
      default: () => ({
        canList: false,
        canCreate: false,
        canEdit: false,
        canDelete: false,
        canConfirm: false,
        canClose: false,
        canAmpliarRevision: false,
        canGenerarCertificado: false,
      }),
    },
    is_list: { type: Boolean, default: () => false },
    showAddButton: { type: Boolean, default: () => false },
  },
  data() {
    return {
      singleSelect: false,
      loading: true,
      search: '',
      page: 1,
      pageCount: 0,
      selected: [],
    }
  },
  watch: {
    items: function (value) {
      if (value && value.length > 0) this.loading = false
      if (value && value.length === 0) this.loading = false
    },
  },
  mounted() {
    setTimeout(() => (this.loading = false), 1500)
  },
  methods: {
    getDireccionCompleta(direccion) {
      let direccionCompleta = ''
      if (direccion) {
        direccionCompleta = direccion.direccion
        if (direccion.localidad) direccionCompleta += ` -${direccion.localidad}`
        if (direccion.provincia) direccionCompleta += ` -${direccion.provincia}`
        if (direccion.pais) direccionCompleta += ` (${direccion.pais})`
      }
      return direccionCompleta
    },
    getCreatedAt(item) {
      const value = item && item.createdAt
      return value ? moment(value).format('DD/MM/YYYY') : ''
    },
    getUpdatedAt(item) {
      const value = item && item.updatedAt
      return value ? moment(value).format('DD/MM/YYYY') : ''
    },
    getFecha(value) {
      return value ? moment(value).format('DD/MM/YYYY') : ''
    },
    clickRow(row) {
      if (!this.disableClickRow){
        if (this.checklistRow) {
          let url = `/auditorias/tabs/checklist/${row.uuid}`
          if (this.auditoria) url += `?auditoria_uuid=${this.auditoria.uuid}`
          if (this.revision) url = `/revisiones/tabs/checklist/${row.uuid}?revision_uuid=${this.revision.uuid}`
          if (this.dictamen) url = `/dictamenes/tabs/checklist/${row.uuid}?dictamen_uuid=${this.dictamen.uuid}`
          this.$router.push(url)
        } else if (!this.disableClickRow && !this.emitClickRow) {
          let route = this.$nuxt.$route.name.replace(/-/g, '/')
          route = `/${route}/${row.uuid}`
          this.$router.push(route)
        } else if (!this.disableClickRow && this.emitClickRow) {
          this.$emit('click-row', row)
        }
      }
    },
    clickRowEdit(row) {
      this.$emit('clickRowEdit', row)
    },
    clickRowConfirm(row) {
      this.$emit('clickRowConfirm', row)
    },
    clickRowClose(row) {
      this.$emit('clickRowClose', row)
    },
    clickRowAmpliarRevision(row) {
      this.$emit('clickRowAmpliarRevision', row)
    },
    clickRowGenerarCertificado(row) {
      this.$emit('clickRowgenerarCertificado', row)
    },
    clickRowDelete(row) {
      this.$emit('clickRowDelete', row)
    },
  },
}
</script>

<style>
.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: pointer !important;
}
</style>
