import { format } from 'date-fns'
import { uuid } from 'vue-uuid'
import es from '~/locales/es'
import formatHelper from '~/util/formatHelper'

export const state = () => ({
  list: [],
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, params) {
    rootState.loading = true
    let url = 'certificados'
    if (params?.cliente) url += `?cliente.uuid=${params.cliente}`
    if (params?.expediente) url += `?expediente.uuid=${params.expediente}`
    const res = await this.$axios.get(url)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async listBySubalcance({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`certificados?subalcance.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      commit('SET_LIST', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`certificados/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, dictamen) {
    rootState.loading = true
    let jxr = null
    const res = await this.$axios.get(`revisiones/${dictamen.revision.uuid}`)
    if (res) {
      const revision = res.data
      const body = {
        uuid: uuid.v4(),
        subalcance: revision.auditoria.expediente.subalcance['@id'],
        expediente: revision.auditoria.expediente['@id'],
        auditoria: revision.auditoria['@id'],
        dictamen: dictamen['@id'],
      }
      jxr = await this.$axios.post('certificados', body)
      rootState.loading = false
    }

    return jxr
  },

  async update({ rootState }, certificado) {
    certificado.certificado = certificado.uuid
    rootState.loading = true

    // https://trello.com/c/CWi9EMrf/435-certicalidad-certificados
    certificado.n_revision = certificado.revision
    delete certificado.revision

    certificado = formatHelper.nestedToIRI(certificado)

    await this.$axios.patch(`certificados/${certificado.uuid}`, certificado, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async delete({ rootState }, certificado) {
    rootState.loading = true
    await this.$axios.delete(`certificados/${certificado.uuid}`)
    rootState.loading = false
  },

  async publicar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
      publicado: true,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async despublicar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
      publicado: false,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async activar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
      activo: true,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async desactivar({ rootState }, uuid) {
    const body = {
      certificado: uuid,
      activo: false,
    }
    rootState.loading = true
    await this.$axios.patch(`certificados/${uuid}`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },
}
