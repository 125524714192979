export const state = () => ({
  items: [],
  item: {},
  total: 0,
  page: 1,
  itemsPerPage: 10,
})

//commit mutations
export const mutations = {
  setSubalcances(state, subalcances) {
    state.items = subalcances
  },
  setSubalcance(state, subalcance) {
    state.item = subalcance
  },
  setTotal(state, total) {
    state.total = total
  },
  setPage(state, page) {
    state.page = page
  },
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage
  },
  addSubalcance(state, subalcance) {
    state.items.push(subalcance)
  },
  updateSubalcance(state, updatedSubalcance) {
    const index = state.items.findIndex((item) => item.uuid === updatedSubalcance.uuid)
    if (index !== -1) {
      state.items.splice(index, 1, updatedSubalcance)
    }
  },
  deleteSubalcance(state, uuid) {
    state.items = state.items.filter((item) => item.uuid !== uuid)
  },
}

export const actions = {
  async updateValidez({ commit }, payload) {
    try {
      const response = await this.$axios.patch(
        `/subalcances/${payload.uuid}`,
        {
          valid: payload.validez,
        },
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      commit('updateSubalcance', response.data)
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  async fetchSubalcances({ rootState, commit }, params) {
    let queryParams = []

    if (params?.search) {
      queryParams.push(`nombre=${encodeURIComponent(params.search)}`)
    }
    if (params?.alcance) {
      queryParams.push(`alcance.uuid=${params.alcance}`)
    }
    if (params?.page) {
      queryParams.push(`page=${params.page}`)
    }
    if (params?.itemsPerPage) {
      queryParams.push(`itemsPerPage=${params.itemsPerPage}`)
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
    const res = await this.$axios.get(`subalcances${queryString}`)

    if (res?.data && res.data['hydra:member']) {
      commit('setSubalcances', res.data['hydra:member'])
      commit('setTotal', res.data['hydra:totalItems'])
      return {
        items: res.data['hydra:member'],
        total: res.data['hydra:totalItems'],
      }
    } else {
      return {
        items: [],
        total: 0,
      }
    }
  },
  async updateLogo({ commit }, params) {
    const res = await this.$axios.post(
      'subalcances/' + params.uuid + '/update-logo',
      params.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    if (res && res.data) {
      commit('updateSubalcance', res.data)
      return res.data
    }
  },

  async getLogo({ commit }, params) {
    const res = await this.$axios.get('subalcances/' + params + '/logo', { responseType: 'blob' })
    if (res && res.data) {
      return res.data
    }
  },
  async list({ rootState, commit }, params) {
    rootState.loading = true
    let res = null
    if (params?.nombre) {
      res = await this.$axios.get(`subalcances?nombre=${params.nombre}`)
    }
    if (params?.alacance) {
      res = await this.$axios.get(`subalcances?alcance.uuid=${params.alcance}`)
    } else {
      res = await this.$axios.get('subalcances')
    }
    rootState.loading = false

    if (res?.data && res.data['hydra:member']) {
      commit('setSubalcances', res.data['hydra:member'])
      return res.data['hydra:member']
    }
  },

  async get({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances/${uuid}`)
    rootState.loading = false
    if (res) {
      commit('setSubalcance', res.data)
      return res.data
    }
  },

  async getProductos({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances/${uuid}/productos`)
    rootState.loading = false
    if (res) {
      commit('setSubalcance', res.data)
      return res.data
    }
  },

  async clone({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.post(`subalcances/${uuid}/clone`, {
      headers: {
        'Content-Type': 'application/ld+json',
      },
    })
    rootState.loading = false
    if (res) {
      commit('addSubalcance', res.data)
      return res.data
    }
  },

  async delete({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.delete(`subalcances/${uuid}`)
    rootState.loading = false
    if (res) {
      commit('deleteSubalcance', uuid)
      return res
    }
  },

  async incrementVersion({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`subalcances/${uuid}/increment-version`)
    rootState.loading = false
    if (res) {
      commit('updateSubalcance', res.data)
      return res.data
    }
  },

  async updateEstado({ rootState }, payload) {
    rootState.loading = true
    const res = await this.$axios.patch(
      `subalcances/${payload.uuid}`,
      {
        activo: payload.activo,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }
    )
    rootState.loading = false
    if (res) {
      commit('updateSubalcance', res.data)
      return res.data
    }
  },
}
