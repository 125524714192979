export default function (ctx) {
  // Set i18n locale based on $auth.user
  if (ctx.store.state.auth.user) {
    // Check if user has a prop preferences and a prop locale in it
    if (ctx.store.state.auth.user.preferences && ctx.store.state.auth.user.preferences.defaultLanguage) {
      ctx.store.commit('SET_LOCALE', ctx.store.state.auth.user.preferences.defaultLanguage)
      console.warn(
        "localeSetter.js: user's locale has been set to " +
          ctx.store.state.locale +
          " from user's preferences"
      )
    } else if (
      ctx.store.state.auth.user.empresa &&
      ctx.store.state.auth.user.empresa.configuracion &&
      ctx.store.state.auth.user.empresa.configuracion.defaultLanguage
    ) {
      ctx.store.commit(
        'SET_LOCALE',
        ctx.store.state.auth.user.empresa.configuracion.defaultLanguage
      )
      console.warn(
        "localeSetter.js: user's locale has been set to " +
          ctx.store.state.locale +
          " from user's empresa"
      )
    } else {
      // Detect browser language
      let browserLanguage = getBrowserLanguage()
      console.warn(
        "localeSetter.js: user's locale has been set to " + browserLanguage + ' from browser'
      )
      ctx.store.commit('SET_LOCALE', browserLanguage)
    }
    let locale = ctx.store.state.locale
    ctx.app.i18n.setLocaleCookie(locale)
  }
}

function getBrowserLanguage() {
  return (navigator.language || navigator.userLanguage || 'en').split('-')[0]
}
