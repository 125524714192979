/**
 * @project: certiapp-nuxt
 * @file:    store/plantillas-formatos.js
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 28th April 2021
 * Last Modified: Thu Jul 13 2023
 **/

import { uuid } from 'vue-uuid'

export const state = () => ({})

export const mutations = {}

export const actions = {
  //+-------------------------------------------------
  // list()
  // Returns a list of available templates
  // Requires modulo: "solicitudes" modulo_ref: "uuid"
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async list({ rootState }, params) {
    rootState.loading = true
    let all = false
    if (params?.all){
      all = params.all
      delete params.all
    }
    let url = 'plantilla_formatos'
    if (params) {
      url += '?'
      for (const key in params) {
        url += `${key}=${params[key]}&`
      }
    }
    const res = await this.$axios.get(url)

    rootState.loading = false
    if (res){
      if (all){
        console.log(res)
        return res
      }else{
        return res.data['hydra:member']
      }
    } 
  },

  //+-------------------------------------------------
  // get()
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`plantilla_formatos/${uuid}`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // regenerar()
  // -----
  // Created on Thu Apr 29 2021
  // Updated on Fri Sep 30 2022
  //+-------------------------------------------------
  async regenerar({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`plantilla_formatos/${uuid}/regenerar`)
    // const res = await this.$axios.get(`plantilla_formatos/${uuid}/filtered`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // create()
  // payload: uuid, empresa, alcance, modulo, file, nombre
  // -----
  // Created on Wed Apr 28 2021
  //+-------------------------------------------------
  async create({ rootState }, params) {
    rootState.loading = true

    let data = new FormData()
    if (params.file) data.append('file', params.file)
    if(params.empresas) data.append('empresas', params.empresas)
    data.append('alcance', params.alcance)
    data.append('subalcance', params.subalcance)
    data.append('modulo', params.modulo)
    data.append('codigo', params.codigo)
    data.append('nombre', params.nombre)
    data.append('type', params.type)
    data.append('tipo', params.tipo)
    data.append('is_lotes', params.is_lotes || false)
    data.append('flow_url', params.flow_url)
    data.append('refUuid', params.refUuid)
    data.append('version', params.version || 1)

    const res = await this.$axios.post(`plantilla_formatos`, data)
    if (res) return res.data
    rootState.loading = false
  },

  //+-------------------------------------------------
  // update()
  // Does not update the file, only the data
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async update({ rootState }, params) {
    rootState.loading = true

    await this.$axios.patch(`plantilla_formatos/${params.uuid}`, {
      ...params,
      },{
        headers: {
          'Content-Type': 'application/merge-patch+json',
        }
      }
    )

    rootState.loading = false
  },

  //+-------------------------------------------------
  // replaceFile()
  // Only replaces the template file
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async replaceFile({ rootState }, params) {
    rootState.loading = true

    let data = new FormData()
    data.append('file', params.file)

    await this.$axios.post(`plantilla_formatos/${params.uuid}/updateFile`, data)
    rootState.loading = false
  },

  async getVars({ rootState }, params) {
    rootState.loading = true
    const res = await this.$axios.get(`plantilla_formatos/${params.uuid}/vars`)

    rootState.loading = false
    if (res) return res.data
  },

  //+-------------------------------------------------
  // generate()
  // Requests a dynamic file.
  // The api returns the blob
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async generate({ rootState }, params) {
    rootState.loading = true

    this.$axios({
      url: `plantilla_formatos/${params.formato_plantilla}/generate/${params.modulo_ref}`,
      method: 'get',
      responseType: 'blob',
      data: {
        modulo: params.modulo,
        formato: params.formato || 'word',
        modulo_ref: params.modulo_ref,
        formato_plantilla: params.formato_plantilla,
      },
    })
      .then((response) => {
        downloadBlob(response)

        // If params has data on the document attribute
        // call for documents:generated with the blob
        if (params.document) {
          $nuxt.$emit('documents:generated', {
            name: params.document.name,
            modulo: params.document.modulo,
            modulo_ref: params.document.modulo_ref,
            submodulo: params.document.submodulo,
            submodulo_ref: params.document.submodulo_ref,
            cliente: params.document.cliente,
            cliente_name: params.document.cliente_name,
            blob: response,
          })
        }

        rootState.loading = false
        return response
      })
      .catch((e) => {
        console.warn(e)
      })
  },

  //+-------------------------------------------------
  // download()
  // -----
  // Created on Fri Apr 30 2021
  //+-------------------------------------------------
  async download({ rootState, dispatch }, uuid) {
    this.$axios({
      url: `plantilla_formatos/${uuid}/downloadPlantilla`,
      method: 'get',
      responseType: 'blob',
    })
      .then((response) => {
        downloadBlob(response)
        rootState.loading = false
      })
      .catch((e) => {
        console.warn(e)
      })
  },

  //+-------------------------------------------------
  // clone()
  // -----
  // Created on Tue May 04 2021
  //+-------------------------------------------------
  async clone({ rootState }, uuid) {
    rootState.loading = true
    await this.$axios.post(`plantilla_formatos/${uuid}/clone`)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // toggleIsPublic()
  // -----
  // Created on Tue May 04 2021
  //+-------------------------------------------------
  async toggleIsPublic({ rootState }, item) {
    rootState.loading = true
    await this.$axios.patch(`plantilla_formatos/${item.uuid}`, {
      formato_plantilla: item.uuid,
      is_public: item.is_public,
      public: item.is_public,
    }, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })
    rootState.loading = false
  },

  //+-------------------------------------------------
  // delete()
  // -----
  // Created on Thu Apr 29 2021
  //+-------------------------------------------------
  async delete({ rootState }, item) {
    rootState.loading = true
    await this.$axios.delete(`plantilla_formatos/${item.uuid}`)
    rootState.loading = false
  },
}

function getParameterCaseInsensitive(object, key) {
  return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
}

//+-------------------------------------------------
// downloadBlob()
// Creates a file from a blob and downloads it
// Note: This function could be in a general helpers file to reuse
// -----
// Created on Fri Apr 30 2021
//+-------------------------------------------------
function downloadBlob(xhr) {
  const blob = new Blob([xhr.data], { type: xhr.data.type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  const contentDisposition = getParameterCaseInsensitive(xhr.headers, 'Content-Disposition')

  let fileName = 'download' //href.substring(href.lastIndexOf("/") + 1)
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (fileNameMatch != null && fileNameMatch.length === 2) fileName = fileNameMatch[1]
  }

  link.href = url
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}
