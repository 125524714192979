import { uuid } from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
  tab: 0,
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  CHANGE_TAB(state, tab) {
    state.tab = tab
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, uuid) {
    rootState.loading = true
    const body = {}
    if (uuid) body.expediente = uuid
    const res = await this.$axios.get('revisiones', body)
    rootState.loading = false
    if (res) {
      const data = res.data['hydra:member']
      commit('SET_LIST', data)
      return data
    }
  },
  async listByIteracion({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones?iteracion.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data['hydra:member']
      commit('SET_LIST', data)
      return data
    }
  },

  async addPlanificacion({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'addPlanificacion',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('revision'))
      storage.planificaciones.push(data.planificacion)
      localStorage.setItem('revision', JSON.stringify(storage))

      rootState.loading = false
      return storage
    } else {
      const planificacion = data.planificacion
      console.info('plan', planificacion)
      const res = await this.$axios.patch(
        `revisiones/${data.revision}/addChecklist`,
        planificacion,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      rootState.loading = false
      if (res) return res.data
    }
  },

  async editRespuesta({ rootState, state, commit, dispatch }, params) {
    console.log('[STORE] editRespuesta', params)
    if (state.offlineMode) {
      const action = {
        action: 'editRespuesta',
        body: params,
      }
      commit('OFFLINE_ACTION', action)
      const storage = JSON.parse(localStorage.getItem('revision_checklist'))
      storage.forEach((element) => {
        if (element.uuid === params.checklist_uuid) {
          element.preguntas.forEach((item) => {
            if (item.uuid === params.checklist_pregunta_uuid) {
              if (params.observacion) item.observacion = params.observacion
              if (params.no_conformidad) item.no_conformidad = params.no_conformidad
              if (params.respuesta) item.respuesta = params.respuesta['@id']
              if (params.nivel) item.nivel = params.nivel
              if (params.numero) item.numero = params.numero
              if (params.comentarios) item.comentarios = params.comentarios
              if (params.requisito_afectado) item.requisito_afectado = params.requisito_afectado
            }
          })
        }
      })
      localStorage.setItem('revision_checklist', JSON.stringify(storage))
      //dispatch('noConformidadesPendientesPac')
    } else {
      const body = {
        ...params,
      }
      console.log('[STORE] editRespuesta', body)
      const res = await this.$axios.patch(
        `pregunta_checklists/${params.checklist_pregunta_uuid}`,
        body,
        {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        }
      )
      if (res) return res.data
    }
  },

  async saveChecklist({ rootState, state, commit }, data) {
    rootState.loading = true
    // Check Offline
    if (state.offlineMode) {
      const action = {
        action: 'saveChecklist',
        body: data,
      }
      commit('OFFLINE_ACTION', action)

      const storage = JSON.parse(localStorage.getItem('revision_checklist'))
      storage.forEach((element) => {
        if (element.uuid === data.checklist_uuid) {
          if (data.fecha) element.fecha = data.fecha
          if (data.inicio) element.inicio = data.inicio
          if (data.fin) element.fin = data.fin
        }
      })
      localStorage.setItem('revision_checklist', JSON.stringify(storage))
    } else {
      await this.$axios.patch(`checklists/${data.checklist_uuid}`, data, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
    }
    rootState.loading = false
  },

  async listBySubalcance({ rootState, commit }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones?subalcance.uuid=${uuid}`)
    rootState.loading = false
    if (res) {
      const data = res.data['hydra:member']
      commit('SET_LIST', data)
      return data
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, revision) {
    rootState.loading = true
    // const body = {
    //   uuid: uuid.v4(),
    //   auditoria: revision.auditoria,
    //   auditor: revision.auditor,
    // }
    await this.$axios.post('revisiones', revision)
    rootState.loading = false
  },

  async update({ rootState }, revision) {
    rootState.loading = true

    // const body = {
    //   revision: revision.uuid,
    //   auditoria: revision.auditoria,
    //   auditor: revision.auditor,
    // }
    revision.revision = revision.uuid // hack to make the API happy

    await this.$axios.patch(`revisiones/${revision.uuid}`, revision, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async proponer({ rootState }, revision) {
    rootState.loading = true
    const body = {
      proposicion: revision.proposicion,
      fechaProposicion: revision.fechaProposicion,
      auditor1: revision.auditor1,
      enCalidadDeAuditor1: revision.enCalidadDeAuditor1,
      observacionesAuditor1: revision.observacionesAuditor1,
      auditor2: revision.auditor2,
      enCalidadDeAuditor2: revision.enCalidadDeAuditor2,
      observacionesAuditor2: revision.observacionesAuditor2,
      revision: revision.uuid,
    }
    await this.$axios.patch(`revisiones/${revision.uuid}/proponer`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async cambiarEstado({ rootState }, revision) {
    rootState.loading = true
    const body = {
      revision: revision.uuid,
      auditor1: revision.auditor1,
    }
    await this.$axios.patch(`revisiones/${revision.uuid}/revisada`, body, {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    })

    rootState.loading = false
  },

  async delete({ rootState }, revision) {
    rootState.loading = true
    await this.$axios.delete(`revisiones/${revision.uuid}`)
    rootState.loading = false
  },
}
